import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import {
  requestRegister,
  userLogout,
  requestGetList,
  requestCandidateProfile,
  requestGetCandidate,
  requestGetProducts
} from '../../Redux/actions'
import { toast } from 'react-toastify'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useTranslation } from 'react-i18next'
import CodiceFiscale from 'codice-fiscale-js'
import TermsAndService from '../../Components/common/register-fields/TermsAndService'
import OtherTerms from '../../Components/common/register-fields/OtherTerms'
import { useNavigate } from 'react-router-dom'
import CategoriesCheckbox from './CategoriesCheckbox'
import FiscalCode from '../../Components/common/register-fields/FiscalCode'
import Cellphone from '../../Components/common/register-fields/Cellphone'
import Driverlicense from '../../Components/common/register-fields/Driverlicense'
import LocationForm from '../../Components/common/register-fields/location/LocationForm'
import BasicInfoRegister from '../../Components/common/register-fields/basic-details/BasicInfoRegister'
import PasswordResetConfirm from '../../Components/forggot-password/Password-Reset-Confirm'

import PhoneInput from 'react-phone-input-2'
import it from 'react-phone-input-2/lang/it.json'
import SectorLevel from '../../Components/common/register-fields/SectorLevel'

function AgentProfile (props) {
  const [selectedOptionsEducation, setSelectedOptionsEducation] = useState({
    submenu: '',
    subSubmenu: ''
  })

  const [selectedOptionsSector, setSelectedOptionsSector] = useState({
    submenu: '',
    subSubmenu: ''
  })

  const [data, setData] = useState({
    country: 'Italia',
    phone: '+39',
    tel_visible: false,
    tel_consent: false,
    e_mail_consent: false,
    minority_group: false,
    travel_available: false
  })

  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const emailRef = useRef(null)
  const titleRef = useRef(null)
  const fiscalCodeRef = useRef(null)
  const phonRef = useRef()
  const birthDateRef = useRef(null)
  const qualificationRef = useRef(null)
  const subqualificationRef = useRef(null)
  const driverLicenseRef = useRef(null)
  const streetNumberRef = useRef(null)
  const zipCodeRef = useRef(null)
  const cityRef = useRef(null)
  const provinceRef = useRef(null)
  const countryRef = useRef(null)
  const eula_consentRef = useRef(null)
  const privacy_consentRef = useRef(null)

  const sectorRef = useRef(null)
  const subsectorRef = useRef(null)
  const productRef = useRef(null)

  const vatNumberRef = useRef(null)
  const workPhoneRef = useRef()

  const [error, setError] = useState(false)
  const [errorfirst_name, seterrorfirst_name] = useState('')
  const [errorlast_name, seterrorlast_name] = useState('')
  const [erroremail, seterroremail] = useState('')
  const [submenuSector, setSubmenuSector] = useState([])
  const [errorphone, seterrorPhone] = useState('')
  const [errortitle, seterrorTitle] = useState('')
  const [errorfiscal_code, seterrorFiscal_Code] = useState('')
  const [errorqualification, seterrorqualification] = useState('')
  const [errorDriverlicense, seterrorDriverlicense] = useState('')
  const [errorStreetnumber, seterrorStreetnumber] = useState('')
  const [errorCountry, seterrorCountry] = useState('')
  const [errorCity, seterrorCity] = useState('')
  const [errorProvince, seterrorProvince] = useState('')
  const [errorZipcode, seterrorZipcode] = useState('')
  const [errorbirthDate, seterrorBirthDate] = useState('')
  const [driverlicenseData, setDriverlicenseData] = useState([])
  const [modifydriverlicenseData, setModifyDriverlicenseData] = useState([])
  const [titleData, setTitleData] = useState([])
  const [countryData, setCountryData] = useState([])
  const [municipalities, setMunicipalities] = useState([])
  const [autopassword, setAutoPassword] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedCity, setSelectedCity] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errorSubMenu, setErrorSubMenu] = useState('')
  const [privacyAccepted, setPrivacyAccepted] = useState(false)
  const [errorprivacyAccepted, setErrorPrivacyAccepted] = useState('')
  const [privacyContentAccepted, setPrivacyContentAccepted] = useState(false)
  const [errorPrivacyContentAccepted, setErrorPrivacyContentAccepted] =
    useState('')
  const [errorSector, setErrorSector] = useState('')

  const [errorWorkPhone, setErrorWorkPhone] = useState('')

  const [errorProduct, setErrorProduct] = useState('')

  const [errorVatnumber, setErrorVatnumber] = useState('')

  const navigate = useNavigate()
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState('')

  const [productData, setProductData] = useState([])
  const [selectedProductData, setSelectedProductData] = useState({
    product: ''
  })
  const [selectedOptionsProduct, setSelectedOptionsProduct] = useState([])

  const mystyle = {
    color: '#D10000',
    padding: '3px 10px',
    width: '100%'
  }

  useEffect(() => {
    props.requestGetList()
  }, [])

  useEffect(() => {
    props.requestGetProducts()
  }, [])

  useEffect(() => {
    let levelData = props.candidate.getlistData
    if (levelData !== undefined) {
      if (levelData?.data?.Status === 'Success') {
        const transformedDriverLicenseData =
          levelData.data.Message.drivingLicences.map(license => ({
            value: license.id,
            label: license.licenceType
          }))
        setDriverlicenseData(transformedDriverLicenseData)
        setTitleData(levelData.data.Message.Title)
        const processedCountries = levelData.data.Message.countries.map(
          item => {
            const name = item.nativeCountryName
            return name
          }
        )
        const uniqueCountries = [...new Set(processedCountries)]
        const uniqueCountryData = uniqueCountries.map(name => ({
          nativeCountryName: name
        }))

        setCountryData(uniqueCountryData)
        const municipalit = levelData?.data?.Message?.municipalities
        const uniqueMunicipalities = []
        const municipalityNames = new Set()

        municipalit.forEach(municipality => {
          if (!municipalityNames.has(municipality.name)) {
            municipalityNames.add(municipality.name)
            const municipalityObject = {
              name: `${municipality.name}, ${municipality.provincia.name}`
            }
            uniqueMunicipalities.push(municipalityObject)
          }
        })
        const transformedCityData =
          uniqueMunicipalities?.map((city, index) => ({
            value: index,
            label: city?.name
          })) || []

        setMunicipalities(transformedCityData)
      }
    }
  }, [props.candidate.getlistData])

  const sortedCountryData = [...countryData].sort((a, b) => {
    if (a.nativeCountryName === 'Italia') return -1
    if (b.nativeCountryName === 'Italia') return 1
    return 0
  })

  function validateFname () {
    const firstName = firstNameRef.current.value
    if (!firstName) {
      seterrorfirst_name('Inserisci il tuo nome.')
      return false
    }
    const trimmedFirstName = firstName.trim()
    if (!trimmedFirstName) {
      seterrorfirst_name('Il nome non può essere vuoto.')
      return false
    }
    if (!/^[a-zA-Z\s]+$/.test(trimmedFirstName)) {
      seterrorfirst_name('Inserisci solo caratteri alfabetici.')
      return false
    }
    seterrorfirst_name('')
    return true
  }

  function validateLname () {
    const lastName = lastNameRef.current.value
    if (!lastName) {
      seterrorlast_name('Inserisci il tuo cognome.')
      return false
    }
    const trimmedLastName = lastName.trim()
    if (!trimmedLastName) {
      seterrorlast_name('Il cognome non può essere vuoto.')
      return false
    }
    if (!/^[a-zA-Z\s]+$/.test(trimmedLastName)) {
      seterrorlast_name('Inserisci solo caratteri alfabetici.')
      return false
    }
    seterrorlast_name('')
    return true
  }

  function validateEmail () {
    const email = emailRef.current.value
    if (!email) {
      seterroremail('Inserisci la tua e-mail.')
      return false
    }
    const trimmedEmail = email.trim()
    if (!trimmedEmail) {
      seterroremail("L'e-mail non può essere vuota.")
      return false
    }
    if (
      !trimmedEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      seterroremail('Inserisci un ID e-mail valido.')
      return false
    }
    seterroremail('')
    return true
  }

  const validatePhone = () => {
    let phone_no = phonRef?.current?.props.value
    if (!phone_no) {
      seterrorPhone('Inserisci il numero di telefono.')
      return false
    } else if (phone_no.length < 5) {
      seterrorPhone('Inserisci il numero di telefono.')
      return false
    } else {
      seterrorPhone('')
      return true
    }
  }

  const validateWorkPhone = () => {
    /*
    let phone_no = workPhoneRef?.current?.props.value
    if (!phone_no) {
      setErrorWorkPhone('Inserisci il numero di telefono.')
      return false
    } else if (phone_no.length < 5) {
      setErrorWorkPhone('Inserisci il numero di telefono.')
      return false
    } else {
      setErrorWorkPhone('')
      return true
    }*/
    return true
  }

  function validateTitle () {
    const title = titleRef.current.value
    if (!title) {
      seterrorTitle('Seleziona il titolo.')
      return false
    }
    seterrorTitle('')
    return true
  }

  const validateFiscalCode = () => {
    const fiscalCode = data?.fiscal_code

    if (!fiscalCode) {
      seterrorFiscal_Code('Inserisci il Codice Fiscale')
      return false
    }

    try {
      const cf = new CodiceFiscale(fiscalCode)

      if (cf.isValid()) {
        seterrorFiscal_Code('')
        return true
      } else {
        seterrorFiscal_Code('Codice Fiscale non valido')
        return false
      }
    } catch (error) {
      seterrorFiscal_Code('Codice Fiscale non valido')
      return false
    }
  }

  function validateQualification () {
    const qualification = qualificationRef?.current?.value
    if (!qualification) {
      seterrorqualification('Seleziona il tuo titolo di studio.')
      return false
    } else {
      seterrorqualification('')
      return true
    }
  }

  // const validateSubMenu = () => {
  //     const selectedSubMenu =
  //         selectedOptionsEducation?.submenu &&
  //         submenuOptions.find(
  //             (option) => option?.value === selectedOptionsEducation?.submenu
  //         );
  //     const subMenuLength = selectedSubMenu?.subSubmenuOptions?.length > 0;
  //     const validOptions = [
  //         { label: "Istituto tecnico economico" },
  //         { label: "Istituto tecnico tecnologico" },
  //     ];

  //     if (subMenuLength) {
  //         const isValidOption = validOptions.some(
  //             (option) => option.label === selectedSubMenu.label
  //         );

  //         if (!isValidOption) {
  //             setErrorSubMenu("Seleziona il tuo Indirizzo / Specializzazione.");
  //             return false;
  //         } else if (!selectedOptionsEducation?.subSubmenu) {
  //             setErrorSubMenu("Seleziona il tuo Indirizzo / Specializzazione.");
  //             return false;
  //         } else {
  //             setErrorSubMenu("");
  //             return true;
  //         }
  //     }

  //     setErrorSubMenu("");
  //     return true;
  // };

  // const validateSubMenuInput = () => {
  //     if (!selectedOptionsEducation?.subSubmenu) {
  //         setErrorSubMenu("Inserisci il tuo indirizzo/specializzazione.");
  //         return false;
  //     } else {
  //         setErrorSubMenu("");
  //         return true;
  //     }
  // };

  const validateDriverlicense = () => {
    if (selectedOptions.length === 0) {
      seterrorDriverlicense('Seleziona almeno una patente di guida.')
      return false
    } else {
      seterrorDriverlicense('')
      return true
    }
  }

  //================= Validation Sector ===============================

  function validateSector () {
    const sectorData = sectorRef?.current?.getValue()

    if (!sectorData) {
      setErrorSector('Seleziona il tuo Settore.')
      return false
    } else {
      setErrorSector('')
      return true
    }
  }

  //===========================Sector Level ==============================

  const handleSectorLevelChange = e => {
    const value = e?.target?.value ?? e?.value
    const label = e?.target?.label ?? e?.label

    setSelectedOptionsSector(prevOptions => ({
      ...prevOptions,
      submenu: label,
      subSubmenu: ''
    }))

    setData(prevData => ({ ...prevData, sector: value }))
    validateSector()
  }

  const handleSubSectorMenuChange = e => {
    const value = e?.target?.value ?? e?.value

    setSelectedOptionsSector(prevOptions => {
      const newOptions = {
        ...prevOptions,
        subSubmenu: value
      }

      const sector = `${newOptions.submenu}, ${value}`
      setData(prevData => ({
        ...prevData,
        sector
      }))

      return newOptions
    })
  }

  function validateVatnumber () {
    const vatNumber = vatNumberRef.current.value
    if (!vatNumber) {
      setErrorVatnumber('Inserisci la partita IVA.')
      return false
    }
    const trimmedVatnumber = vatNumber.trim()
    if (!trimmedVatnumber) {
      setErrorVatnumber('La partita IVA non può essere vuota.')
      return false
    }

    setErrorVatnumber('')
    return true
  }

  function validateProduct () {
    const product = selectedOptionsProduct.subSubmenu
    if (product?.length === 0) {
      setErrorProduct('Inserisci il prodotto.')
      return false
    }

    setErrorProduct('')
    return true
  }

  function validateStreetNumber () {
    const street_number = streetNumberRef.current.value
    if (!street_number) {
      seterrorStreetnumber('Inserisci il numero civico.')
      return false
    }
    const trimmedStreetnumber = street_number.trim()
    if (!trimmedStreetnumber) {
      seterrorStreetnumber('Il numero civico non può essere vuoto.')
      return false
    }
    seterrorStreetnumber('')
    return true
  }

  function validateZipCode () {
    const zip_code = zipCodeRef.current.value
    if (!zip_code) {
      seterrorZipcode('Inserisci il tuo Cap.')
      return false
    }
    const trimmedZipcode = zip_code.trim()
    if (!trimmedZipcode) {
      seterrorZipcode('Il CAP non può essere vuoto.')
      return false
    }
    if (!/^\d+$/.test(trimmedZipcode)) {
      seterrorZipcode('Il CAP può contenere solo numeri.')
      return false
    }
    if (trimmedZipcode.length < 5) {
      seterrorZipcode('Il CAP deve contenere almeno 5 cifre.')
      return false
    }
    seterrorZipcode('')
    return true
  }

  function validateCountry () {
    if (countryRef.current !== null) {
      const country = countryRef.current.value
      if (!country) {
        seterrorCountry('Nazione non selezionato.')
        return false
      }
      seterrorCountry('')
      return true
    }
  }

  function validateProvince () {
    if (provinceRef?.current !== null) {
      const province = provinceRef?.current?.value
      if (!province) {
        seterrorProvince('Inserisci la tua provincia.')
        return false
      }
      seterrorProvince('')
      return true
    } else {
      return true
    }
  }

  const validateCity = () => {
    let city

    if (data?.country === 'Italia') {
      if (data !== 'undefined') {
        if (!data?.city || !data?.province) {
          seterrorCity('Comune non selezionato.')
          return false
        }
      }
    } else {
      if (cityRef?.current) {
        city = cityRef.current.value ?? ''
        if (!city.trim()) {
          seterrorCity('Inserisci la tua Comune.')
          return false
        }
      }
    }
    seterrorCity('')
    return true
  }

  const validateBirthDate = () => {
    const date = birthDateRef.current.value
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (!date) {
      seterrorBirthDate('inserisci una data di nascita valida.')
      return false
    } else if (new Date(date).getTime() >= today.getTime()) {
      seterrorBirthDate('inserisci una data di nascita valida.')
      return false
    } else {
      seterrorBirthDate('')
      return true
    }
  }

  const validatePrivacy = () => {
    const privacy = eula_consentRef.current.checked
    if (!privacy) {
      setErrorPrivacyAccepted('Questo campo è obbligatorio.')
      return false
    } else {
      setErrorPrivacyAccepted('')
      return true
    }
  }

  const validatePrivacyContent = () => {
    const privacy = privacy_consentRef.current.checked
    if (!privacy) {
      setErrorPrivacyContentAccepted('Questo campo è obbligatorio.')
      return false
    } else {
      setErrorPrivacyContentAccepted('')
      return true
    }
  }

  const generatePassword = () => {
    const pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/
    const characters =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@$!%*?&'
    let password = ''
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      password += characters.charAt(randomIndex)
    }
    if (!pattern.test(password)) {
      return generatePassword()
    }
    return password
  }

  const handleAutoGeneratePassword = () => {
    const newPassword = generatePassword()
    setAutoPassword(newPassword)
  }

  const onChangeData = e => {
    const { name, value, type, files } = e.target
    let newValue

    if (type === 'number') {
      newValue = value.replace(/\D/g, '').slice(0, 5)
    } else if (type === 'file') {
      newValue = files[0]
    } else {
      newValue = value
    }

    if (name === 'country') {
      if (value === 'Italia') {
        setData(prevData => ({
          ...prevData,
          country: newValue,
          city: '',
          province: ''
        }))
      } else {
        setData(prevData => ({
          ...prevData,
          country: newValue,
          city: '',
          province: ''
        }))
      }
    } else {
      setData(prevData => ({ ...prevData, [name]: newValue }))
    }
  }

  const onChangeProductData = e => {
    console.log(e, 'product')
    const productValues =
      e?.length > 0
        ? e.map(option => ({
            label: option.label,
            value: option.value
          }))
        : null
    setSelectedOptionsProduct(prevOptions => ({
      subSubmenu: productValues
    }))
    setData(prevData => ({
      ...prevData,
      specialization: productValues
    }))
  }

  // useEffect(() => {
  //     validateSubMenu();
  //     validateSubMenuInput();
  // }, [selectedOptionsEducation]);

  const handleEducationLevelChange = e => {
    const { value } = e.target

    setSelectedOptionsEducation(prevOptions => ({
      ...prevOptions,
      submenu: value,
      subSubmenu: ''
    }))

    setData(prevData => ({ ...prevData, qualification: value }))
    validateQualification()
  }

  const handleSubsubmenuChange = e => {
    const { value } = e.target

    setSelectedOptionsEducation(prevOptions => {
      const newOptions = {
        ...prevOptions,
        subSubmenu: value
      }

      const qualification = `${newOptions.submenu}, ${value}`
      setData(prevData => ({
        ...prevData,
        qualification
      }))

      return newOptions
    })
  }

  const handleFiscalCodeChange = e => {
    const { name, value, type } = e.target
    let newValue
    if (type === 'text') {
      newValue = value.slice(0, 16)
      setData(prevData => ({ ...prevData, [name]: newValue }))
    }
  }

  const handlePhoneChange = value => {
    if (typeof value !== 'undefined') {
      if (!value.startsWith('+')) {
        value = '+' + value
      }
      setData(prevData => ({ ...prevData, phone: value }))
    }
  }

  const handleWorkPhoneChange = value => {
    if (typeof value !== 'undefined') {
      if (!value.startsWith('+')) {
        value = '+' + value
      }
      setData(prevData => ({ ...prevData, workPhone: value }))
    }
  }

  const handleChangeDriver = selectedOptions => {
    const licenseTypes = selectedOptions
      ? selectedOptions.map(option => ({ licenceType: option.label }))
      : []
    setModifyDriverlicenseData(licenseTypes)
    setSelectedOptions(selectedOptions)
    seterrorDriverlicense('')
  }

  const handleCheckboxChange = e => {
    const { name, checked } = e.target

    setData(prevData => ({
      ...prevData,
      ...(name === 'tel_visible'
        ? {
            tel_consent: checked
            // e_mail_consent: checked,
          }
        : {}),
      [name]: checked
    }))
  }

  const handleDateChange = event => {
    const date = event.target.value
    if (date.length < 11) {
      setData({ ...data, birth_date: date })
    }
  }

  const handleChangeCity = async selectedOptions => {
    setIsLoading(true)
    console.log(selectedOptions, 'selectedOptions')
    if (selectedOptions !== null) {
      setTimeout(() => {
        const [city, province] = selectedOptions?.label.split(', ')
        // const newSelectedOptions = {
        //     city: city,
        //     province: province,
        // };
        setData(prevData => ({
          ...prevData,

          city: city,
          province: province
        }))
        setSelectedCity(selectedOptions)
        setIsLoading(false)

        seterrorCity('')
      }, 1000)
    } else {
      setData(prevData => ({
        ...prevData,
        address: {
          ...prevData.address,
          city: '',
          province: ''
        }
      }))
      setSelectedCity(null)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    console.log(data, 'data')
  }, [data])

  const handleCheckboxChange1 = e => {
    setPrivacyAccepted(e.target.checked)
    if (e.target.checked) {
      setErrorPrivacyAccepted('')
    }
    validatePrivacy()
  }

  const handleCheckboxChange2 = e => {
    setPrivacyContentAccepted(e.target.checked)
    if (e.target.checked) {
      setErrorPrivacyContentAccepted('')
    }
    validatePrivacyContent()
  }

  function validateForm () {
    let fname = validateFname()
    let lname = validateLname()
    let email = validateEmail()
    let title = validateTitle()
    let ficalC = validateFiscalCode()
    let phonN = validatePhone()
    let workPhonN = validateWorkPhone()
    //let qualificationT = validateQualification()
    // let submenu = validateSubMenu();
    // let submenuInput = validateSubMenuInput();
    let driverlicenseV = validateDriverlicense()
    let sectorV = validateSector()
    let vatnumberV = validateVatnumber()
    let productV = validateProduct()
    let streetNum = validateStreetNumber()
    let zipCode = validateZipCode()
    let countryV = validateCountry()
    let cityV = validateCity()
    let provinceV = validateProvince()
    let birthDateV = validateBirthDate()
    let privacy = validatePrivacy()
    let privacyContent = validatePrivacyContent()

    const validall =
      fname &&
      lname &&
      email &&
      phonN &&
      workPhonN &&
      title &&
      ficalC &&
      //qualificationT &&
      // submenu &&
      // submenuInput &&
      driverlicenseV &&
      sectorV &&
      vatnumberV &&
      productV &&
      streetNum &&
      zipCode &&
      countryV &&
      cityV &&
      provinceV &&
      birthDateV &&
      privacy &&
      privacyContent
    return validall
  }

  useEffect(() => {
    if (error) {
      if (errortitle) {
        titleRef.current.focus()
      } else if (errorfirst_name) {
        firstNameRef.current.focus()
      } else if (errorlast_name) {
        lastNameRef.current.focus()
      } else if (errorbirthDate) {
        birthDateRef.current.focus()
      } else if (errorfiscal_code) {
        fiscalCodeRef.current.focus()
      } else if (errorphone) {
        phonRef?.current?.numberInputRef.focus()
      } else if (erroremail) {
        emailRef.current.focus()
      } else if (errorqualification) {
        qualificationRef?.current?.focus()
      } else if (errorSubMenu) {
        subqualificationRef.current.focus()
      } else if (errorDriverlicense) {
        driverLicenseRef.current.focus()
      } else if (errorProduct) {
        productRef.current.focus()
      } else if (errorSector) {
        sectorRef.current.focus()
      } else if (errorVatnumber) {
        vatNumberRef.current.focus()
      } else if (errorWorkPhone) {
        workPhoneRef.current.focus()
      } else if (errorCountry) {
        countryRef.current.focus()
      } else if (errorStreetnumber) {
        streetNumberRef.current.focus()
      } else if (errorCity) {
        cityRef.current.focus()
      } else if (errorProvince) {
        provinceRef.current.focus()
      } else if (errorZipcode) {
        zipCodeRef.current.focus()
      } else if (errorprivacyAccepted) {
        eula_consentRef.current.focus()
      } else if (errorPrivacyContentAccepted) {
        privacy_consentRef.current.focus()
      }

      setError(false)
    }
  }, [
    error,
    errortitle,
    errorfirst_name,
    errorlast_name,
    errorbirthDate,
    errorfiscal_code,
    errorphone,
    erroremail,
    errorqualification,
    errorSubMenu,
    errorDriverlicense,
    errorProduct,
    errorSector,
    errorVatnumber,
    errorWorkPhone,
    errorCountry,
    errorCity,
    errorProvince,
    errorStreetnumber,
    errorZipcode,
    errorprivacyAccepted,
    errorPrivacyContentAccepted
  ])

  const handlePasswordUpdate = newPassword => {
    const token = localStorage.getItem('token')
    if (token) {
      props.requestCandidateProfile({
        data: {
          username: data.email,
          password: newPassword.password1,
          password2: newPassword.password2
        },
        token: token
      })
    }
  }

  function onSubmitForm (e) {
    e.preventDefault()
    //props.userLogout()
    const token = localStorage.getItem('token')
    if (validateForm()) {
      props.requestCandidateProfile({
        data: {
          username: data.email,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          //password: autopassword,
          //password2: autopassword,
          cellphone: data.phone,
          name: data.first_name,
          // tel_consent: true,
          // e_mail_consent: true,
          surname: data.last_name,
          description: 'Some description',
          title: {
            title: data.title
          },
          educationalLevel: {
            levelType: data.qualification
          },

          flag_agent: true,
          flag_employee: false,
          flag_professional: false,
          fiscal_code: data.fiscal_code,
          product: data.product,
          contract_type: [
            {
              contract_name: 'Contract 1'
            },
            {
              contract_name: 'Contract 2'
            }
          ],
          availability: [
            {
              availability_type: 'Availability 1'
            },
            {
              availability_type: 'Availability 2'
            }
          ],
          sector: {
            sectorName: selectedOptionsSector.submenu
          },
          subsector: {
            subsector_name: 'Subsector 1'
          },
          driving_licence: modifydriverlicenseData,
          birthdate: data.birth_date,
          document: null,
          minority_group: data.minority_group,
          travel_available: data.travel_available,
          product: selectedOptionsProduct.subSubmenu.map(product => ({
            product: product.label
          })),
          tel_visible: data.tel_visible,
          tel_consent: data.tel_consent,
          e_mail_consent: data.e_mail_consent,
          eula_consent: privacyAccepted,
          privacy_consent: privacyContentAccepted,
          work_phone: data.workPhone,
          address: {
            street: data.street_number,
            city: data.city,
            zip_code: data.zip_code,
            state: null,
            province: data.province,
            country: data.country
          }
        },
        token: token
      })

      setError(false)
    } else {
      setError(true)
    }
  }
  const handleOnPasswordConfirm = name => {
    setShowModal(name)
  }

  const handleCloseModal = () => {
    setShowModal('')
  }

  useEffect(() => {
    const registerdata = props?.candidate?.registerData

    if (registerdata) {
      const { Status, Message } = registerdata.data
      props.candidate.registerData = undefined
      props.agent.registerData = undefined

      if (Status === 'Success') {
        toast.success('Completato', { theme: 'colored', autoClose: 8000 })
        navigate('/resume', { state: { username: data.email } })
      } else {
        toast.error(Message, { theme: 'colored', autoClose: 8000 })
        setError(true)
      }
    }
  }, [props.candidate.registerData, props.agent.registerData])

  useEffect(() => {
    if (props.agent.loginData) {
      props.requestGetCandidate({
        token: props.agent.loginData?.data.Message.access,
        data: { username: props.agent.loginData?.data.Message.username }
      })
    }
  }, [props.agent.loginData])

  useEffect(() => {
    const source = props.agent.GetCandidateData?.data.Message?.[0] ?? {}
    console.log(source, 'source')
    setData(p => ({
      ...p,
      ...source,
      first_name: source?.name,
      last_name: source?.surname,
      email: source?.e_mail,
      phone: source?.cellphone ? source?.cellphone : data.phone,
      birth_date: source?.birthdate,
      title: source?.title?.title,
      city: source?.address?.[0]?.city,
      province: source?.address?.[0]?.province,
      country: source?.address?.[0]?.country,
      zip_code: source?.address?.[0]?.zip_code,
      street_number: source?.address?.[0]?.street,
      vat_number: source.vat_number,
      website: source.website,
      workPhone: source.work_phone
      // tel_consent: true,
      // e_mail_consent: true,
    }))

    setSelectedOptions(p => {
      const uniqueSet = new Set([
        ...p.map(JSON.stringify),
        ...(source?.driving_licence ?? []).map(i =>
          JSON.stringify({
            label: i.licenceType,
            value: driverlicenseData.find(el => el.label === i.licenceType)
              ?.value
          })
        )
      ])

      return Array.from(uniqueSet)
        .map(JSON.parse)
        .filter(i => i.value && i.label)
    })

    setSelectedOptionsSector(p => ({
      submenu: source?.sector?.sectorName,
      subSubmenu: source?.subsector?.subsector_name
    }))

    setSelectedOptionsProduct(p => ({
      subSubmenu: source?.product?.map(i => ({
        label: i.product,
        value: i.id
      }))
    }))
  }, [props.agent.GetCandidateData, driverlicenseData])

  return (
    <>
      <Header />
      <div className='registration_sec'>
        <div className='container'>
          <div className='new_research_btn mt-4 text-end'>
            <button
              className='btn btn_default btn_orange_outline'
              type='button'
              onClick={() => handleOnPasswordConfirm('password-confirm')}
            >
              Cambia password
            </button>
          </div>
          <form className='registration_form' onSubmit={onSubmitForm}>
            <div className='registration_title'>
              <h3>Profilo Agente</h3>
            </div>
            <div className='row registration_row g-4'>
              <BasicInfoRegister
                //================== title ========================
                data={data}
                titleData={titleData}
                onChangeData={onChangeData}
                validateTitle={validateTitle}
                titleRef={titleRef}
                errortitle={errortitle}
                // ==================== First Name ==========================

                validateFname={validateFname}
                firstNameRef={firstNameRef}
                errorfirst_name={errorfirst_name}
                // =====================Last Name ===========================

                validateLname={validateLname}
                lastNameRef={lastNameRef}
                errorlast_name={errorlast_name}
                // ===================== Birth Date ===========================

                handleDateChange={handleDateChange}
                validateBirthDate={validateBirthDate}
                birthDateRef={birthDateRef}
                errorbirthDate={errorbirthDate}
                mystyle={mystyle}
                t={t}
              />

              <FiscalCode
                data={data}
                fiscalCodeRef={fiscalCodeRef}
                handleFiscalCodeChange={handleFiscalCodeChange}
                validateFiscalCode={validateFiscalCode}
                errorfiscal_code={errorfiscal_code}
                mystyle={mystyle}
                t={t}
              />
              <Cellphone
                data={data}
                phonRef={phonRef}
                handlePhoneChange={handlePhoneChange}
                validatePhone={validatePhone}
                errorphone={errorphone}
                mystyle={mystyle}
                t={t}
              />
              <div className='registration_col form-group col-md-6 col-12'>
                <label htmlFor='email' className='label'>
                  {/* E-mail */}
                  {t('register.email')}
                </label>
                <input
                  type='email'
                  className={`form-control set_max_width ${
                    erroremail ? 'error' : ''
                  }`}
                  placeholder={t('register.email')}
                  id='email'
                  name='email'
                  ref={emailRef}
                  value={data.email || ''}
                  onChange={onChangeData}
                  onBlur={validateEmail}
                />
                {erroremail && <div style={mystyle}>{erroremail}</div>}
              </div>
            </div>

            <OtherTerms
              data={data}
              handleCheckboxChange={handleCheckboxChange}
              t={t}
            />
            <div className='row registration_row g-4'>
              {/* <QualificationLevel
                                selectedOptionsEducation={selectedOptionsEducation}
                                handleEducationLevelChange={handleEducationLevelChange}
                                validateQualification={validateQualification}
                                qualificationRef={qualificationRef}
                                errorqualification={errorqualification}
                                handleSubsubmenuChange={handleSubsubmenuChange}
                                validateSubMenu={validateSubMenu}
                                validateSubMenuInput={validateSubMenuInput}
                                subqualificationRef={subqualificationRef}
                                submenuOptions={submenuOptions}
                                errorSubMenu={errorSubMenu}
                                mystyle={mystyle}
                                t={t}
                            /> */}

              <Driverlicense
                driverlicenseData={driverlicenseData}
                selectedOptions={selectedOptions}
                handleChangeDriver={handleChangeDriver}
                validateDriverlicense={validateDriverlicense}
                driverLicenseRef={driverLicenseRef}
                errorDriverlicense={errorDriverlicense}
                mystyle={mystyle}
                t={t}
              />
            </div>

            <div className='row registration_row g-4'>
              <SectorLevel
                selectedOptionsSector={selectedOptionsSector}
                handleSectorLevelChange={handleSectorLevelChange}
                validateSector={validateSector}
                sectorRef={sectorRef}
                errorSector={errorSector}
                handleSubSectorLevelChange={handleSubSectorMenuChange}
                subsectorRef={subsectorRef}
                submenuSector={submenuSector}
                mystyle={mystyle}
                t={t}
              />

              <div className='registration_col form-group  col-md-6 col-12'>
                <label htmlFor='products_marketed' className='label'>
                  Prodotti commercializzati
                </label>
                <Select
                  className={`form-control set_max_width select_dropdown ${
                    errorProduct ? 'error' : ''
                  }`}
                  options={
                    props.agent.GetProductsData?.data.Message.map(i => ({
                      value: i.id,
                      label: i.product
                    })) || []
                  }
                  placeholder='Prodotti commercializzati'
                  id='product'
                  name='product'
                  ref={productRef}
                  isMulti
                  value={
                    selectedOptionsProduct.subSubmenu
                      ? selectedOptionsProduct.subSubmenu
                      : null
                  }
                  onChange={onChangeProductData}
                  onBlur={validateLname}
                />
                {errorProduct && <div style={mystyle}>{errorProduct}</div>}
              </div>

              <div className='registration_col form-group col-md-6 col-12'>
                <label htmlFor='vat_number' className='label'>
                  Partita Iva
                </label>
                <input
                  type='text'
                  className={`form-control set_max_width ${
                    errorVatnumber ? 'error' : ''
                  }`}
                  placeholder='Partita Iva'
                  id='vat_number'
                  name='vat_number'
                  ref={vatNumberRef}
                  value={data.vat_number || ''}
                  onChange={onChangeData}
                  onBlur={validateVatnumber}
                />
                {errorVatnumber && <div style={mystyle}>{errorVatnumber}</div>}
              </div>
            </div>
            <CategoriesCheckbox
              data={data}
              handleCheckboxChange={handleCheckboxChange}
            />

            <div className='registration_col form-group col-md-6 col-12'>
              <label htmlFor='workPhone' className='label'>
                Numero cellulare di lavoro
              </label>
              <PhoneInput
                className={`form-control set_max_width numero_cellulare ${
                  errorWorkPhone ? 'error' : ''
                }`}
                country={'it'}
                localization={it}
                value={data?.workPhone || ''}
                ref={workPhoneRef}
                placeholder={t('register.cellphone')}
                onChange={e => handleWorkPhoneChange(e)}
                onBlur={validateWorkPhone}
                enableLongNumbers={18}
              />
              {errorWorkPhone && <div style={mystyle}>{errorWorkPhone}</div>}
            </div>

            <div className='contry_form'>
              <h6 className='contry_form_title'>
                {t('register.candidate.label')}
              </h6>
              <LocationForm
                data={data}
                //==================Country================

                countryRef={countryRef}
                onChangeData={onChangeData}
                validateCountry={validateCountry}
                sortedCountryData={sortedCountryData}
                errorCountry={errorCountry}
                //===========Street=========================

                validateStreetNumber={validateStreetNumber}
                streetNumberRef={streetNumberRef}
                errorStreetnumber={errorStreetnumber}
                //=============ProvinceCommue===============

                municipalities={municipalities}
                selectedCity={selectedCity}
                isLoading={isLoading}
                handleChangeCity={handleChangeCity}
                cityRef={cityRef}
                validateCity={validateCity}
                errorCity={errorCity}
                provinceRef={provinceRef}
                validateProvince={validateProvince}
                errorProvince={errorProvince}
                //=======ZipCode=====================

                zipCodeRef={zipCodeRef}
                validateZipCode={validateZipCode}
                errorZipcode={errorZipcode}
                mystyle={mystyle}
                t={t}
              />
            </div>

            <TermsAndService
              privacyAccepted={privacyAccepted}
              privacyContentAccepted={privacyContentAccepted}
              handleCheckboxChange1={handleCheckboxChange1}
              handleCheckboxChange2={handleCheckboxChange2}
              validatePrivacy={validatePrivacy}
              validatePrivacyContent={validatePrivacyContent}
              errorprivacyAccepted={errorprivacyAccepted}
              errorPrivacyContentAccepted={errorPrivacyContentAccepted}
              eula_consentRef={eula_consentRef}
              privacy_consentRef={privacy_consentRef}
              mystyle={mystyle}
              t={t}
            />

            <div className='registration_button text-center'>
              <button
                type='submit'
                className='btn'
                onClick={handleAutoGeneratePassword}
              >
                aggiorna i miei dati
              </button>
            </div>
          </form>
        </div>
      </div>
      {showModal === 'password-confirm' && (
        <div
          className='modal fade show custom_modal'
          tabIndex='-1'
          aria-labelledby='passwordConFirmModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  type='button'
                  className='btn-close'
                  onClick={handleCloseModal}
                  aria-label='Close'
                ></button>
              </div>
              <div className='modal-body'>
                <PasswordResetConfirm onPasswordUpdate={handlePasswordUpdate} />
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return { candidate: state.candidate, agent: state.agent }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestRegister,
      userLogout,
      requestGetList,
      requestGetCandidate,
      requestCandidateProfile,
      requestGetProducts
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AgentProfile)
