// File: /src/Components/resume/mock.js
// Created Date: Tuesday February 11th 2025
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Tuesday February 11th 2025 11:24:30 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

export const MOCK_LINKEDIN_DATA = {
  status: "Success",
  Message: {
    education: [
      {
        type: "",
        monthFrom: null,
        yearFrom: "1997",
        name: "I.t.i.s. F. Corni",
        monthTo: null,
        yearTo: "2004",
        address: {
          street: null,
          city: null,
          province: "Abano Terme, Padova",
          country: "Italia",
        },
      },
    ],
    works: [
      {
        monthFrom: "Dec",
        yearFrom: "2016",
        monthTo: null,
        yearTo: null,
        description: "",
        company: "Elisium s.r.l.",
        role: "Fondatore e CEO",
        address: {
          street: null,
          city: null,
          province: "Modena",
          country: "Italia",
        },
        reccomendationLetter: null,
        sector: null,
      },
      {
        monthFrom: "Dec",
        yearFrom: "2011",
        monthTo: "May",
        yearTo: "2016",
        description:
          "Ho seguito il ramo d'azienda relativo alla PCD in ogni sua parte: dal marketing al project management. Da marzo 2013 ho incrementato le mie competenze seguendo diversi altri prodotti come Consulente Commerciale e portando in azienda clienti importanti come Pfizer, sia Italia che Europa, e Graniti Fiandre.",
        company: "Progetti di Impresa s.r.l.",
        role: "Responsabile Tecnico, Key Account Manager",
        address: {
          street: "Largo Del Pozzo",
          city: "Modena",
          province: "Modena",
          country: "Italia",
        },
        reccomendationLetter: null,
        sector: null,
      },
      {
        monthFrom: "Feb",
        yearFrom: "2011",
        monthTo: "Nov",
        yearTo: "2011",
        description:
          "Ho seguito il ramo d'azienda relativo alla PCD (Penna e Carta Digitale) per quanto concerne il suo sviluppo software e mi occupavo dell'assistenza clienti per il gestionale MGC2000, software di punta dell'azienda.",
        company: "MGC srl",
        role: "Apprendista Programmatore",
        address: {
          street: null,
          city: null,
          province: "Modena",
          country: "Italia",
        },
        reccomendationLetter: null,
        sector: null,
      },
      {
        monthFrom: "Sep",
        yearFrom: "2009",
        monthTo: "Feb",
        yearTo: "2011",
        description:
          "Mi occupavo della creazione ed inserimento online degli annunci per gli autoveicoli usati. In realtà, essendo la figura maggiormente qualificata nell'uso di strumenti informatici ho finito per occuparmi e svolgere diverse mansioni oltre a quelle originarie. Dall'help desk interno per i problemi di rapida ed urgente soluzione, allo sviluppo di un foglio Excel con macro VBA da usare come \"preventivatore\" per la forza vendite, giusto per citare un paio di esempi.",
        company: "Car Mix SpA",
        role: "Consulente vendite usato, responsabile annunci on-line",
        address: {
          street: null,
          city: null,
          province: "Modena",
          country: "Italia",
        },
        reccomendationLetter: null,
        sector: null,
      },
      {
        monthFrom: "Sep",
        yearFrom: "2007",
        monthTo: "Jan",
        yearTo: "2009",
        description:
          "Riparazione dell'HW, gestione prodotti rientrati in garanzia (contatto Casamadre, apertura RMA, etc.). Gestione di tutta la pratica, dall'entrata in laboratorio dell'HW non funzionante fino alla sua riconsegna al cliente.",
        company: "Computer discount Modena",
        role: "Tecnico di laboratorio",
        address: {
          street: null,
          city: null,
          province: "Modena",
          country: "Italia",
        },
        reccomendationLetter: null,
        sector: null,
      },
      {
        monthFrom: "May",
        yearFrom: "2007",
        monthTo: "Aug",
        yearTo: "2007",
        description:
          "Mi occupavo dell'assemblaggio finale del prodotto secondo commessa prima del suo trasferimento al reparto spedizioni.",
        company: "Dini Argeo srl",
        role: "Operaio 3° lvl",
        address: {
          street: null,
          city: null,
          province: null,
          country: null,
        },
        reccomendationLetter: null,
        sector: null,
      },
    ],
    languages: [
      {
        language: "Inglese",
        proficiency: "",
        levelListening: null,
        levelReading: null,
        levelWritten: null,
        levelSpoken: null,
      },
    ],
    skills: [
      {
        skillName: "SQL",
      },
      {
        skillName: "XML",
      },
      {
        skillName: "Software Development",
      },
      {
        skillName: "Java",
      },
      {
        skillName: "Software Project Management",
      },
      {
        skillName: "Visual Studio",
      },
      {
        skillName: "MySQL",
      },
      {
        skillName: "PHP",
      },
      {
        skillName: "HTML",
      },
      {
        skillName: "Web Development",
      },
      {
        skillName: "Python",
      },
      {
        skillName: "Email Marketing",
      },
      {
        skillName: "Graphic Design",
      },
      {
        skillName: "Sales Management",
      },
      {
        skillName: "Microsoft Office",
      },
    ],
  },
};
