// File: /src/Redux/franchisorReducer.js
// Created Date: Wednesday September 11th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Wednesday September 11th 2024 10:59:51 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import {
  RECEIVE_REGISTER,
  RECEIVE_LOGIN,
  // RECEIVE_REFRESH_LOGIN,
  RECEIVE_GET_LIST,
  RECEIVE_GET_LOCATION,
  //==============regione==========================
  RECEIVE_GET_REGIONE_LIST,
  //==============education level===================
  RECEIVE_GET_EDUCATION_LEVEL_LIST,
  //==============sector level======================
  RECEIVE_GET_SECTOR_LIST,
  RECEIVE_GET_CANDIDATE,
  RECEIVE_CANDIDATE_PROFILE,
  RECEIVE_COMPANY_GET_CV_LIST,
  RECEIVE_COMPANY_REGISTER,
  RECEIVE_COMPANY_DETAILS,
  RECEIVE_COMPANY_UPDATE,
  RECEIVE_GET_FILTER_OPTIONS,
  RECEIVE_GET_FRANCHISOR_RESULTS,
  RECEIVE_PUBLISH_FRANCHISING_AD,
  RECEIVE_SAVE_FILTER_OPTIONS,
  RECEIVE_SEARCH_FRANCHISOR,
  RECEIVE_LINKEDIN_LOGIN,
  RECEIVE_REMOVE_PUBLISH_FRANCHISING_AD,
} from "./actions";

export default (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_REGISTER:
      return {
        ...state,
        registerData: data,
      };

    case RECEIVE_LINKEDIN_LOGIN:
      return {
        ...state,
        loginData: (() => {
          if (data?.data?.Message) {
            return data;
          }
          return {
            data: {
              Message: {
                ...data.data,
              },
            },
          };
        })(),
      };

    case RECEIVE_LOGIN:
      return {
        ...state,
        loginData: data,
      };

    // case RECEIVE_REFRESH_LOGIN:
    //   return {
    //     ...state,
    //     loginrefreshData: data,
    //   };

    case RECEIVE_GET_LIST:
      return {
        ...state,
        getlistData: data,
      };

    case RECEIVE_GET_LOCATION:
      return {
        ...state,
        getlocation: data,
      };

    case RECEIVE_GET_REGIONE_LIST:
      return {
        ...state,
        getRegionData: data,
      };

    case RECEIVE_GET_EDUCATION_LEVEL_LIST:
      return {
        ...state,
        EducationlevelData: data,
      };

    case RECEIVE_GET_SECTOR_LIST:
      return {
        ...state,
        SectorData: data,
      };

    case RECEIVE_GET_CANDIDATE:
      return {
        ...state,
        GetCandidateData: data,
      };

    case RECEIVE_CANDIDATE_PROFILE:
      return {
        ...state,
        CandidateProfileData: data,
      };

    case RECEIVE_COMPANY_GET_CV_LIST:
      return {
        ...state,
        cvListData: data,
      };

    case RECEIVE_COMPANY_REGISTER:
      return {
        ...state,
        comRegisterData: data,
      };

    case RECEIVE_COMPANY_DETAILS:
      return {
        ...state,
        getCompanyData: data,
      };

    case RECEIVE_COMPANY_UPDATE:
      return {
        ...state,
        companyUpdateData: data,
      };

    case RECEIVE_GET_FILTER_OPTIONS:
      return {
        ...state,
        GetFilterOptionsData: data,
      };

    case RECEIVE_GET_FRANCHISOR_RESULTS:
      return {
        ...state,
        GetFranchisorResultsData: data,
      };

    case RECEIVE_PUBLISH_FRANCHISING_AD:
      return {
        ...state,
        publishFranchisingAdData: data,
      };

    case RECEIVE_REMOVE_PUBLISH_FRANCHISING_AD:
      return {
        ...state,
        publishFranchisingAdData: null,
      };

    case RECEIVE_SAVE_FILTER_OPTIONS:
      return {
        ...state,
        saveFilterOptionsData: data,
      };

    case RECEIVE_SEARCH_FRANCHISOR:
      return {
        ...state,
        searchFranchisorData: data,
      };

    default:
      return state;
  }
};
