// File: /src/Franchisor/search-franchisor/SearchResults.js
// Created Date: Friday September 20th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Friday September 20th 2024 11:26:34 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "../../Components/Footer";
import CandidateJobsDetails from "../../Company/jobs/Candidate-jobs-details";
import Header from "../../Components/Header";
import { useAppNavigation } from "../../utils/routing";
import CompanyLogo from "../../images/logo.png";
import { resetGetJobList } from "../../Redux/actions";
import FranchisorJobDetail from "./FranchisorJobDetail";

const SearchResults = (props) => {
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [showModal, setShowModal] = useState("");
  const { location, navigateTo } = useAppNavigation();

  const user = true;
  // props.candidate?.loginData?.data?.Message?.id ||
  // props.franchisor?.comRegisterData?.data?.Message;

  useEffect(() => {
    const data = location.state?.jobListData || [];
    setJobs(data);
  }, [location.state?.jobListData]);

  const getValue = (value) => (user ? value : "xxxxxxxxxxxxxxxx");

  const redirectToJobFinder = () => {
    props.resetGetJobList();
    navigateTo("/franchisor/search2", { state: { jobListData: [] } });
  };

  const redirectToJobDetails = (id) => {
    let destination = location.pathname;
    const newOptions = { state: { jobListData: jobs } };
    // if (!props.candidate?.loginData) {
    //   setShowModal("login");
    //   newOptions.params = {
    //     userType: "franchisee",
    //   };
    // } else if (!props.candidate?.loginData?.data?.Message?.flag_franchisee) {
    //   navigateTo("/franchisor/search");
    // } else {
    setJobId(id);
    navigateTo(destination, newOptions);
    // }
  };

  return (
    <>
      <Header showModal={showModal} setShowModal={setShowModal} />
      <div className="container">
        <div className="new_research_btn mt-4 text-end">
          <button
            className="btn btn_default btn_orange_outline"
            type="button"
            onClick={redirectToJobFinder}
          >
            Nuova ricerca
          </button>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            {jobs.length > 0 && (
              <div className="job_listing_sec">
                {/* <div className="container"> */}

                <div className="job_listing_blocks">
                  {jobs.map((job) => (
                    <div
                      key={job.id}
                      className={`job_listing_block ${
                        !user ? "blur_effect" : ""
                      }`}
                      onClick={() => redirectToJobDetails(job.id)}
                    >
                      <a className="redirect_a">
                        <div className="job_listing_block_inner">
                          <div className="job_title">
                            <h2>{getValue(job.e_mail || "")}</h2>
                          </div>
                          <div className="logo_detailbtn">
                            <div className="company_logo">
                              <img src={CompanyLogo} alt="company logo" />
                            </div>
                            {/* <div className="detail_btn">
                              <button
                                className="btn btn_default btn_green_outline"
                                type="button"
                                // onClick={() => redirectToJobDetails(job.id)}
                              >
                                Salva
                              </button>
                            </div> */}
                          </div>
                          {/* <div className="job_description">
                                              <p>{getValue(job.job_description)}</p>
                                          </div> */}
                          <div className="sector_name">
                            <p>
                              <strong>Name:&nbsp;&nbsp;</strong>
                              {getValue(job?.franchisor?.brand || "")}{" "}
                            </p>
                          </div>
                          <div className="sector_name">
                            <p>
                              <strong>Anno di fondazione:&nbsp;&nbsp;</strong>
                              {getValue(
                                job?.franchisor?.year_brand_creation || ""
                              )}
                            </p>
                          </div>
                          <div className="sector_name">
                            <p>
                              <strong>Punti Vendita:&nbsp;&nbsp;</strong>
                              {getValue(job?.franchisor?.n_store || "")}
                            </p>
                          </div>
                          <div className="sector_name">
                            <p>
                              <strong>Metratura minima:&nbsp;&nbsp;</strong>
                              {getValue(job?.franchisor?.area_min || "")}mq
                            </p>
                          </div>
                          <div className="sector_name">
                            <p>
                              <strong>Finanziabile:&nbsp;&nbsp;</strong>
                              {getValue(
                                String(job?.franchisor?.financeable) === "true"
                                  ? "Si"
                                  : "No"
                              )}
                            </p>
                          </div>
                          <div className="job_salary_address">
                            {/* <div className="job_salary">
                                                  <p>
                                                      <strong>Retribuzione:&nbsp;&nbsp;</strong>
                                                      {getValue(
                                                          job?.salary?.fixedAmount
                                                              ? `${job?.salary?.fixedAmount} ${job?.salary?.reccurency}`
                                                              : job?.salary?.minAmount && job?.salary?.maxAmount
                                                                  ? `${job?.salary?.minAmount} - ${job?.salary?.maxAmount} ${job?.salary?.reccurency}`
                                                                  : ""
                                                      )}
                                                  </p>
                                              </div> */}
                          </div>
                          <div
                            className="job_description"
                            dangerouslySetInnerHTML={{
                              __html: job?.description?.slice(0, 100),
                            }}
                          ></div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
                {/* </div> */}
              </div>
            )}
          </div>
          {jobId && user && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setJobId(null);
              }}
              className="col-md-6 col-12 p-0"
              style={{
                height: "100vh",
                position: "fixed",
                right: 0,
                top: 0,
                zIndex: 1000,
                width: "100vw",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              }}
            >
              <FranchisorJobDetail
                jobDetails={jobs.find((job) => job.id === jobId)}
              />
              <button
                className="btn btn_default btn_orange_outline"
                onClick={() => setJobId(null)}
                style={{ position: "absolute", top: "1.5rem", right: "1rem" }}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return { franchisor: state.franchisor, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetGetJobList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
