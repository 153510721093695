import React from "react";
import Select, { createFilter } from "react-select";

function LocationEdu(props) {
  const {
    i,
    x,
    education,
    setEducation,
    erroreducation,
    sortedCountryData,
    municipalities,
    validateEducationCountry,
    validateEducationCommon,
    validateEducationProvince,
    handleInputEducation,
    handleChangeEducationCity,
    focusRefs,
    isLoading,
    isClearable,
    isSearchable,
    filterConfig,
    mystyle,
    t,
  } = props;

  return (
    <>
      <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
        <div className="form-group">
          <label htmlFor="address.country">{t("location.country")}</label>
          <select
            className={`form-control ${
              erroreducation[i].address.country ? "error" : ""
            }`}
            name="address.country"
            id={"country_education" + i}
            value={x.address.country}
            ref={(el) => {
              focusRefs.education.current[i] =
                focusRefs.education.current[i] || {};
              focusRefs.education.current[i].country_education = el;
            }}
            onChange={(e) => handleInputEducation(e, i)}
            onBlur={() => validateEducationCountry(i)}
            placeholder={t("location.country")}
          >
            <option value="">{t("location.country")}</option>
            {sortedCountryData.map((option, index) => (
              <option key={index} value={option.nativeCountryName}>
                {option.nativeCountryName}
              </option>
            ))}
          </select>
          {erroreducation[i].address.country && (
            <div style={mystyle}>{erroreducation[i].address.country}</div>
          )}
        </div>
      </div>

      {education[i].address?.country?.length > 0 && (
        <>
          {education[i]?.address?.country === "Italia" ? (
            <>
              <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                <div className="form-group">
                  <label>
                    {t("location.city")}, {t("location.province")}
                  </label>
                  <Select
                    className={`form-control select_dropdown ${
                      erroreducation[i].address.city ? "error" : ""
                    }`}
                    options={municipalities}
                    defaultValue={(() => {
                      return x.address.city && x.address.province
                        ? { label: `${x.address.city} ${x.address.province}` }
                        : "";
                    })()}
                    value={
                      x.address.city && x.address.province
                        ? { label: `${x.address.city} ${x.address.province}` }
                        : ""
                    }
                    isLoading={isLoading}
                    isClearable={isClearable}
                    onChange={(e) => {
                      handleChangeEducationCity(e, i);
                      if (!e) {
                        setEducation((prevEducation) => {
                          const newEducation = [...prevEducation];
                          newEducation[i].address.city = "";
                          newEducation[i].address.province = "";
                          return newEducation;
                        });
                      }
                    }}
                    onBlur={() => validateEducationCommon(i)}
                    isSearchable={isSearchable}
                    ref={(el) => {
                      focusRefs.education.current[i] =
                        focusRefs.education.current[i] || {};
                      focusRefs.education.current[i].common_education = el;
                    }}
                    placeholder="Seleziona comune e provincia"
                    filterOption={createFilter(filterConfig)}
                  />
                  {erroreducation[i].address.city && (
                    <div style={mystyle}>{erroreducation[i].address.city}</div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                <div className="form-group">
                  <label htmlFor="address.city">{t("location.city")}</label>
                  <input
                    className={`form-control ${
                      erroreducation[i].address.city ? "error" : ""
                    }`}
                    type="text"
                    name="address.city"
                    id={"common_education" + i}
                    value={x?.address?.city}
                    ref={(el) => {
                      focusRefs.education.current[i] =
                        focusRefs.education.current[i] || {};
                      focusRefs.education.current[i].common_education = el;
                    }}
                    onBlur={() => validateEducationCommon(i)}
                    onChange={(e) => handleInputEducation(e, i)}
                    placeholder={t("location.city")}
                  />
                  {erroreducation[i].address.city && (
                    <div style={mystyle}>{erroreducation[i].address.city}</div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
                <div className="form-group">
                  <label htmlFor="address.province">
                    {t("location.province")}
                  </label>
                  <input
                    className={`form-control ${
                      erroreducation[i].address.province ? "error" : ""
                    }`}
                    type="text"
                    name="address.province"
                    id={"province_education" + i}
                    value={x?.address?.province}
                    ref={(el) => {
                      focusRefs.education.current[i] =
                        focusRefs.education.current[i] || {};
                      focusRefs.education.current[i].province_education = el;
                    }}
                    onBlur={() => validateEducationProvince(i)}
                    onChange={(e) => handleInputEducation(e, i)}
                    placeholder={t("location.province")}
                  />
                  {erroreducation[i].address.province && (
                    <div style={mystyle}>
                      {erroreducation[i].address.province}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default LocationEdu;
