import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestPatchAdvertisement } from '../../Redux/actions'
import Swal from 'sweetalert2'

function PermissionAdvertisment (props) {
  const { item } = props
  const itemId = item.id
  const navigate = useNavigate()
  const [checkedDraft, setCheckedDraft] = useState([])
  const [checkedPublished, setCheckedPublished] = useState([])
  const [checkedSuspended, setCheckedSuspended] = useState([])
  const [checkedClosed, setCheckedClosed] = useState([])
  const [disabledAd, setDisabledAd] = useState(false)
  const [draftId, setDraftId] = useState(item.draft_id)
  const [allStatesFalse, setAllStatesFalse] = useState(false)

  const handleDraftChange = itemId => {
    setCheckedDraft(prevCheckedItems => ({
      ...prevCheckedItems,
      [itemId]: !prevCheckedItems[itemId]
    }))
    console.log(item.draft_id, 'draft_id')
  }

  const handlePublishedChange = itemId => {
    if (allStatesFalse) {
      Swal.fire({
        title: 'Vuoi completare questo annuncio?',
        text: 'Premi si per andare alla pagina di creazione del nuovo annuncio.',
        icon: 'error',
        confirmButtonText: 'Si',
        showCancelButton: true,
        cancelButtonText: 'No',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        allowOutsideClick: false
        // allowEscapeKey: false,
      }).then(result => {
        if (result.isConfirmed) {
          navigate('/company/create-adv?setStep=1&adId=' + itemId)
        }
      })
      return;
    }
    if (!checkedPublished[itemId]) {
      const token = localStorage.getItem('token')
      if (!item.have_been_sponsored) {
        const data = {
          published: true,
          suspended: false
        }
        Swal.fire({
          title: 'Vuoi sponsorizzare questo annuncio?',
          text: 'Premi "Si, sponsorizza" per confermare.',
          icon: 'success',
          confirmButtonText: 'Si, sponsorizza',
          showCancelButton: true,
          cancelButtonText: 'No',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
          allowOutsideClick: false
          // allowEscapeKey: false,
        }).then(result => {
          if (result.isConfirmed) {
            navigate('/company/CreaAnnuncio7_bis?adId=' + itemId)
          
          } else {
            props.requestPatchAdvertisement({
              data: data,
              token: token,
              id: itemId
            })
            setCheckedPublished(prevCheckedItems => ({
              ...prevCheckedItems,
              [itemId]: !prevCheckedItems[itemId]
            }))
            setCheckedSuspended(prevCheckedItems => ({
              ...prevCheckedItems,
              [itemId]: false
            }))
          }
        })
      }

     
    }
  }

  const handleSuspendedChange = itemId => {
    if(!checkedSuspended[itemId]) {
    console.log(itemId, 'itemId')
    const token = localStorage.getItem('token')
    const data = {
      published: false,
      suspended: true
    }
    props.requestPatchAdvertisement({
      data: data,
      token: token,
      id: itemId
    })
    setCheckedSuspended(prevCheckedItems => ({
      ...prevCheckedItems,
      [itemId]: !prevCheckedItems[itemId]
    }))
    console.log(checkedPublished[itemId], 'checkedPublished[itemId]')
    console.log(checkedSuspended, 'checkedSuspended')
    if (checkedPublished[itemId] === true) {
      setCheckedPublished(prevCheckedItems => ({
        ...prevCheckedItems,
        [itemId]: false
      }))
    }
  }
  }

  const isDraftChecked = item.draft_id && item.draft_id !== null ? false : true

  // const isPublishedChecked = (item.published === null || item.published === false || item.have_been_sponsored === null || item.have_been_sponsored === false ) ? false : true;
  useEffect(() => {
    setCheckedPublished(prevItem => ({
      ...prevItem,
      [item.id]: item.published
    }))

    setCheckedSuspended(prevItem => ({
      ...prevItem,
      [item.id]: item.suspended
    }))

    setCheckedClosed(prevItem => ({
      ...prevItem,
      [item.id]: item.closed
    }))

    if (!item.closed && !item.suspended && !item.published) {
      //setDisabledAd(true);
      setAllStatesFalse(true);
    }
  }, [])

  const isClosedChecked = item.closed

  const handleClosedChange = itemId => {
    console.log(checkedClosed, 'checkedClosed')
    if (!checkedClosed[itemId]) {
      console.log('ci sono')
      Swal.fire({
        title: 'Sei sicuro di voler disattivare la pubblicazione?',
        text: 'Non potrai più pubblicare questo annuncio',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, chiudilo',
        cancelButtonText: 'No'
      }).then(result => {
        if (result.isConfirmed) {
          setCheckedClosed(prevCheckedItems => ({
            ...prevCheckedItems,
            [itemId]: !prevCheckedItems[itemId]
          }))
          setCheckedPublished(prevCheckedItems => ({
            ...prevCheckedItems,
            [itemId]: false
          }))

          setCheckedSuspended(prevCheckedItems => ({
            ...prevCheckedItems,
            [itemId]: false
          }))

          setDisabledAd(true)
          const token = localStorage.getItem('token')
          const data = {
            closed: true
          }
          props.requestPatchAdvertisement({
            data: data,
            token: token,
            id: itemId
          })
        }
      })
    }
  }

  const handleEditDraftClick = itemId => {
    console.log(itemId, 'itemId')
    console.log(item.draft_id, 'draftId')
    navigate('/company/create-adv/?setStep=1&adId=' + item.draft_id)
  }

  const handleCreateDraftClick = itemId => {
    const token = localStorage.getItem('token')
    navigate('/company/create-adv/?setStep=1&adId=' + item.id)
  }

  const handleRedirectFranchisor = () => {
    navigate('/franchisor/register2?type=franchisor')
  }

  return (
    <td>
      <div className='advertisement_status'>
        <form className='advertisement_status_form'>
          <div className='form-group'>
            <p>Bozza</p>
            {item.ad_type?.type === 'Franchising' ? (
              <a onClick={handleRedirectFranchisor}>Modifica annuncio</a>
            ) :
            (item.draft_id == null ? (
              checkedPublished[item.id] ? (
                <a onClick={handleCreateDraftClick}>Modifica bozza</a>
              ) : (
                <a onClick={handleCreateDraftClick}>Modifica bozza</a>
              )
            ) : (
              <a onClick={handleEditDraftClick}>Modifica bozza</a>
            )
          )
            }
          </div>

          <div className='form-group'>
            <p>Pubblicato</p>

            <input
              type='checkbox'
              id={`${item.id}_published`}
              checked={checkedPublished[item.id]}
              onChange={() => handlePublishedChange(item.id)}
              disabled={disabledAd}
            />
            <label htmlFor={`${item.id}_published`}></label>
          </div>

          <div className='form-group'>
            <p>Sospeso</p>
            <input
              type='checkbox'
              id={`${item.id}_suspended`}
              checked={checkedSuspended[item.id]}
              onChange={() => handleSuspendedChange(item.id)}
              disabled={disabledAd}
            />
            <label htmlFor={`${item.id}_suspended`}></label>
          </div>

          <div className='form-group'>
            <p>Chiuso</p>
            <input
              type='checkbox'
              id={`${item.id}_closed`}
              checked={checkedClosed[item.id]}
              onChange={() => handleClosedChange(item.id)}
              // disabled
            />
            <label htmlFor={`${item.id}_closed`}></label>
          </div>
        </form>
      </div>
    </td>
  )
}

const mapStateToProps = state => {
  return { company: state.company, candidate: state.candidate }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestPatchAdvertisement
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionAdvertisment)
