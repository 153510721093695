//register
export const REQUEST_REGISTER = "REQUEST_REGISTER";
export const RECEIVE_REGISTER = "RECEIVE_REGISTER";
export const requestRegister = (obj) => ({ type: REQUEST_REGISTER, obj });
export const receiveRegister = (data) => ({ type: RECEIVE_REGISTER, data });

//login
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const requestLogin = (obj) => ({ type: REQUEST_LOGIN, obj });
export const receiveLogin = (data) => ({ type: RECEIVE_LOGIN, data });

export const REQUEST_LINKEDIN_LOGIN = "REQUEST_LINKEDIN_LOGIN";
export const RECEIVE_LINKEDIN_LOGIN = "RECEIVE_LINKEDIN_LOGIN";
export const requestLinkedinLogin = (obj) => ({
  type: REQUEST_LINKEDIN_LOGIN,
  obj,
});
export const receiveLinkedinLogin = (data) => ({
  type: RECEIVE_LINKEDIN_LOGIN,
  data,
});

//===============refresh token ==================================

//refresh login
// export const REQUEST_REFRESH_LOGIN = "REQUEST_REFRESH_LOGIN";
// export const RECEIVE_REFRESH_LOGIN = "RECEIVE_REFRESH_LOGIN";
// export const requestRefreshLogin = (obj) => ({ type: REQUEST_REFRESH_LOGIN, obj });
// export const receiveRefreshLogin = (data) => ({ type: RECEIVE_REFRESH_LOGIN, data });

//get list
export const REQUEST_GET_LIST = "REQUEST_GET_LIST";
export const RECEIVE_GET_LIST = "RECEIVE_GET_LIST";
export const requestGetList = (obj) => ({ type: REQUEST_GET_LIST, obj });
export const receiveGetList = (data) => ({ type: RECEIVE_GET_LIST, data });

//get location
export const REQUEST_GET_LOCATION = "REQUEST_GET_LOCATION";
export const RECEIVE_GET_LOCATION = "RECEIVE_GET_LOCATION";
export const requestGetLocation = (obj) => ({
  type: REQUEST_GET_LOCATION,
  obj,
});
export const receiveGetLocation = (data) => ({
  type: RECEIVE_GET_LOCATION,
  data,
});

//get regione list
export const REQUEST_GET_REGIONE_LIST = "REQUEST_GET_REGIONE_LIST";
export const RECEIVE_GET_REGIONE_LIST = "RECEIVE_GET_REGIONE_LIST";
export const requestGetRegioneList = (obj) => ({
  type: REQUEST_GET_REGIONE_LIST,
  obj,
});
export const receiveGetRegioneList = (data) => ({
  type: RECEIVE_GET_REGIONE_LIST,
  data,
});

//get education level list
export const REQUEST_GET_EDUCATION_LEVEL_LIST =
  "REQUEST_GET_EDUCATION_LEVEL_LIST";
export const RECEIVE_GET_EDUCATION_LEVEL_LIST =
  "RECEIVE_GET_EDUCATION_LEVEL_LIST";
export const requestGetEducationLevelList = (obj) => ({
  type: REQUEST_GET_EDUCATION_LEVEL_LIST,
  obj,
});
export const receiveGetEducationLevelList = (data) => ({
  type: RECEIVE_GET_EDUCATION_LEVEL_LIST,
  data,
});

//get sector level  list
export const REQUEST_GET_SECTOR_LIST = "REQUEST_GET_SECTOR_LIST";
export const RECEIVE_GET_SECTOR_LIST = "RECEIVE_GET_SECTOR_LIST";
export const requestGetSectorList = (obj) => ({
  type: REQUEST_GET_SECTOR_LIST,
  obj,
});
export const receiveGetSectorsList = (data) => ({
  type: RECEIVE_GET_SECTOR_LIST,
  data,
});

//get candidate details
export const REQUEST_GET_CANDIDATE = "REQUEST_GET_CANDIDATE";
export const RECEIVE_GET_CANDIDATE = "RECEIVE_GET_CANDIDATE";
export const requestGetCandidate = (obj) => ({
  type: REQUEST_GET_CANDIDATE,
  obj,
});
export const receiveGetCandidate = (data) => ({
  type: RECEIVE_GET_CANDIDATE,
  data,
});

//candidate Profile Update
export const REQUEST_CANDIDATE_PROFILE = "REQUEST_CANDIDATE_PROFILE";
export const RECEIVE_CANDIDATE_PROFILE = "RECEIVE_CANDIDATE_PROFILE";
export const requestCandidateProfile = (obj) => ({
  type: REQUEST_CANDIDATE_PROFILE,
  obj,
});
export const receiveCandidateProfile = (data) => ({
  type: RECEIVE_CANDIDATE_PROFILE,
  data,
});

//candidate resume
export const REQUEST_CANDIDATE_RESUME = "REQUEST_CANDIDATE_RESUME";
export const RECEIVE_CANDIDATE_RESUME = "RECEIVE_CANDIDATE_RESUME";
export const requestCandidateResume = (obj) => ({
  type: REQUEST_CANDIDATE_RESUME,
  obj,
});
export const receiveCandidateResume = (data) => ({
  type: RECEIVE_CANDIDATE_RESUME,
  data,
});

//get resume details
export const REQUEST_GET_RESUME = "REQUEST_GET_RESUME";
export const RECEIVE_GET_RESUME = "RECEIVE_GET_RESUME";
export const requestGetResumeDetails = (obj) => ({
  type: REQUEST_GET_RESUME,
  obj,
});
export const receiveGetResumeDetails = (data) => ({
  type: RECEIVE_GET_RESUME,
  data,
});

//update resume details
export const REQUEST_UPDATE_RESUME = "REQUEST_UPDATE_RESUME";
export const RECEIVE_UPDATE_RESUME = "RECEIVE_UPDATE_RESUME";
export const requestUpdateResumeDetails = (obj) => ({
  type: REQUEST_UPDATE_RESUME,
  obj,
});
export const receiveUpdateResumeDetails = (data) => ({
  type: RECEIVE_UPDATE_RESUME,
  data,
});

//delete resume details
export const REQUEST_DELETE_RESUME_FILE = "REQUEST_DELETE_RESUME_FILE";
export const RECEIVE_DELETE_RESUME_FILE = "RECEIVE_DELETE_RESUME_FILE";
export const requestDeleteResumeFile = (obj) => ({
  type: REQUEST_DELETE_RESUME_FILE,
  obj,
});
export const receiveDeleteResumeFile = (data) => ({
  type: RECEIVE_DELETE_RESUME_FILE,
  data,
});

//delete reccomdation letter
export const REQUEST_DELETE_LETTER_FILE = "REQUEST_DELETE_LETTER_FILE ";
export const RECEIVE_DELETE_LETTER_FILE = "RECEIVE_DELETE_LETTER_FILE ";
export const requestDeleteLetterFile = (obj) => ({
  type: REQUEST_DELETE_LETTER_FILE,
  obj,
});
export const receiveDeleteLetterFile = (data) => ({
  type: RECEIVE_DELETE_LETTER_FILE,
  data,
});

//get job details
export const REQUEST_GET_JOB = "REQUEST_GET_JOB";
export const RECEIVE_GET_JOB = "RECEIVE_GET_JOB";
export const requestGetJobDetails = (obj) => ({ type: REQUEST_GET_JOB, obj });
export const receiveGetJobDetails = (data) => ({ type: RECEIVE_GET_JOB, data });

//get job listing
export const REQUEST_GET_JOBLIST = "REQUEST_GET_JOBLIST";
export const RECEIVE_GET_JOBLIST = "RECEIVE_GET_JOBLIST";
export const requestGetJobList = (obj) => ({ type: REQUEST_GET_JOBLIST, obj });
export const receiveGetJobList = (data) => ({
  type: RECEIVE_GET_JOBLIST,
  data,
});

export const RESET_GET_JOBLIST = "RESET_GET_JOBLIST";
export const resetGetJobList = () => ({ type: RESET_GET_JOBLIST });

//get job listing User id
export const REQUEST_GET_JOBLIST_USER_ID = "REQUEST_GET_JOBLIST_USER_ID";
export const RECEIVE_GET_JOBLIST_USER_ID = "RECEIVE_GET_JOBLIST_USER_ID";
export const requestGetJobListUserId = (obj) => ({
  type: REQUEST_GET_JOBLIST_USER_ID,
  obj,
});
export const receiveGetJobListUserId = (data) => ({
  type: RECEIVE_GET_JOBLIST_USER_ID,
  data,
});

//apply now
export const REQUEST_COMPANY_JOB_APPLY_NOW = "REQUEST_COMPANY_JOB_APPLY_NOW";
export const RECEIVE_COMPANY_JOB_APPLY_NOW = "RECEIVE_COMPANY_JOB_APPLY_NOW";
export const requestJobApplyNow = (obj) => ({
  type: REQUEST_COMPANY_JOB_APPLY_NOW,
  obj,
});
export const receiveJobApplyNow = (data) => ({
  type: RECEIVE_COMPANY_JOB_APPLY_NOW,
  data,
});

//Answer Add
export const REQUEST_CANDIDATE_ADD_ANSWER = "REQUEST_CANDIDATE_ADD_ANSWER";
export const RECEIVE_CANDIDATE_ADD_ANSWER = "RECEIVE_CANDIDATE_ADD_ANSWER";
export const requestAddAnswerForJob = (obj) => ({
  type: REQUEST_CANDIDATE_ADD_ANSWER,
  obj,
});
export const receiveAddAnswerForJob = (data) => ({
  type: RECEIVE_CANDIDATE_ADD_ANSWER,
  data,
});

//Answer get
export const REQUEST_CANDIDATE_GET_ANSWER = "REQUEST_CANDIDATE_GET_ANSWER";
export const RECEIVE_CANDIDATE_GET_ANSWER = "RECEIVE_CANDIDATE_GET_ANSWER";
export const requestGetAnswerForJob = (obj) => ({
  type: REQUEST_CANDIDATE_GET_ANSWER,
  obj,
});
export const receiveGetAnswerForJob = (data) => ({
  type: RECEIVE_CANDIDATE_GET_ANSWER,
  data,
});

//==================================== Company ===================================

//register
export const REQUEST_COMPANY_REGISTER = "REQUEST_COMPANY_REGISTER";
export const RECEIVE_COMPANY_REGISTER = "RECEIVE_COMPANY_REGISTER";
export const requestCompanyRegister = (obj) => ({
  type: REQUEST_COMPANY_REGISTER,
  obj,
});
export const receiveCompanyRegister = (data) => ({
  type: RECEIVE_COMPANY_REGISTER,
  data,
});

//get company register
export const REQUEST_COMPANY_DETAILS = "REQUEST_COMPANY_DETAILS ";
export const RECEIVE_COMPANY_DETAILS = "RECEIVE_COMPANY_DETAILS ";
export const requestCompanyDetails = (obj) => ({
  type: REQUEST_COMPANY_DETAILS,
  obj,
});
export const receiveCompanyDetails = (data) => ({
  type: RECEIVE_COMPANY_DETAILS,
  data,
});

//update company register
export const REQUEST_COMPANY_UPDATE = "REQUEST_COMPANY_UPDATE";
export const RECEIVE_COMPANY_UPDATE = "RECEIVE_COMPANY_UPDATE";
export const requestCompanyUpdate = (obj) => ({
  type: REQUEST_COMPANY_UPDATE,
  obj,
});
export const receiveCompanyUpdate = (data) => ({
  type: RECEIVE_COMPANY_UPDATE,
  data,
});

// company jwt token
export const REQUEST_COMPANY_JWT = "REQUEST_COMPANY_JWT";
export const RECEIVE_COMPANY_JWT = "RECEIVE_COMPANY_JWT";
export const requestCompanyJWT = (obj) => ({ type: REQUEST_COMPANY_JWT, obj });
export const receiveCompanyJWT = (data) => ({
  type: RECEIVE_COMPANY_JWT,
  data,
});

//company reference
export const REQUEST_COMPANY_REFERENCE = "REQUEST_COMPANY_REFERENCE";
export const RECEIVE_COMPANY_REFERENCE = "RECEIVE_COMPANY_REFERENCE";
export const requestCompanyReference = (obj) => ({
  type: REQUEST_COMPANY_REFERENCE,
  obj,
});
export const receiveCompanyReference = (data) => ({
  type: RECEIVE_COMPANY_REFERENCE,
  data,
});

//package token
export const REQUEST_COMPANY_PACKAGE = "REQUEST_COMPANY_PACKAGE";
export const RECEIVE_COMPANY_PACKAGE = "RECEIVE_COMPANY_PACKAGE";
export const requestCompanyPackage = (obj) => ({
  type: REQUEST_COMPANY_PACKAGE,
  obj,
});
export const receiveCompanyPackage = (data) => ({
  type: RECEIVE_COMPANY_PACKAGE,
  data,
});

//Credite Tokens
export const REQUEST_COMPANY_GET_CREDITE_TOKEN =
  "REQUEST_COMPANY_GET_CREDITE_TOKEN";
export const RECEIVE_COMPANY_GET_CREDITE_TOKEN =
  "RECEIVE_COMPANY_GET_CREDITE_TOKEN";
export const requestCompanyGetCrediteToken = (obj) => ({
  type: REQUEST_COMPANY_GET_CREDITE_TOKEN,
  obj,
});
export const receiveCompanyGetCrediteToken = (data) => ({
  type: RECEIVE_COMPANY_GET_CREDITE_TOKEN,
  data,
});

// all Info of Tokens
export const REQUEST_COMPANY_CREDITE = "REQUEST_COMPANY_CREDITE";
export const RECEIVE_COMPANY_CREDITE = "RECEIVE_COMPANY_CREDITE";
export const requestCompanyCredite = (obj) => ({
  type: REQUEST_COMPANY_CREDITE,
  obj,
});
export const receiveCompanyCredite = (data) => ({
  type: RECEIVE_COMPANY_CREDITE,
  data,
});

//get Bought token
export const REQUEST_COMPANY_BOUGHT_TOKEN = "REQUEST_COMPANY_BOUGHT_TOKEN";
export const RECEIVE_COMPANY_BOUGHT_TOKEN = "RECEIVE_COMPANY_BOUGHT_TOKEN";
export const requestCompanyBoughtToken = (obj) => ({
  type: REQUEST_COMPANY_BOUGHT_TOKEN,
  obj,
});
export const receiveCompanyBoughtToken = (data) => ({
  type: RECEIVE_COMPANY_BOUGHT_TOKEN,
  data,
});

//get Spent token
export const REQUEST_COMPANY_SPENT_TOKEN = "REQUEST_COMPANY_SPENT_TOKEN";
export const RECEIVE_COMPANY_SPENT_TOKEN = "RECEIVE_COMPANY_SPENT_TOKEN";
export const requestCompanySpentToken = (obj) => ({
  type: REQUEST_COMPANY_SPENT_TOKEN,
  obj,
});
export const receiveCompanySpentToken = (data) => ({
  type: RECEIVE_COMPANY_SPENT_TOKEN,
  data,
});

//get corresponding token
export const REQUEST_COMPANY_CORRESPONDING_TOKEN =
  "REQUEST_COMPANY_CORRESPONDING_TOKEN";
export const RECEIVE_COMPANY_CORRESPONDING_TOKEN =
  "RECEIVE_COMPANY_CORRESPONDING_TOKEN";
export const requestCompanyCorrespondingToken = (obj) => ({
  type: REQUEST_COMPANY_CORRESPONDING_TOKEN,
  obj,
});
export const receiveCompanyCorrespondingToken = (data) => ({
  type: RECEIVE_COMPANY_CORRESPONDING_TOKEN,
  data,
});

//CV Finder
export const REQUEST_COMPANY_GET_CV_LIST = "REQUEST_COMPANY_GET_CV_LIST";
export const RECEIVE_COMPANY_GET_CV_LIST = "RECEIVE_COMPANY_GET_CV_LIST";
export const requestGetCVList = (obj) => ({
  type: REQUEST_COMPANY_GET_CV_LIST,
  obj,
});
export const receiveGetCVList = (data) => ({
  type: RECEIVE_COMPANY_GET_CV_LIST,
  data,
});

export const REQUEST_SEARCH_FRANCHISOR = "REQUEST_SEARCH_FRANCHISOR";
export const RECEIVE_SEARCH_FRANCHISOR = "RECEIVE_SEARCH_FRANCHISOR";
export const requestSearchFranchisor = (obj) => ({
  type: REQUEST_SEARCH_FRANCHISOR,
  obj,
});
export const receiveSearchFranchisor = (data) => ({
  type: RECEIVE_SEARCH_FRANCHISOR,
  data,
});

//CV visible  spent token
export const REQUEST_COMPANY_TWELVE_TOKEN_SPENT =
  "REQUEST_COMPANY_TWELVE_TOKEN_SPENT";
export const RECEIVE_COMPANY_TWELVE_TOKEN_SPENT =
  "RECEIVE_COMPANY_TWELVE_TOKEN_SPENT";

export const requestTwelveTokenSpent = (obj) => ({
  type: REQUEST_COMPANY_TWELVE_TOKEN_SPENT,
  obj,
});
export const receiveTwelveTokenSpent = (data) => ({
  type: RECEIVE_COMPANY_TWELVE_TOKEN_SPENT,
  data,
});

//create advertisement
export const REQUEST_COMPANY_CREATE_ADVERTISEMENT =
  "REQUEST_COMPANY_CREATE_ADVERTISEMENT";
export const RECEIVE_COMPANY_CREATE_ADVERTISEMENT =
  "RECEIVE_COMPANY_CREATE_ADVERTISEMENT";
export const requestCreateAdvertisement = (obj) => ({
  type: REQUEST_COMPANY_CREATE_ADVERTISEMENT,
  obj,
});
export const receiveCreateAdvertisement = (data) => ({
  type: RECEIVE_COMPANY_CREATE_ADVERTISEMENT,
  data,
});

//get recTime
export const REQUEST_COMPANY_REC_TIME = "REQUEST_COMPANY_REC_TIME";
export const RECEIVE_COMPANY_REC_TIME = "RECEIVE_COMPANY_REC_TIME";
export const requestGetrecTime = (obj) => ({
  type: REQUEST_COMPANY_REC_TIME,
  obj,
});
export const receiveGetrecTime = (data) => ({
  type: RECEIVE_COMPANY_REC_TIME,
  data,
});

//get ContractTypes
export const REQUEST_COMPANY_CONTRACT_TYPES = "REQUEST_COMPANY_CONTRACT_TYPES";
export const RECEIVE_COMPANY_CONTRACT_TYPES = "RECEIVE_COMPANY_CONTRACT_TYPES";
export const requestGetContractTypes = (obj) => ({
  type: REQUEST_COMPANY_CONTRACT_TYPES,
  obj,
});
export const receiveGetContractTypes = (data) => ({
  type: RECEIVE_COMPANY_CONTRACT_TYPES,
  data,
});

//get Availabilities
export const REQUEST_COMPANY_AVAILABILITES = "REQUEST_COMPANY_AVAILABILITES";
export const RECEIVE_COMPANY_AVAILABILITES = "RECEIVE_COMPANY_AVAILABILITES";
export const requestGetAvailabilities = (obj) => ({
  type: REQUEST_COMPANY_AVAILABILITES,
  obj,
});
export const receiveGetAvailabilities = (data) => ({
  type: RECEIVE_COMPANY_AVAILABILITES,
  data,
});

//get AdditionalWages
export const REQUEST_COMPANY_ADDITIONAL_WAGES =
  "REQUEST_COMPANY_ADDITIONAL_WAGES";
export const RECEIVE_COMPANY_ADDITIONAL_WAGES =
  "RECEIVE_COMPANY_ADDITIONAL_WAGES";
export const requestGetAdditionalWages = (obj) => ({
  type: REQUEST_COMPANY_ADDITIONAL_WAGES,
  obj,
});
export const receiveGetAdditionalWages = (data) => ({
  type: RECEIVE_COMPANY_ADDITIONAL_WAGES,
  data,
});

//get Benefits
export const REQUEST_COMPANY_BENEFITS = "REQUEST_COMPANY_BENEFITS";
export const RECEIVE_COMPANY_BENEFITS = "RECEIVE_COMPANY_BENEFITS";
export const requestGetBenefits = (obj) => ({
  type: REQUEST_COMPANY_BENEFITS,
  obj,
});
export const receiveGetBenefits = (data) => ({
  type: RECEIVE_COMPANY_BENEFITS,
  data,
});

//PATCH FOR ADDVERTISEMENT

export const REQUEST_COMPANY_PATCH_ADVERTISEMENT =
  "REQUEST_COMPANY_PATCH_ADVERTISEMENT";
export const RECEIVE_COMPANY_PATCH_ADVERTISEMENT =
  "RECEIVE_COMPANY_PATCH_ADVERTISEMENT";
export const requestPatchAdvertisement = (obj) => ({
  type: REQUEST_COMPANY_PATCH_ADVERTISEMENT,
  obj,
});
export const receivePatchAdvertisement = (data) => ({
  type: RECEIVE_COMPANY_PATCH_ADVERTISEMENT,
  data,
});

//get Representatives
export const REQUEST_COMPANY_REPRESENTATIVES =
  "REQUEST_COMPANY_REPRESENTATIVES";
export const RECEIVE_COMPANY_REPRESENTATIVES =
  "RECEIVE_COMPANY_REPRESENTATIVES";
export const requestGetRepresentatives = (obj) => ({
  type: REQUEST_COMPANY_REPRESENTATIVES,
  obj,
});
export const receiveGetRepresentatives = (data) => ({
  type: RECEIVE_COMPANY_REPRESENTATIVES,
  data,
});

// payment gatway PAYPAL

//purchage Token of Payment

export const REQUEST_PURCHAGE_TOKEN_PAYMENT = "REQUEST_PURCHAGE_TOKEN_PAYMENT";
export const RECEIVE_PURCHAGE_TOKEN_PAYMENT = "RECEIVE_PURCHAGE_TOKEN_PAYMENT";
export const requestPurchageTokenPayment = (obj) => ({
  type: REQUEST_PURCHAGE_TOKEN_PAYMENT,
  obj,
});
export const receivePurchageTokenPayment = (data) => ({
  type: RECEIVE_PURCHAGE_TOKEN_PAYMENT,
  data,
});

//PAYPAL
export const REQUEST_PAYPAL_PAYMENT = "REQUEST_PAYPAL_PAYMENT";
export const RECEIVE_PAYPAL_PAYMENT = "RECEIVE_PAYPAL_PAYMENT";
export const requestPaypalPayment = (obj) => ({
  type: REQUEST_PAYPAL_PAYMENT,
  obj,
});
export const receivePaypalPayment = (data) => ({
  type: RECEIVE_PAYPAL_PAYMENT,
  data,
});

//STRIPE
export const REQUEST_STRIPE_PAYMENT = "REQUEST_STRIPE_PAYMENT";
export const RECEIVE_STRIPE_PAYMENT = "RECEIVE_STRIPE_PAYMENT";
export const requestStripePayment = (obj) => ({
  type: REQUEST_STRIPE_PAYMENT,
  obj,
});
export const receiveStripePayment = (data) => ({
  type: RECEIVE_STRIPE_PAYMENT,
  data,
});

export const REQUEST_GET_RETRIEVE_SESSION_PAYMENT =
  "REQUEST_GET_RETRIEVE_SESSION_PAYMENT";
export const RECEIVE_GET_RETRIEVE_SESSION_PAYMENT =
  "RECEIVE_GET_RETRIEVE_SESSION_PAYMENT";
export const requestRetrieveSession = (obj) => ({
  type: REQUEST_GET_RETRIEVE_SESSION_PAYMENT,
  obj,
});
export const receiveRetrieveSession = (data) => ({
  type: RECEIVE_GET_RETRIEVE_SESSION_PAYMENT,
  data,
});

//SATISPAY
export const REQUEST_SATISPAY_PAYMENT = "REQUEST_SATISPAY_PAYMENT";
export const RECEIVE_SATISPAY_PAYMENT = "RECEIVE_SATISPAY_PAYMENT";
export const requestSatispayPayment = (obj) => ({
  type: REQUEST_SATISPAY_PAYMENT,
  obj,
});
export const receiveSatispayPayment = (data) => ({
  type: RECEIVE_SATISPAY_PAYMENT,
  data,
});

//logout
export const USER_LOGOUT = "USER_LOGOUT";
export const userLogout = () => {
  console.log(
    "%c-- LOGGING OUT --",
    "color: white; font-weight: bold; background-color: red; padding: 2px 4px; border-radius: 4px;"
  );

  return { type: USER_LOGOUT };
};

// ==================================== Agent ===================================

//opportunity finder
export const REQUEST_AGENT_OPPORTUNITY_FINDER =
  "REQUEST_AGENT_OPPORTUNITY_FINDER";
export const RECEIVE_AGENT_OPPORTUNITY_FINDER =
  "RECEIVE_AGENT_OPPORTUNITY_FINDER";
export const requestAgentOpportunityFinder = (obj) => ({
  type: REQUEST_AGENT_OPPORTUNITY_FINDER,
  obj,
});
export const receiveAgentOpportunityFinder = (data) => ({
  type: RECEIVE_AGENT_OPPORTUNITY_FINDER,
  data,
});

export const REQUEST_GET_PRODUCTS = "REQUEST_GET_PRODUCTS";
export const RECEIVE_GET_PRODUCTS = "RECEIVE_GET_PRODUCTS";
export const requestGetProducts = (obj) => ({
  type: REQUEST_GET_PRODUCTS,
  obj,
});
export const receiveGetProducts = (data) => ({
  type: RECEIVE_GET_PRODUCTS,
  data,
});

export const REQUEST_GET_FILTER_OPTIONS = "REQUEST_GET_FILTER_OPTIONS";
export const RECEIVE_GET_FILTER_OPTIONS = "RECEIVE_GET_FILTER_OPTIONS";
export const requestGetFilterOptions = (obj) => ({
  type: REQUEST_GET_FILTER_OPTIONS,
  obj,
});
export const receiveGetFilterOptions = (data) => ({
  type: RECEIVE_GET_FILTER_OPTIONS,
  data,
});

export const REQUEST_GET_FRANCHISOR_RESULTS = "REQUEST_GET_FRANCHISOR_RESULTS";
export const RECEIVE_GET_FRANCHISOR_RESULTS = "RECEIVE_GET_FRANCHISOR_RESULTS";
export const requestGetFranchisorResults = (obj) => ({
  type: REQUEST_GET_FRANCHISOR_RESULTS,
  obj,
});
export const receiveGetFranchisorResults = (data) => ({
  type: RECEIVE_GET_FRANCHISOR_RESULTS,
  data,
});

export const REQUEST_PUBLISH_FRANCHISING_AD = "REQUEST_PUBLISH_FRANCHISING_AD";
export const RECEIVE_PUBLISH_FRANCHISING_AD = "RECEIVE_PUBLISH_FRANCHISING_AD";
export const requestPublishFranchisingAd = (obj) => ({
  type: REQUEST_PUBLISH_FRANCHISING_AD,
  obj,
});
export const receivePublishFranchisingAd = (data) => ({
  type: RECEIVE_PUBLISH_FRANCHISING_AD,
  data,
});

export const REQUEST_REMOVE_PUBLISH_FRANCHISING_AD =
  "REQUEST_REMOVE_PUBLISH_FRANCHISING_AD";
export const RECEIVE_REMOVE_PUBLISH_FRANCHISING_AD =
  "RECEIVE_REMOVE_PUBLISH_FRANCHISING_AD";
export const requestRemovePublishFranchisingAd = (obj) => ({
  type: REQUEST_REMOVE_PUBLISH_FRANCHISING_AD,
  obj,
});
export const REQUEST_SAVE_FILTER_OPTIONS = "REQUEST_SAVE_FILTER_OPTIONS";
export const RECEIVE_SAVE_FILTER_OPTIONS = "RECEIVE_SAVE_FILTER_OPTIONS";
export const requestSaveFilterOptions = (obj) => ({
  type: REQUEST_SAVE_FILTER_OPTIONS,
  obj,
});
export const receiveSaveFilterOptions = (data) => ({
  type: RECEIVE_SAVE_FILTER_OPTIONS,
  data,
});

export const REQUEST_GET_CV_DETAILS = "REQUEST_GET_CV_DETAILS";
export const RECEIVE_GET_CV_DETAILS = "RECEIVE_GET_CV_DETAILS";

export const requestGetCVDetails = (obj) => ({
  type: REQUEST_GET_CV_DETAILS,
  obj,
});
export const receiveGetCVDetails = (data) => ({
  type: RECEIVE_GET_CV_DETAILS,
  data,
});
