// File: /src/Franchisor/SendFranchisorRequest.js
// Created Date: Friday February 7th 2025
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Friday February 7th 2025 1:58:32 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React from "react";
import FormBuilder from "../Components/FormBuilder";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const FIELDS = [
  {
    label: "Telefono",
    name: "phone",
    type: "phone",
    mandatory: true,
  },
  {
    label: "Indirizzo",
    name: "address",
    type: "text",
    mandatory: true,
  },
  {
    label: "Città",
    name: "city",
    type: "text",
    mandatory: true,
  },
  {
    label: "Provincia",
    name: "province",
    type: "text",
    mandatory: true,
  },
  {
    label: "Cap",
    name: "cap",
    type: "text",
    mandatory: true,
  },
  {
    label: "Regione",
    name: "region",
    type: "text",
    mandatory: true,
  },
  {
    label: "È già in possesso di un negozio fisico",
    name: "hasPhysicalStore",
    type: "select",
    mandatory: true,
  },
  {
    label: "Ha esperienza nel settore scelto",
    name: "hasExperience",
    type: "select",
    mandatory: true,
  },
  {
    label: "In quale fascia oraria essere contattato",
    name: "contactTime",
    type: "text",
    mandatory: true,
  },
  {
    label: "Note",
    name: "note",
    type: "text_area",
  },
  {
    label: "Quando vorresti iniziare l'attività",
    name: "start_date",
    type: "select",
    mandatory: true,
  },
  {
    label: "Che ammontare sei disposto ad investire",
    name: "investmentAmount",
    type: "number",
    mandatory: true,
  },
];

const OPTIONS = {
  hasPhysicalStore: [
    { label: "Sì", value: true },
    { label: "No", value: false },
  ],
  hasExperience: [
    { label: "Sì", value: true },
    { label: "No", value: false },
  ],
  start_date: [
    { label: "0-3 mesi", value: "0-3" },
    { label: "3-6 mesi", value: "3-6" },
    { label: "6-12 mesi", value: "6-12" },
    { label: "Oltre", value: "Oltre" },
  ],
};

const SendFranchisorRequest = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const patchUserPreferences = async (args) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_HOST}auth/register/`,
        {
          tel_consent: args.tel_consent,
          e_mail_consent: args.e_mail_consent,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            username: props.candidate?.loginData?.data?.Message?.username,
          },
        }
      );
      return true;
    } catch (err) {
      toast.error("Errore nel consentire l'invio delle preferenze");
      console.log(err);
      Swal.hideLoading();
      return false;
    }
  };

  const handleSubmit = async (data, skipPrefernces = false) => {
    const userData = props.candidate?.loginData?.data?.Message;
    if (
      !skipPrefernces &&
      !userData?.tel_consent &&
      !userData?.e_mail_consent
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Hai scelto di non condividere alcuna informazione di contatto. Accedi al profilo e consenti di condividere almeno l'email o il telefono.",
        showConfirmButton: false,
        footer: /*html*/ `
          <div class="d-flex flex-column gap-2">
            <div>
              <input type="checkbox" id="checkbox-preference-email" />
              <label for="checkbox-preference-email">Salva le mie preferenze condividendo l'email.</label>
            </div>
            <div>
              <input type="checkbox" id="checkbox-preference-phone" />
              <label for="checkbox-preference-phone">Salva le mie preferenze condividendo il telefono.</label>
            </div>
            <div id='rgjiwufg2bi3ufbiuebgiw' class='d-flex gap-2 justify-content-center mt-2'></div>
          </div>
        `,
      });
      const button = document.createElement("button");
      button.className = "btn btn-secondary mt-2";
      button.textContent = "Annulla";
      button.addEventListener("click", () => {
        Swal.close();
      });
      const button2 = document.createElement("button");
      button2.className = "btn btn-primary mt-2";
      button2.textContent = "Salva ed Invia";
      button2.addEventListener("click", async () => {
        Swal.showLoading();
        if (
          !document.getElementById("checkbox-preference-phone").checked &&
          !document.getElementById("checkbox-preference-email").checked
        ) {
          toast.error(
            "Devi consentire di essere contattato via telefono o email"
          );
          Swal.hideLoading();
          return;
        }
        const success = await patchUserPreferences({
          tel_consent: document.getElementById("checkbox-preference-phone")
            .checked,
          e_mail_consent: document.getElementById("checkbox-preference-email")
            .checked,
        });
        if (!success) return;
        await handleSubmit(data, true);
        Swal.hideLoading();
        Swal.close();
      });
      document.getElementById("rgjiwufg2bi3ufbiuebgiw").appendChild(button);
      document.getElementById("rgjiwufg2bi3ufbiuebgiw").appendChild(button2);
      return;
    }

    try {
      const formData = {
        ...data,
        franchisor: +searchParams.get("franchisorId"),
        ad: +searchParams.get("jobId"),
        start_date: {
          time_slot: data.start_date?.[0],
        },
        hasPhysicalStore: data.hasPhysicalStore?.[0],
        hasExperience: data.hasExperience?.[0],
        investmentAmount: Number(data.investmentAmount || 0).toFixed(2),
        address: {
          address: data.address,
          city: data.city,
          province: data.province,
          cap: data.cap,
          region: data.region,
        },
        franchisee_answers: {
          ...JSON.parse(localStorage.getItem("franchisee_answers")),
        },
      };
      delete formData.province;
      delete formData.region;
      delete formData.cap;
      delete formData.city;

      await axios.post(
        `${process.env.REACT_APP_API_HOST}wideer/f/r`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Richiesta inviata con successo",
        text: "La tua richiesta è stata inviata con successo",
        timer: 2000,
        showConfirmButton: false,
      });
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Qualcosa è andato storto!",
      });
    }
  };

  return (
    <div>
      <Header />
      <div
        style={{
          minHeight: "100vh",
          marginBlock: "2rem",
          width: "min(90%, 40rem)",
          display: "flex",
          flexDirection: "column",
          marginInline: "auto",
        }}
      >
        <FormBuilder
          fields={FIELDS}
          submitLabel="Invia richiesta"
          selectOptions={OPTIONS}
          onSubmit={handleSubmit}
        />
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendFranchisorRequest);
