import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Logo from "../images/logo.png";
import {
  requestLinkedinLogin,
  requestLogin,
  userLogout,
} from "../Redux/actions";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import Login from "./login/Login";
import SocialRegister from "./login/SocialRegister";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { toast } from "react-toastify";
import PasswordReset from "./forggot-password/Password-Reset";
import PasswordResetConfirm from "./forggot-password/Password-Reset-Confirm";
import DropdownNavigation from "./DropdownNavigation";
import LanguageSelector from "./LanguageSelector";
import axios from "axios";
import Swal from "sweetalert2";

function Header(props) {
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(props?.showModal || "");
  const [isOpen, setIsOpen] = useState(false);
  // const [showModal, setShowModal] = useState('');
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUser(decodedToken);
      } catch (error) {
        console.error("Invalid token", error);
        localStorage.removeItem("token");
      }
      const expiration = localStorage.getItem("expiration");
      if (expiration) {
        const expirationDate = new Date(expiration);
        if (expirationDate < new Date()) {
          //localStorage.removeItem('token')
          //localStorage.removeItem('refresh_token')
          /*localStorage.removeItem('expiration')
          axios.post(`${process.env.REACT_APP_API_HOST}auth/login/refresh/`,
            {
              refresh: localStorage.getItem('refresh_token'),
            }, {
            headers: {
              'Content-Type': 'application/json',
            },
            
          })
            .then(response => {
              console.log('refresh token', response)
              localStorage.setItem('token', response.data.access)
              localStorage.setItem('refresh_token', response.data.refresh)
              const date = new Date();
              date.setMinutes(date.getMinutes() + 1);
              localStorage.setItem('expiration', date.toISOString());
            })
            .catch(error => {
              console.error('Error refreshing token', error)
            })*/
          props.userLogout();
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("userType");
          localStorage.removeItem("__paypal_storage__");
          localStorage.removeItem("representative");
          localStorage.removeItem("representative_id");
          localStorage.removeItem("registerFranchisorData");
          localStorage.removeItem("CreaAnnuncio");
          localStorage.removeItem("can_see_resumes");
          localStorage.removeItem("cazzodidomandefranchisee");
          localStorage.removeItem("linkedin_oauth2_state");
          localStorage.removeItem("expiration");
          localStorage.removeItem("canSeeResumes");
          localStorage.removeItem("social_uid");

          setUser(null);
          navigate("/");
        }
      }
    }
  }, []);

  //======================= session exipire ================================================

  // useEffect(() => {
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //         try {
  //             const decodedToken = jwtDecode(token);
  //             const currentTime = Date.now() / 1000;
  //             if (decodedToken.exp > currentTime) {
  //                 setUser(decodedToken);
  //             } else {
  //                 console.warn("Token has expired");
  //             }
  //         } catch (error) {
  //             console.error("Invalid token", error);
  //             toast.error('Invalid token. Please log in again.', { theme: "colored", autoClose: 8000 });
  //         }
  //     }
  // }, []);

  //======================= session exipire ================================================

  useEffect(() => {
    if (location.pathname !== "/resume") {
      localStorage.removeItem("education");
      localStorage.removeItem("educationalLevel");
      localStorage.removeItem("experience");
      localStorage.removeItem("experienceSector");
      localStorage.removeItem("languages");
      localStorage.removeItem("skills");
      localStorage.removeItem("currentStep");
    }
  }, [location]);

  useEffect(() => {
    setShowModal(props.showModal);
  }, [props.showModal]);

  useEffect(() => {
    if (typeof props.setShowModal === "function") {
      props.setShowModal(showModal);
    }
    if (!showModal) {
      localStorage.removeItem("logging-for");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const logOut = () => {
    props.userLogout();
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("userType");
    localStorage.removeItem("__paypal_storage__");
    localStorage.removeItem("representative");
    localStorage.removeItem("representative_id");
    localStorage.removeItem("registerFranchisorData");
    localStorage.removeItem("CreaAnnuncio");
    localStorage.removeItem("can_see_resumes");
    localStorage.removeItem("cazzodidomandefranchisee");
    localStorage.removeItem("linkedin_oauth2_state");
    localStorage.removeItem("expiration");
    localStorage.removeItem("social_uid");
    setUser(null);
    navigate("/");
  };

  const handleLoginClick = () => {
    if (props.data === undefined) {
      setShowModal("login");
    }
  };

  const handleRegisterClick = () => {
    if (props.data === undefined) {
      if (
        location.pathname === "/job-finder" ||
        location.pathname === "/jobs-list"
      ) {
        navigate("/candidate/register");
      } else if (
        location.pathname === "/agent/opportunity-finder" ||
        location.pathname === "/agent/opportunities-list"
      ) {
        navigate("/agent/register");
      } else if (
        location.pathname === "/agent/search" ||
        location.pathname === "/company/candidate-finder" ||
        location.pathname === "/company/candidate-jobs-list"
      ) {
        navigate("/company/register/");
      } else {
        navigate("/register");
      }
    } else {
      if (location.pathname === "/register") {
        toast.error("Compila la registrazione.", {
          theme: "colored",
          autoClose: 8000,
        });
      }
    }
  };

  const handleOnLogo = () => {
    if (props.data === undefined) {
      navigate("/", { state: { jobListData: [] } });
    } else {
      if (location.pathname === "/register") {
        toast.error("Compila la registrazione.", {
          theme: "colored",
          autoClose: 8000,
        });
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal("");
  };

  const isLoadingLinkedin = useRef(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const error = urlParams.get("error");

    if (code) {
      if (isLoadingLinkedin.current) return;
      isLoadingLinkedin.current = true;
      props.requestLinkedinLogin({
        linkedin_code: code,
        redirect_uri: `${window.location.origin}/home?path=${window.location.pathname}`,
      });
    } else if (error) {
      console.error("LinkedIn auth error:", error);
      toast.error("Autenticazione LinkedIn fallita", {
        theme: "colored",
        autoClose: 8000,
      });
      navigate("/");
    }
  }, [props, navigate, location]);

  const [urlParams] = useSearchParams();
  const hasShownError = useRef(false);

  useEffect(() => {
    if (props.candidate.loginData?.data?.Message?.social_uid) {
      localStorage.setItem(
        "social_uid",
        props.candidate.loginData?.data?.Message?.social_uid
      );
    }
    if (
      props.candidate.loginData?.data?.status === "Failed" &&
      !hasShownError.current &&
      urlParams.get("code")
    ) {
      toast.error(props.candidate.loginData?.data?.Message, {
        theme: "colored",
        autoClose: 8000,
      });
      hasShownError.current = true;
    } else {
      const path = urlParams.get("path");
      console.log(
        urlParams.get("path") === "/home",
        props.candidate.loginData?.data?.Message?.social_uid,
        !props.candidate.loginData?.data?.Message?.access
      );
      if (urlParams.get("path") === "/home" && urlParams.get("area")) {
        switch (urlParams.get("area")) {
          case "company":
          case "professional":
          case "artisan":
            navigate("/company/register");
            break;
          case "agent":
            navigate("/agent/register");
            break;
          case "franchisor":
            navigate("/franchisor/register");
            break;
          case "employee":
            navigate("/candidate/register");
            break;
          default:
            navigate("/register");
            break;
        }
      } else if (path) {
        isLoadingLinkedin.current = false;
        navigate(path.replace("/home", ""));
      }
    }
  }, [props.candidate.loginData, navigate, urlParams]);

  return (
    <>
      <div className="header_main">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div className="navbar-brand" onClick={handleOnLogo}>
              <img src={Logo} alt="Main Logo" />
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              onClick={() => setIsOpen(!isOpen)}
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* {user && <DropdownNavigation />} */}
            <div
              className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {/*<li className='nav-item'>
                  <a className='nav-link'> {t('header.message')}</a>
                </li>*/}
                {/* <li className="nav-item">
                  <a className="nav-link">{t("header.notification")}</a>
                </li> */}
                {user ? (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link" to={"/"} onClick={logOut}>
                        Log Out
                      </Link>
                    </li>
                    <DropdownNavigation />
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <div className="nav-link" onClick={handleLoginClick}>
                        {t("header.login")}
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="nav-link" onClick={handleRegisterClick}>
                        Registrati
                      </div>
                    </li>
                  </>
                )}
                {/*
                <li className='nav-item'>
                  <LanguageSelector />
                </li>
                */}
              </ul>
            </div>
          </div>
        </nav>
      </div>

      {showModal === "login" && (
        <div
          className="modal fade show custom_modal"
          tabIndex="-1"
          aria-labelledby="loginModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <Login
                  setShowModal={setShowModal}
                  handleCloseModal={handleCloseModal}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal === "register" && (
        <div
          className="modal fade show custom_modal"
          tabIndex="-1"
          aria-labelledby="loginModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <SocialRegister setShowModal={setShowModal} />
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal === "password-reset" && (
        <div
          className="modal fade show custom_modal"
          tabIndex="-1"
          aria-labelledby="loginModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <PasswordReset
                  setShowModal={setShowModal}
                  showModal={showModal}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {showModal === 'password-reset' &&
                <div className="custom_modal">
                    <PasswordResetConfirm />
                </div>
            } */}

      {showModal === "password-confirm" && (
        <div className="custom_modal">
          <PasswordResetConfirm />
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return { candidate: state?.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { requestLogin, userLogout, requestLinkedinLogin },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
