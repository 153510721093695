// File: /src/lib/linkedin.js
// Created Date: Wednesday January 29th 2025
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Wednesday January 29th 2025 9:43:28 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

export async function initiateLinkedInLogin() {
  const linkedInAuthURL = "https://www.linkedin.com/oauth/v2/authorization";
  const params = {
    response_type: "code",
    client_id: "77pfd64ztjectx",
    redirect_uri: window.location.origin + "/home/resume",
    scope: "r_dma_portability_3rd_party",
    prompt: "consent",
  };

  const authURL = `${linkedInAuthURL}?${new URLSearchParams(params)}`;
  window.location.href = authURL;
}

export async function exchangeCodeForAccessToken(code) {
  const linkedInTokenURL =
    process.env.REACT_APP_API_HOST + "auth/auth/convert-token";
  const params = {
    backend: "linkedin-oauth2",
    client_id: "77pfd64ztjectx",
    token:
      "AQSIXaAEf1OyiWpaWAuDBZoU8TfC0_z0OLp4IMekmdWYrRe76gBeaoqi8SwAcr3tqNva48Ze-y3blWWZt1kNsV8AIBt10ioI5I7v9gTP1E9O440QpIDXff_rjxqbsQn8vHyra_8rHW_nLgEQGmBV3wHM2jQtTNyKQME9-VR_Xt2A4iyViwk8cDJ2Ebm0FjQRdGfgvoRYIN6tAgq50Rc",
    grant_type: "authorization_code",
  };
  const response = await fetch(linkedInTokenURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(params),
  });
  const data = await response.json();
  return data.access_token;
}

export async function getLinkedInUserData(accessToken) {
  const linkedInApiURL =
    "https://api.linkedin.com/rest/memberSnapshotData?q=criteria";
  const response = await fetch(linkedInApiURL, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Use the passed accessToken parameter
      "X-Restli-Protocol-Version": "2.0.0",
    },
  });
  const data = await response.json();
  return data;
}

//AQSIXaAEf1OyiWpaWAuDBZoU8TfC0_z0OLp4IMekmdWYrRe76gBeaoqi8SwAcr3tqNva48Ze-y3blWWZt1kNsV8AIBt10ioI5I7v9gTP1E9O440QpIDXff_rjxqbsQn8vHyra_8rHW_nLgEQGmBV3wHM2jQtTNyKQME9-VR_Xt2A4iyViwk8cDJ2Ebm0FjQRdGfgvoRYIN6tAgq50Rc
