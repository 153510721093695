import React, { useEffect, useState } from 'react'
import Select, { createFilter } from 'react-select'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  requestGetSectorList,
  requestGetProducts
} from '../../../Redux/actions'
import CreatableSelect from 'react-select/creatable'

function SectorLevel (props) {
  const {
    selectedOptionsSector,
    handleSectorLevelChange,
    handleProductChange,
    CreatAd_Five,
    validateSector,
    sectorRef,
    errorSector,
    errorCreatAd_Five,
    handleSubSectorLevelChange,
    validateSubSector,
    subsectorRef,
    errorSubSector,
    isLoading,
    mystyle,
    sectorLabel = 'Settore',
    hideSubSector = false,
    hidden = false,
    t,
    adType,
    prodottiRef,
    selectedProduct
  } = props

  const [sectorsLevel, setSectorsLevel] = useState([])
  const [productData, setProductData] = useState([])
  const [subSubSectorsLevel, setSubSubSectorsLevel] = useState([])
  const isClearable = true

  useEffect(() => {
    props.requestGetSectorList()

    console.log(adType, 'adType')
  }, [])

  useEffect(() => {
    const productData = props.agent.GetProductsData
    if (productData?.data?.Status === 'Success') {
      const products = productData.data.Message.map(product => ({
        value: product.id,
        label: product.product
      }))

      setProductData(products)
    }
  }, [props.agent.ProductsData])

  useEffect(() => {
    const sectorsLevelData = props.candidate.SectorData
    if (sectorsLevelData?.data?.Status === 'Success') {
      const uniqueSectors = {}

      sectorsLevelData.data.Message.forEach(sector => {
        if (!uniqueSectors[sector.sectorName]) {
          uniqueSectors[sector.sectorName] = {
            value: sector.id,
            label: sector.sectorName
          }
        }
      })
      const transformedSectorlevelData = Object.values(uniqueSectors)
      setSectorsLevel(transformedSectorlevelData)
      console.log('selectedOptionsSector_before', selectedOptionsSector)
      const filteredSubMenuOptions = sectorsLevelData.data.Message.filter(
        level =>
          level.subSector &&
          level.sectorName === selectedOptionsSector?.submenu.label
      ).map(level => ({
        sectorName: level.sectorName,
        subSector: level.subSector
      }))

      setSubSubSectorsLevel(filteredSubMenuOptions)
    }
  }, [props.candidate.SectorData])

  const selectedSubmenuOption = subSubSectorsLevel.filter(
    option => option.sectorName === selectedOptionsSector?.submenu
  )

  //   const hasSubSubmenuOptions =
  //     selectedSubmenuOption && selectedSubmenuOption?.length > 0;

  if (hidden) return <></>

  return (
    <>
      <div className='registration_col form-group col-12'>
        <label htmlFor='sector' className='label'>
          {sectorLabel}
        </label>
        <CreatableSelect
          name='sector'
          className={`form-control set_max_width select_dropdown  ${
            errorCreatAd_Five.sector ? 'error' : ''
          } `}
          options={sectorsLevel}
          onChange={(...args) => {
            handleSectorLevelChange(...args)
            const uState = (() => {
              if (props.agent.SectorData) return props.agent.SectorData
              if (props.company.SectorData) return props.company.SectorData
              if (props.candidate.SectorData) return props.candidate.SectorData
              return null
            })()

            const optList = uState?.data.Message
            const subSectorsList = optList
              ?.filter(sector => sector.sectorName === args[0]?.label)
              .filter(s => !!s.subSector)

            if (subSectorsList) {
              setSubSubSectorsLevel(subSectorsList)
            }
          }}
          value={
            selectedOptionsSector.submenu
              ? {
                  label:
                    selectedOptionsSector.submenu.label ||
                    selectedOptionsSector.submenu,
                  value:
                    selectedOptionsSector.submenu.value ||
                    selectedOptionsSector.submenu
                }
              : ''
          }
          ref={sectorRef}
          placeholder='Seleziona un Settore'
          onBlur={validateSector}
          isClearable={isClearable}
          isLoading={isLoading}
        />
        {errorCreatAd_Five.sector && (
          <div style={mystyle}>{errorCreatAd_Five.sector}</div>
        )}
      </div>

      {!hideSubSector && adType !== 'Agente' ? (
        subSubSectorsLevel?.length || CreatAd_Five.subSector ? (
          <>
            <div className='registration_col form-group col-12 mt-4'>
              <label>Sottosettore</label>
              <select
                name='sector'
                value={
                  subSubSectorsLevel.find(
                    subOption =>
                      subOption.subSector === selectedOptionsSector?.subSubmenu
                  )?.subSector || CreatAd_Five?.subSector
                }
                onChange={handleSubSectorLevelChange}
                onBlur={validateSubSector}
                ref={subsectorRef}
                className={`form-control set_max_width mt-2 ${
                  errorCreatAd_Five.subsector ? 'error' : ''
                }`}
              >
                <option value=''>Seleziona un Sottosettore</option>
                {subSubSectorsLevel?.map((subOption, index) => (
                  <option key={index} value={subOption.subSector}>
                    {subOption.subSector}
                  </option>
                ))}
              </select>
              {errorCreatAd_Five.subsector && (
                <div style={mystyle}>{errorCreatAd_Five.subsector}</div>
              )}
            </div>
          </>
        ) : (
          <></>
        )
      ) : (
        adType === 'Agente' && (
          <div className='registration_col form-group col-12 mt-4'>
            <label htmlFor='products' className='label'>
              Prodotti
            </label>
            <Select
              type='text'
              className={`form-control select_dropdown ${
                errorSubSector ? 'error' : ''
              }`}
              options={productData}
              placeholder='Prodotti'
              id='products'
              name='products'
              ref={prodottiRef}
              value={selectedProduct}
              onChange={e => handleProductChange(e)}
              //onBlur={validateSubSector}
            />
            {errorSubSector && <div style={mystyle}>{errorSubSector}</div>}
          </div>
        )
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    candidate: state.candidate,
    company: state.company,
    agent: state.agent
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ requestGetSectorList, requestGetProducts }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SectorLevel)
