import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Header from './Header'
import Footer from './Footer'
import { useAppNavigation } from '../utils/routing'

function ThankYou () {
  const { t } = useTranslation()

  const { navigateTo } = useAppNavigation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigateTo('/', {
        replaceFrom: true
      })
    }, 5000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <Header />
      <div className='thank_you_sec'>
        <div className='container'>
          <div className='thank_you_inner'>
            <div className='thankyou_main py-5 px-3'>
              <div className='text-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='75'
                  height='75'
                  fill='currentColor'
                  className='bi bi-check-circle-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
                </svg>
              </div>
              <div className='text-center thankyou_content'>
                <h1>{t('thanksbase.title')}</h1>
                <h3>{t('thanksbase.content')}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ThankYou
