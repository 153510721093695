// File: /src/Franchisor/search-franchisor/SearchFranchisor.js
// Created Date: Thursday September 19th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Thursday September 19th 2024 4:32:39 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Select from "react-select";
import { useAppNavigation } from "../../utils/routing";
import { bindActionCreators } from "redux";
import {
  requestGetFilterOptions,
  requestSaveFilterOptions,
} from "../../Redux/actions";
import { connect } from "react-redux";
import Swal from "sweetalert2";

const SearchFranchisor = (props) => {
  const [leftColumnSettings, setLeftColumnSettings] = useState({
    investment: {
      label: "Che investimento saresti disposto a fare per la tua attività?",
    },
    financing: {
      label: "Vorresti un finanziamento?",
    },
    contact_with_public: {
      label: "Nel tuo lavoro ti piacerebbe avere un contatto con il pubblico?",
    },
    interested_markets: {
      label: "Quali mercati potrebbero interessarti?",
    },
    work_for_brand: {
      label: "Ti piacerebbe lavorare per un marchio?",
    },
    work_hours: {
      label: "Che orario di lavoro ti piacerebbe?",
    },
    franchisor_assistance: {
      label: "Vorresti ricevere assistenza da parte del franchisor?",
    },
    experience_in_business: {
      label:
        "Credi che potrai impiegare nell'attività eventuali esperienze lavorative accumulate?",
    },
    products: {
      label: "Ti piacerebbe offrire dei prodotti?",
    },
  });
  const [rightColumnSettings, setRightColumnSettings] = useState({
    consignment: {
      label: "I prodotti li vorresti in contovendita?",
    },
    locale_size: {
      label: "Che ampiezza di locale hai disponibile?",
    },
    relocation: {
      label: "Saresti disponibile a spostarti?",
    },
    user_base: {
      label: "Che bacino di utenza pensi di voler servire?",
    },
    age: {
      label: "Che età hai?",
    },
    gender: {
      label: "Di che sesso sei?",
    },
    education: {
      label: "Che titolo di studio hai?",
    },
    residence: {
      label: "In che comune risiedi?",
    },
    current_position: {
      label: "Attuale posizione lavorativa",
    },
  });

  const [data, setData] = useState();

  useEffect(() => {
    props.requestGetFilterOptions();
  }, []);

  useEffect(() => {
    const insertOptions = (prev) => {
      return Object.fromEntries(
        Object.entries(prev).map(([name, setting], i) => {
          let options = [];

          if (
            Object.keys(
              props.candidate?.GetFilterOptionsData?.data.Message
            ).includes(name)
          ) {
            options = props.candidate?.GetFilterOptionsData?.data.Message[name];
          }

          return [
            name,
            {
              ...setting,
              options: options.map((o) => {
                if (o.id) {
                  return {
                    label: o.answer ?? o.name,
                    value: o.id,
                  };
                }

                return {
                  label: o.levelType,
                  value: o.levelType,
                };
              }),
            },
          ];
        })
      );
    };

    if (props.candidate?.GetFilterOptionsData?.data.Message) {
      setLeftColumnSettings((s) => insertOptions(s));
      setRightColumnSettings((s) => insertOptions(s));
    }
  }, [props.candidate?.GetFilterOptionsData]);

  const { navigateTo, location } = useAppNavigation();

  const onSubmitForm = (e) => {
    e.preventDefault();

    const formData = {};
    const formElements = e.target.elements;

    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      if (element.value?.length) {
        formData[element.name] = element.value;
      } else {
        element.focus();
        const container =
          element.parentElement?.parentElement?.parentElement?.parentElement;
        container.classList.add("error");

        if (!container.parentElement.querySelector(".error-message")) {
          const errorMessage = document.createElement("p");
          errorMessage.textContent = "Questo campo è obbligatorio.";
          errorMessage.style.color = "#D10000";
          errorMessage.style.marginTop = "4px";
          errorMessage.classList.add("error-message");
          container.parentElement?.appendChild(errorMessage);
        }
      }
    }

    if (!Object.entries(formData ?? {}).length) return;

    if (!Object.values(formData).every(Boolean)) return;

    // if (props.candidate?.loginData?.data.Message?.flag_franchisee) {
    props.requestSaveFilterOptions({
      data: formData,
      token: props.franchisor?.loginData?.data.Message.token,
    });
    localStorage.setItem("franchisee_answers", JSON.stringify(formData));
    // if (!location.state?.searching) return;
    if (location.state?.searching) {
      navigateTo("/franchisor/search2", {
        state: {
          filterData: { ...formData },
        },
      });
    }
    // } else {
    if (!location.state?.searching) {
      navigateTo("/company/register", {
        state: {
          filterData: { ...formData },
        },
        params: {
          type: "franchisse",
        },
      });
    }
    // }
  };

  useEffect(() => {
    if (!props.franchisor?.saveFilterOptionsData) return;
    if (props.franchisor?.saveFilterOptionsData?.data.Status === "Success") {
      Swal.fire({
        title: "Filtraggio salvato correttamente",
        timer: 2000,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: "Errore nel salvataggio del filtraggio",
        timer: 2000,
        icon: "error",
      });
    }
  }, [props.franchisor?.saveFilterOptionsData]);

  const handleSelectChange = (name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Header />
      <div className="registration_sec">
        <div className="container">
          <div className="registration_title">
            <h3>Cerca un franchising</h3>
          </div>
          <form className="registration_form" onSubmit={onSubmitForm}>
            <div className="row">
              <div className="col-12 col-md-6 mb-4">
                <h6 className="mb-4">Quale franchisor può interessarti?</h6>
                <div className="registration_row gap-4 d-flex flex-column">
                  {Object.entries(leftColumnSettings).map(
                    ([name, setting], i) => (
                      <div
                        className="registration_col form-group col-12"
                        key={i}
                      >
                        <label>{setting.label}</label>
                        <Select
                          name={name}
                          className={`form-control set_max_width select_dropdown`}
                          options={setting.options ?? []}
                          value={data?.[name]}
                          onChange={(selectedOption) =>
                            handleSelectChange(name, selectedOption)
                          }
                          placeholder={"Seleziona un'opzione"}
                          isMulti={setting.isMulti}
                          isClearable={true}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className="col-12 col-md-6 mb-4">
                <h6 className="mb-4">Compila il questionario</h6>
                <div className="registration_row gap-4 d-flex flex-column">
                  {Object.entries(rightColumnSettings).map(
                    ([name, setting], i) => (
                      <div className="registration_col form-group col-12">
                        <label>{setting.label}</label>
                        <Select
                          name={name}
                          className={`form-control set_max_width select_dropdown`}
                          options={setting.options ?? []}
                          value={data?.[name]}
                          onChange={(selectedOption) =>
                            handleSelectChange(name, selectedOption)
                          }
                          placeholder={"Seleziona un'opzione"}
                          isMulti={setting.isMulti}
                          isClearable={true}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="registration_button text-center">
              <button type="submit" className="btn">
                {location.state?.searching
                  ? "Avanti con la ricerca"
                  : "Registra la mia attività"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return { franchisor: state.francisor, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestGetFilterOptions,
      requestSaveFilterOptions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchFranchisor);
