import React from "react";
import { generateYears, months } from "../../common/YearMonthResume";

function MonthYearsExp(props) {
  const years = generateYears();
  const {
    i,
    x,
    errorexperience,
    handleInputExperience,
    validateExperienceYearFrom,
    validateExperienceYearTo,
    focusRefs,
    mystyle,
    t,
  } = props;
  return (
    <>
      <div className="col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label htmlFor="monthFrom">{t("time.From_year")}</label>
          <div className="row">
            <div className="col-lg-4 col-md-6" style={{ padding: "0px 5px" }}>
              <select
                className="form-control"
                name="monthFrom"
                id={"monthFromexp" + i}
                value={x.monthFrom}
                onChange={(e) => handleInputExperience(e, i)}
              >
                <option value="">{t("time.select_month")}</option>
                {months.map((month, index) => (
                  <option
                    key={index}
                    value={(index + 1).toString().padStart(2, "0")}
                  >
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-8 col-md-6" style={{ padding: "0px 5px" }}>
              <select
                className={`form-control ${
                  errorexperience[i]?.yearFrom ? "error" : ""
                }`}
                name="yearFrom"
                id={"yearFromexp" + i}
                value={x.yearFrom}
                ref={(el) => {
                  focusRefs.experience.current[i] =
                    focusRefs.experience.current[i] || {};
                  focusRefs.experience.current[i].yearFromexp = el;
                }}
                onBlur={() => validateExperienceYearFrom(i)}
                onChange={(e) => handleInputExperience(e, i)}
              >
                <option value="">{t("time.select_year")}</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              {errorexperience[i]?.yearFrom && (
                <div style={mystyle}>{errorexperience[i]?.yearFrom}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label htmlFor="monthTo">{t("time.To_year")}</label>
          <div className="row">
            {x?.yearTo !== "presente" && (
              <div className="col-lg-4 col-md-6" style={{ padding: "0px 5px" }}>
                <div className="form-group">
                  <select
                    className="form-control"
                    name="monthTo"
                    id={"monthToexp" + i}
                    value={x.monthTo}
                    onChange={(e) => handleInputExperience(e, i)}
                  >
                    <option value="">{t("time.select_month")}</option>
                    {months.map((month, index) => (
                      <option
                        key={index}
                        value={(index + 1).toString().padStart(2, "0")}
                      >
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="col-lg-8 col-md-6" style={{ padding: "0px 5px" }}>
              <div className="form-group">
                <select
                  className={`form-control ${
                    errorexperience[i]?.yearTo ? "error" : ""
                  }`}
                  name="yearTo"
                  id={"yearToexp" + i}
                  value={x.yearTo}
                  ref={(el) => {
                    focusRefs.experience.current[i] =
                      focusRefs.experience.current[i] || {};
                    focusRefs.experience.current[i].yearToexp = el;
                  }}
                  onBlur={() => validateExperienceYearTo(i)}
                  onChange={(e) => handleInputExperience(e, i)}
                >
                  <option value="">{t("time.select_year")}</option>
                  <option value="presente">Presente</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                {errorexperience[i]?.yearTo && (
                  <div style={mystyle}>{errorexperience[i]?.yearTo}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MonthYearsExp;
