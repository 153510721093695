import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import Footer from "../Components/Footer";
import FormBuilder from "../Components/FormBuilder";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { requestGetSectorList } from "../Redux/actions";

const FIELDS = [
  {
    label: "Tipo di intervento",
    name: "macroservices",
    type: "select",
    mandatory: true,
  },
  {
    label: "Servizio richiesto",
    name: "services",
    type: "select",
    mandatory: true,
  },
];

const FIELDS_MULTI = [
  {
    type: "blocks",
    submitLabel: "Aggiungi Servizio",
    name: "services",
    size: 2,
    fields: [
      {
        label: "Intervento",
        name: "macroservices",
        type: "select",
        mandatory: true,
      },
      {
        label: "Servizio",
        name: "services",
        type: "select",
        mandatory: true,
      },
    ],
  },
];

const DUMMY_OPTIONS = {
  macroservices: [
    {
      label: "Termotecnica",
      value: 1,
    },
    {
      label: "Meccanica",
      value: 2,
    },
    {
      label: "Edilizia",
      value: 4,
    },
    {
      label: "Informatica",
      value: 5,
    },
  ],
  services: [
    { label: "Servizio 1", value: 1 },
    { label: "Servizio 2", value: 2 },
  ],
};

const OfferedServices = (props) => {
  // const [options, setOptions] = useState(DUMMY_OPTIONS);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [sectorsLevel, setSectorsLevel] = useState({});
  const [subSubSectorsLevel, setSubSubSectorsLevel] = useState({});

  const [allSubSubSectors, setAllSubSubSectors] = useState([]);

  const handleChange = async (formData) => {
    try {
      const serviceOptions = {};
      for (let i = 0; i < formData.services.length; i++) {
        const entries = formData.services[i];
        const macroService = entries.macroservices?.[0];
        const label = sectorsLevel[i].find(
          (sector) => sector.value === macroService
        )?.label;
        serviceOptions[i] = allSubSubSectors.filter(
          (subSubSector) => subSubSector.sectorName === label
        );
      }
      setSubSubSectorsLevel(serviceOptions);
    } catch (error) {
      console.log(`error services: ${error}`);
    }
  };

  useEffect(() => {
    const sectorsLevelData = props.agent.SectorData;

    if (sectorsLevelData?.data?.Status === "Success") {
      const uniqueSectors = {};

      sectorsLevelData.data.Message.forEach((sector) => {
        if (!uniqueSectors[sector.sectorName]) {
          uniqueSectors[sector.sectorName] = {
            value: sector.id,
            label: sector.sectorName,
          };
        }
      });
      const transformedSectorlevelData = Object.values(uniqueSectors);
      setSectorsLevel({ 0: transformedSectorlevelData });

      const filteredSubMenuOptions = sectorsLevelData.data.Message.filter(
        (level) => level.subSector
      ).map((level) => ({
        value: level.id,
        sectorName: level.sectorName,
        subSector: level.subSector,
        label: level.subSector,
      }));

      setAllSubSubSectors(filteredSubMenuOptions);
    }
  }, [props.agent.SectorData]);

  useEffect(() => {
    props.requestGetSectorList();
  }, []);

  const handleSubmit = (formData) => {
    if (!formData) return;
    const services =
      searchParams.get("registering") === "true"
        ? formData.services.reduce((acc, curr) => {
            return {
              macroservices: [
                ...(acc.macroservices || []),
                ...curr.macroservices,
              ],
              services: [...(acc.services || []), ...curr.services],
            };
          }, {})
        : formData;
    if (searchParams.get("type") === "searching") {
      navigate(
        `/questions-tree?service=${
          formData.services?.[formData.macroservices?.length - 1]
        }&macro_service=${
          formData.macroservices?.[formData.macroservices?.length - 1]
        }&rtype=${searchParams.get("rtype")}`
      );
    }
    if (
      searchParams.get("type") === "professional" ||
      searchParams.get("type") === "artisan"
    ) {
      navigate(
        `/display-name?type=${searchParams.get("type")}&registering=true`,
        {
          state: {
            formData: {
              ...services,
              ...location.state.formData,
            },
          },
        }
      );
    }
  };

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header />
      <Breadcrumbs />
      <div className="container mt-4 h-100">
        <div className="h-100">
          <div className="seekng_job_title mb-4">
            <h2>
              {(() => {
                if (searchParams.get("registering") === "true") {
                  return "I servizi che offro";
                }
                return (
                  <span>
                    Ho bisogno di un{" "}
                    {searchParams.get("type") === "professional"
                      ? "Professionista"
                      : searchParams.get("type") === "artisan"
                      ? "Artigiano"
                      : "Servizio"}
                  </span>
                );
              })()}
            </h2>
          </div>
          <FormBuilder
            fields={
              searchParams.get("registering") === "true" ? FIELDS_MULTI : FIELDS
            }
            onSubmit={handleSubmit}
            onChange={handleChange}
            submitLabel="Avanti"
            onAdd={() => {
              setSectorsLevel((p) => {
                const newSectorsLevel = { ...p };
                newSectorsLevel[Object.keys(p).length] = p[0];
                return newSectorsLevel;
              });
            }}
            onBack={() => navigate(-1)}
            selectOptions={{
              macroservices: sectorsLevel,
              services: subSubSectorsLevel,
            }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { candidate: state.candidate, agent: state.agent };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestGetSectorList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OfferedServices);
