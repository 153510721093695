import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { requestGetCandidate, userLogout } from "../../Redux/actions";
import Facebook from "../../images/facebook.png";
import Google from "../../images/google.png";
import LinkedIn from "../../images/LinkedIn.png";
import { auth } from "../../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

function SocialLogin(props) {
  const { setShowModal, handleCloseModal } = props;
  const navigate = useNavigate();

  const handleGoogleLoginOld = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);

      if (result && result.user) {
        const email = result.user.email;
        if (email) {
          try {
            // localStorage.setItem("token", result.user.accessToken);
            await props.requestGetCandidate({ data: { username: email } });
            setTimeout(() => {
              if (Object.prototype.hasOwnProperty.call(props, "candidate")) {
                if (Object.keys(props.candidate).length > 0) {
                  console.log("props", props);
                  let candidateDetails = props.candidate.GetCandidateData;

                  if (typeof candidateDetails !== "undefined") {
                    console.log("candidateDetails", candidateDetails);
                    if (candidateDetails.data.Status === "Success") {
                      const updateData = candidateDetails.data.Message[0] || {};
                      console.log("updateData", updateData);
                      if (updateData) {
                        console.log(updateData);
                        // localStorage.setItem("token", result.user.accessToken);
                        localStorage.setItem("username", email);
                        if (updateData.flag_employee) {
                          localStorage.setItem("userType", "employee");
                        } else if (updateData.flag_agent) {
                          localStorage.setItem("userType", "agent");
                        } else if (updateData.flag_franchisor) {
                          localStorage.setItem("userType", "franchisor");
                        } else if (updateData.flag_company) {
                          localStorage.setItem("userType", "company");
                        } else if (updateData.flag_professional) {
                          localStorage.setItem("userType", "professional");
                        } else if (updateData.flag_artisan) {
                          localStorage.setItem("userType", "artisan");
                        } else if (updateData.flag_private) {
                          localStorage.setItem("userType", "private");
                        } else {
                          localStorage.setItem("userType", "noType");
                        }
                        handleCloseModal();
                        toast.success("Autenticato con successo", {
                          theme: "colored",
                          autoClose: 8000,
                        });
                        navigate("/", { replace: true });
                        window.location.reload();
                      } else {
                        toast.error(
                          "I dettagli utente non sono stati recuperati correttamente",
                          {
                            theme: "colored",
                            autoClose: 8000,
                          }
                        );
                      }
                    } else {
                      toast.error("Utuente non trovato", {
                        theme: "colored",
                        autoClose: 8000,
                      });
                    }
                  }
                }
              }
            }, 2000);
          } catch (err2) {
            console.log(err2);
          }
        } else {
          toast.error("Email not found in response", {
            theme: "colored",
            autoClose: 8000,
          });
        }
      } else {
        toast.error("Login response is not valid", {
          theme: "colored",
          autoClose: 8000,
        });
      }
    } catch (error) {
      toast.error("Failed to login with Google", {
        theme: "colored",
        autoClose: 8000,
      });
      console.error("Error during Google login:", error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);

      if (result && result.user) {
        const email = result.user.email;
        console.log(result, "result");
        if (email) {
          try {
            await props.requestGetCandidate({
              data: { username: email },
              token: result.user.accessToken,
            });
            setTimeout(() => {
              const candidateDetails = props.candidate.GetCandidateData;
              console.log(candidateDetails, "candidateDetails");
              if (candidateDetails !== undefined) {
                if (
                  candidateDetails &&
                  candidateDetails.data.Status === "Success"
                ) {
                  const updateData = candidateDetails.data.Message[0] || {};

                  if (updateData) {
                    console.log(updateData, "updateData");
                    handleCloseModal();
                    toast.success("Utente autenticato correttamente", {
                      theme: "colored",
                      autoClose: 8000,
                    });
                    localStorage.setItem("username", email);
                    localStorage.setItem("token", result?.user?.accessToken);

                    localStorage.setItem("representative", updateData?.representative);
                    localStorage.setItem("representative_id", updateData?.id);
                    const date = new Date();
                    date.setMinutes(date.getMinutes() + updateData?.duration);
                    localStorage.setItem("expiration", date.toISOString());
                    if (!updateData.flag_company) {
                      localStorage.setItem("username", updateData?.username);
                    }
                    let userType = "noType";

                    switch (true) {
                      case updateData.flag_employee:
                        userType = "employee";
                        break;
                      case updateData.flag_agent:
                        userType = "agent";
                        break;
                      case updateData.flag_artisan:
                        userType = "artisan";
                        break;
                      case updateData.flag_franchisor:
                        userType = "franchisor";
                        break;
                      case updateData.flag_private:
                        userType = "private";
                        break;
                      case updateData.flag_professional:
                        userType = "professional";
                        break;
                      case updateData.flag_company:
                        userType = "company";
                        break;
                      default:
                        userType = "noType";
                        break;
                    }

                    localStorage.setItem("userType", userType);

                    switch (userType) {
                      case "employee":
                        // navigate("/candidate/profile", { replace: true });
                        break;
                      case "noType":
                        // navigate(location.pathname, {
                        //   replace: false,
                        //   state: location.state,
                        // });
                        //navigate("/candidate/profile", { replace: true });
                        break;
                      case "company":
                      case "professional":
                      case "artisan":
                        localStorage.setItem(
                          "can_see_resumes",
                          updateData?.can_see_resumes
                        );
                        // navigate("/company/profile", { replace: true });
                        break;
            
                      case "agent":
                        // navigate("/agent/profile", { replace: true });
                        break;
            
                      case "franchisor":
                        // navigate("/service/profile", { replace: true });
                        break;
            
                      // case "professional":
                      //   navigate("/professional");
                      //   break;
            
                      default:
                      // navigate(location.pathname, {
                      //   replace: true,
                      //   state: location.state,
                      // });
                    }
                    navigate("/", { replace: true });
                    window.location.reload();
                  } else {
                    toast.error("I dettagli utente sono incompleti", {
                      theme: "colored",
                      autoClose: 8000,
                    });
                  }
                } else {
                  toast.error("Utente non trovato", {
                    theme: "colored",
                    autoClose: 8000,
                  });
                }
              }
            }, 2000);
          } catch (err2) {
            console.error("Errore durante il recupero dei dettagli dell'utente:", err2);
            toast.error("Impossibile recuperare i dettagli dell'utente", {
              theme: "colored",
              autoClose: 8000,
            });
          }
        } else {
          toast.error("Email non trovata nella risposta", {
            theme: "colored",
            autoClose: 8000,
          });
        }
      } else {
        toast.error("Risposta del login non valida", {
          theme: "colored",
          autoClose: 8000,
        });
      }
    } catch (error) {
      toast.error("Impossibile effettuare il login con Google", {
        theme: "colored",
        autoClose: 8000,
      });
      console.error("Errore durante il login con Google:", error);
    }
  };

  const handleFaceBookLogin = async () => {
    try {
      const facebookProvider = new FacebookAuthProvider();
      const result = await signInWithPopup(auth, facebookProvider);

      if (result && result.user) {
        const email = result.user.email;
        console.log(result, "result");
        if (email) {
          try {
            await props.requestGetCandidate({
              data: { username: email },
              token: result.user.accessToken,
            });
            setTimeout(() => {
              const candidateDetails = props.candidate.GetCandidateData;
              console.log(candidateDetails, "candidateDetails");
              if (candidateDetails !== undefined) {
                if (
                  candidateDetails &&
                  candidateDetails.data.Status === "Success"
                ) {
                  const updateData = candidateDetails.data.Message[0] || {};

                  if (updateData) {
                    console.log(updateData, "updateData");
                    handleCloseModal();
                    toast.success("Utente autenticato correttamente", {
                      theme: "colored",
                      autoClose: 8000,
                    });
                    localStorage.setItem("username", email);
                    localStorage.setItem("token", result?.user?.accessToken);

                    localStorage.setItem("representative", updateData?.representative);
                    localStorage.setItem("representative_id", updateData?.id);
                    const date = new Date();
                    date.setMinutes(date.getMinutes() + updateData?.duration);
                    localStorage.setItem("expiration", date.toISOString());
                    if (!updateData.flag_company) {
                      localStorage.setItem("username", updateData?.username);
                    }
                    let userType = "noType";

                    switch (true) {
                      case updateData.flag_employee:
                        userType = "employee";
                        break;
                      case updateData.flag_agent:
                        userType = "agent";
                        break;
                      case updateData.flag_artisan:
                        userType = "artisan";
                        break;
                      case updateData.flag_franchisor:
                        userType = "franchisor";
                        break;
                      case updateData.flag_private:
                        userType = "private";
                        break;
                      case updateData.flag_professional:
                        userType = "professional";
                        break;
                      case updateData.flag_company:
                        userType = "company";
                        break;
                      default:
                        userType = "noType";
                        break;
                    }

                    localStorage.setItem("userType", userType);

                    if (userType === "company" || userType === "professional" || userType === "artisan") {
                      localStorage.setItem(
                        "can_see_resumes",
                        updateData?.can_see_resumes
                      );
                    }

                    navigate("/", { replace: true });
                    window.location.reload();
                  } else {
                    toast.error("I dettagli utente sono incompleti", {
                      theme: "colored",
                      autoClose: 8000,
                    });
                  }
                } else {
                  toast.error("Utente non trovato", {
                    theme: "colored",
                    autoClose: 8000,
                  });
                }
              }
            }, 2000);
          } catch (err2) {
            console.error("Errore durante il recupero dei dettagli dell'utente:", err2);
            toast.error("Impossibile recuperare i dettagli dell'utente", {
              theme: "colored",
              autoClose: 8000,
            });
          }
        } else {
          toast.error("Email non trovata nella risposta", {
            theme: "colored",
            autoClose: 8000,
          });
        }
      } else {
        toast.error("Risposta del login non valida", {
          theme: "colored",
          autoClose: 8000,
        });
      }
    } catch (error) {
      toast.error("Impossibile effettuare il login con Facebook", {
        theme: "colored",
        autoClose: 8000,
      });
      console.error("Errore durante il login con Facebook:", error);
      navigate("/");
    }
  };

  const [searchParams] = useSearchParams();

  const handleLinkedInLogin = () => {
    const linkedInAuthUrl = "https://www.linkedin.com/oauth/v2/authorization";
    const params = {
      response_type: "code",
      client_id: "77s58qobsiim6i",
      redirect_uri: `${window.location.origin}/home?path=${
        window.location.pathname
      }&registering=true&area=${searchParams.get("userType") ?? "candidate"}`,
      scope: "profile email openid",
    };

    const queryString = Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    window.location.href = `${linkedInAuthUrl}?${queryString}`;
  };

  const redirectToRegister = () => {
    setShowModal("register");
  };

  return (
    <div className="login_social">
      <div className="or_line">
        <p>OPPURE</p>
      </div>
      <div className="login_social_btn">
        <button
          type="submit"
          className="btn social_login_btn"
          onClick={handleFaceBookLogin}
        >
          <img src={Facebook} alt="Main Logo" />
          Continua con Facebook
        </button>
        <button
          type="submit"
          className="btn social_login_btn"
          onClick={handleGoogleLogin}
        >
          <img src={Google} alt="Main Logo" />
          Continua con Google
        </button>
        <button
          type="submit"
          className="btn social_login_btn"
          onClick={handleLinkedInLogin}
        >
          <img src={LinkedIn} alt="Main Logo" />
          Continua con LinkedIn
        </button>
      </div>
      <div className="terms_privacy">
        <p>
          Continuando, accetti i
          <a
            href="https://www.iubenda.com/privacy-policy/51986058"
            target="_blank"
            rel="noopener noreferrer"
          >
            Termini d’uso
          </a>
          <br />e l’
          <a
            href="https://www.iubenda.com/privacy-policy/51986058/cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Informativa Privacy
          </a>
        </p>
      </div>

      <div className="sign_in_account">
        <p>
          Non hai ancora un account? &nbsp;
          <a onClick={redirectToRegister}>Registrati</a>
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestGetCandidate,
      userLogout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);
