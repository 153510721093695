import React, { useEffect, useState, useRef } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'
import AdditionalWages from './AdditionalWages'
import Benefits from './Benefits'
import BackButton from '../common/BackButton'
import NextButton from '../common/NextButton'
import CreaAnnuncioFiveAgent from './agent/CreaAnnuncioFiveAgent'
import SectorLevel from './SectorLevel'

function CreaAnnuncioFive (props) {
  const {
    nextStep,
    prevStep,
    CreatAd_Five,
    setCreatAd_Five,
    errorCreatAd_Five,
    additionalWagesData,
    benefitsData,
    handleInputChange,
    filterConfig,
    mystyle,
    setErrorCreatAd_Five,
    t,
    handleCreateFiveAdditionalWages,
    handleCreateFiveBenefits,
    handleSectorChange,
    validateForFixAmount,
    validateForSalaryRecurrence,
    validateForMinAmount,
    validateForMaxAmount,
    validateAdditionalWages,
    validateBenefit,
    isClearable,
    isSearchable,
    selectedOptionsBenefits,
    selectedOptionsAdditionalWages,
    selectedOptionsSector,
    setSelectedOptionsSector,
    minAmountRef,
    maxAmountRef,
    fixedAmountRef,
    salaryRecurrenceRef,
    additionalWagesRef,
    benefitRef,
    error,
    setError,
    newAdvertisementId,
    setStepParam,
    ad_type,
    selectedProduct,
    setSelectedProduct,
    handleProductChange
  } = props

  /*const [selectedOptionsSector, setSelectedOptionsSector] = useState({
    submenu: '',
    subSubmenu: ''
  })*/

  const sectorRef = useRef(null)
  const subsectorRef = useRef(null)

  const prodottiRef = useRef(null)

  const [errorSector, setErrorSector] = useState('')
  const [errorSubSector, setErrorSubSector] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState({
    country: 'Italia',
    phone: '+39'
  })

  useEffect(() => {
    const focusField = () => {
      if (errorCreatAd_Five.minAmount && minAmountRef?.current) {
        minAmountRef.current.focus()
      } else if (errorCreatAd_Five.maxAmount && maxAmountRef?.current) {
        maxAmountRef.current.focus()
      } else if (errorCreatAd_Five.fixedAmount && fixedAmountRef?.current) {
        fixedAmountRef.current.focus()
      } else if (
        errorCreatAd_Five.reccurrency &&
        salaryRecurrenceRef?.current
      ) {
        salaryRecurrenceRef.current.focus()
      } else if (
        errorCreatAd_Five.additional_wages &&
        additionalWagesRef?.current
      ) {
        additionalWagesRef.current.focus()
      } else if (errorCreatAd_Five.benefit && benefitRef?.current) {
        benefitRef.current.focus()
      }
    }

    if (error) {
      focusField()
      setError(false)
    }
  }, [
    error,
    errorCreatAd_Five,
    minAmountRef,
    maxAmountRef,
    fixedAmountRef,
    salaryRecurrenceRef,
    additionalWagesRef,
    benefitRef
  ])

  const handleSectorLevelChange = e => {
    setIsLoading(true)
    console.log(e, 'e')
    if (e !== null) {
      setTimeout(() => {
        const updatedSelectedOptions = e ? e.label : ''
        setData(prevData => ({
          ...prevData,
          sector: updatedSelectedOptions
        }))
        setSelectedOptionsSector(prevOptions => ({
          ...prevOptions,
          submenu: updatedSelectedOptions,
          subSubmenu: ''
        }))
        setCreatAd_Five(prevData => ({
          ...prevData,
          sector: updatedSelectedOptions
        }))
        setErrorSector('')
        setErrorCreatAd_Five(prevErrors => ({
          ...prevErrors,
          sector: ''
        }))
        setIsLoading(false)
      }, 50)
    } else {
      setData(prevData => ({ ...prevData, sector: '' }))
      setSelectedOptionsSector(prevOptions => ({
        ...prevOptions,
        submenu: '',
        subSubmenu: ''
      }))
      setErrorSector('Questo campo è obbligatorio')
      setErrorCreatAd_Five(prevErrors => ({
        ...prevErrors,
        sector: 'Questo campo è obbligatorio'
      }))

      setIsLoading(false)
      validateSector()
    }
  }

  const handleSubSectorLevelChange = e => {
    const { value } = e.target

    setSelectedOptionsSector(prevOptions => {
      const newOptions = {
        ...prevOptions,
        subSubmenu: value
      }

      //const sector = `${newOptions.submenu}^*${value}`
      //setData(prevData => ({
      //  ...prevData,
      //  sector
      //}))
      setCreatAd_Five(prevData => ({
        ...prevData,
        subSector: value
      }))
      console.log(data, 'data')
      return newOptions
    })
  }

  const validateSector = () => {
    if (!sectorRef?.current?.props?.value) {
      setErrorSector('Seleziona il tuo Settore.')
      setErrorCreatAd_Five(prevErrors => ({
        ...prevErrors,
        sector: 'Seleziona il tuo Settore.'
      }))
      return false
    } else {
      setErrorSector('')
      setErrorCreatAd_Five(prevErrors => ({
        ...prevErrors,
        sector: ''
      }))
      return true
    }
  }

  useEffect(() => {
    validateSubSector()
  }, [selectedOptionsSector])

  const validateSubSector = () => {
    if (subsectorRef?.current) {
      console.log(subsectorRef.current, 'subsectorRef.current.value')
      const subSectorMenu = selectedOptionsSector?.subSubmenu
      if (!subSectorMenu) {
        setErrorSubSector('Seleziona il tuo Sottosettore.')
        setErrorCreatAd_Five(prevErrors => ({
          ...prevErrors,
          subsector: 'Seleziona il tuo Sottosettore.'
        }))
        return false
      }
    }
    setErrorSubSector('')
    setErrorCreatAd_Five(prevErrors => ({
      ...prevErrors,
      subsector: ''
    }))
    return true
  }

  console.log('CreatAd_Five', CreatAd_Five)
  if (props?.ad_type == 'Agent') {
    return (
      <CreaAnnuncioFiveAgent
        nextStep={nextStep}
        prevStep={prevStep}
        CreatAd_Five={CreatAd_Five}
        selectedOptionsSector={selectedOptionsSector}
        setCreatAd_Five={setCreatAd_Five}
        errorCreatAd_Five={errorCreatAd_Five}
        handleInputChange={handleInputChange}
        handleSectorChange={handleSectorChange}
        filterConfig={filterConfig}
        mystyle={mystyle}
        t={t}
        setErrorCreatAd_Five={setErrorCreatAd_Five}
        error={error}
        setError={setError}
        newAdvertisementId={newAdvertisementId}
        setStepParam={setStepParam}
        ad_type={ad_type}
      />
    )
  } else {
    return (
      <>
        <Header />
        <div className='seekng_job_sec create-an-ad'>
          <div className='container'>
            <form>
              <div className='seekng_job_title_note'>
                <div className='seekng_job_title'>
                  <h2>
                    {setStepParam != null
                      ? "Modifica l'annuncio"
                      : props?.ad_type === 'Agente'
                      ? 'Inserisci un nuovo annuncio per la ricerca di agenti'
                      : 'Inserisci un nuovo annuncio per la ricerca di dipendenti'}
                  </h2>
                  <h6>Informazioni sulla retribuzione</h6>
                </div>
              </div>
              <div className='seeking_job_inner'>
                <div className='seeking_job_form'>
                  <div className='seeking_form_left'>
                    <div className='row g-3'>
                      <div className='col-12'>
                        <label className='form-label d-none d-lg-block'>
                          &nbsp;
                        </label>
                        <div className='switch-btn-wrap'>
                          <strong>Retribuzione fissa</strong>
                          <div
                            className='switch-btn orange'
                            onClick={() => {
                              setCreatAd_Five(prevstate => ({
                                ...prevstate,
                                range: !CreatAd_Five?.range
                              }))
                              CreatAd_Five?.range === true &&
                                setErrorCreatAd_Five(preverr => ({
                                  ...preverr,
                                  range: true,
                                  fixedAmount: '',
                                  minAmount: '',
                                  maxAmount: ''
                                }))
                              if (CreatAd_Five?.range === false) {
                                setCreatAd_Five(prevstate => ({
                                  ...prevstate,
                                  fixedAmount: '',
                                  minAmount: '',
                                  maxAmount: ''
                                }))
                              }
                              if (
                                CreatAd_Five?.range === false ||
                                CreatAd_Five?.range === true
                              ) {
                                setErrorCreatAd_Five(preverr => ({
                                  ...preverr,
                                  fixedAmount: ''
                                }))
                              }
                            }}
                          >
                            <input
                              type='checkbox'
                              checked={CreatAd_Five?.range}
                              readOnly
                            />
                            <span></span>
                          </div>
                          <strong>intervallo retributivo</strong>
                        </div>
                      </div>
                      {/* {CreatAd_Five?.range === false &&
                                            errorCreatAd_Five?.range && (
                                                <div style={mystyle}>
                                                    {errorCreatAd_Five?.range}
                                                </div>
                                            )} */}
                    </div>
                  </div>
                  <div className='seeking_form_right d-none d-lg-block'>
                    <div className='row g-3'>
                      {CreatAd_Five?.range === true ? (
                        <>
                          <div className='col-6'>
                            <label className='form-label'>
                              Retribuzione minima
                            </label>
                            <input
                              className={`form-control ${
                                CreatAd_Five?.minAmount?.length === 0 &&
                                errorCreatAd_Five?.minAmount
                                  ? 'error'
                                  : ''
                              }`}
                              placeholder='Retribuzione minima '
                              value={CreatAd_Five?.minAmount || ''}
                              name='minAmount'
                              ref={minAmountRef}
                              onChange={e => handleInputChange(e, 'minAmount')}
                              onBlur={validateForMinAmount}
                            />
                            {CreatAd_Five?.minAmount?.length === 0 &&
                              errorCreatAd_Five?.minAmount && (
                                <div style={mystyle}>
                                  {errorCreatAd_Five?.minAmount}
                                </div>
                              )}
                          </div>
                          <div className='col-6'>
                            <label className='form-label'>
                              Retribuzione massima
                            </label>
                            <input
                              className={`form-control ${
                                CreatAd_Five?.maxAmount?.length === 0 &&
                                errorCreatAd_Five?.maxAmount
                                  ? 'error'
                                  : ''
                              }`}
                              placeholder='Retribuzione massima'
                              value={CreatAd_Five?.maxAmount || ''}
                              name='maxAmount'
                              ref={maxAmountRef}
                              onChange={e => handleInputChange(e, 'maxAmount')}
                              onBlur={validateForMaxAmount}
                            />
                            {CreatAd_Five?.maxAmount?.length === 0 &&
                              errorCreatAd_Five?.maxAmount && (
                                <div style={mystyle}>
                                  {errorCreatAd_Five?.maxAmount}
                                </div>
                              )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='col-12'>
                            <label className='form-label'>
                              Retribuzione fissa
                            </label>
                            <input
                              className={`form-control ${
                                CreatAd_Five?.fixedAmount?.length === 0 &&
                                errorCreatAd_Five?.fixedAmount
                                  ? 'error'
                                  : ''
                              }`}
                              value={CreatAd_Five?.fixedAmount || ''}
                              placeholder='Retribuzione fissa'
                              name='fixedAmount'
                              ref={fixedAmountRef}
                              onChange={e =>
                                handleInputChange(e, 'fixedAmount')
                              }
                              onBlur={validateForFixAmount}
                            />
                            {CreatAd_Five?.fixedAmount?.length === 0 &&
                              errorCreatAd_Five?.fixedAmount && (
                                <div style={mystyle}>
                                  {errorCreatAd_Five?.fixedAmount}
                                </div>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='seeking_form_left mt-4'>
                    <div className='row g-3'>
                      <div className='col-12'>
                        <label className='form-label'>
                          Ricorrenza retribuzione (settimanale, mensile, ecc.)
                        </label>
                        <input
                          className={`form-control ${
                            CreatAd_Five?.reccurency?.length === 0 &&
                            errorCreatAd_Five?.salary_recurrence
                              ? 'error'
                              : ''
                          }`}
                          placeholder='Ricorrenza retribuzione'
                          value={CreatAd_Five?.reccurency || ''}
                          name='reccurency'
                          ref={salaryRecurrenceRef}
                          onChange={e => handleInputChange(e, 'reccurency')}
                          onBlur={validateForSalaryRecurrence}
                        />
                        {CreatAd_Five?.reccurrency?.length === 0 &&
                          errorCreatAd_Five?.salary_recurrence && (
                            <div style={mystyle}>
                              {errorCreatAd_Five?.salary_recurrence}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className='seeking_form_right mt-4 d-none d-lg-block'>
                    <div className='row g-3'></div>
                  </div>
                  <AdditionalWages
                    CreatAd_Five={CreatAd_Five}
                    errorCreatAd_Five={errorCreatAd_Five}
                    additionalWagesData={additionalWagesData}
                    validateAdditionalWages={validateAdditionalWages}
                    handleCreateFiveAdditionalWages={
                      handleCreateFiveAdditionalWages
                    }
                    additionalWagesRef={additionalWagesRef}
                    mystyle={mystyle}
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    selectedOptionsAdditionalWages={
                      selectedOptionsAdditionalWages
                    }
                    filterConfig={filterConfig}
                  />
                  <Benefits
                    CreatAd_Five={CreatAd_Five}
                    errorCreatAd_Five={errorCreatAd_Five}
                    benefitsData={benefitsData}
                    validateBenefit={validateBenefit}
                    handleCreateFiveBenefits={handleCreateFiveBenefits}
                    benefitRef={benefitRef}
                    mystyle={mystyle}
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    selectedOptionsBenefits={selectedOptionsBenefits}
                    filterConfig={filterConfig}
                  />
                  <div className='seeking_form_left mt-4'>
                    <div className='row g-3'>
                      <div className='col-12'>
                        <SectorLevel
                          selectedOptionsSector={selectedOptionsSector}
                          handleSectorLevelChange={handleSectorLevelChange}
                          handleProductChange={handleProductChange}
                          CreatAd_Five={CreatAd_Five}
                          errorCreatAd_Five={errorCreatAd_Five}
                          validateSector={validateSector}
                          sectorRef={sectorRef}
                          errorSector={errorSector}
                          handleSubSectorLevelChange={
                            handleSubSectorLevelChange
                          }
                          validateSubSector={validateSubSector}
                          subsectorRef={subsectorRef}
                          errorSubSector={errorSubSector}
                          mystyle={mystyle}
                          t={t}
                          adType={ad_type}
                          prodottiRef={prodottiRef}
                          selectedProduct={selectedProduct}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='seeking_form_btn'>
                <BackButton prevStep={prevStep} />
                <NextButton nextStep={nextStep} />
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default CreaAnnuncioFive
