import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  requestCompanyDetails,
  requestGetJobListUserId
} from '../../Redux/actions'
import { useNavigate } from 'react-router-dom'
import PermissionAdvertisment from './PermissionAdvertisment'
import loaderLogo from '../../images/Iphone-spinner-2.gif'
import { toast } from 'react-toastify'
function AdvertisementList (props) {
  const [jobList, setJobList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const navigate = useNavigate()
  const [jobListLoader, setJobListLoader] = useState(true)
  const [companyId, setCompanyId] = useState(null)
  const [userId, setUserId] = useState(null)

  useEffect(() => {
    props.candidate.GetJobListUserId = undefined
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      props.requestCompanyDetails({ data: { token } })
    }
  }, [])

  useEffect(() => {
    const getCompanyData = props.company.getCompanyData
    if (getCompanyData !== undefined) {
      if (getCompanyData.data?.Status === 'Success') {
        const CompanyDetails = getCompanyData?.data?.Message || {}

        if (CompanyDetails.flag_company) {
          setCompanyId(CompanyDetails?.id)
        } else {
          setUserId(CompanyDetails?.user)
        }
      } else {
        props.company.getCompanyData = undefined
      }
    } else {
      props.company.getCompanyData = undefined
    }
  }, [props.company.getCompanyData])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (companyId) {
      const requestData = {
        company__id: companyId
      }

      if (token) {
        props.requestGetJobListUserId({ data: requestData, token })
      }
    } else if (userId) {
      const requestData = {
        spokesman_id: userId
      }

      if (token) {
        props.requestGetJobListUserId({ data: requestData, token })
      }
    }
  }, [companyId])

  useEffect(() => {
    const jobListUserIdData = props?.candidate?.GetJobListUserId
    if (jobListUserIdData !== undefined) {
      setJobListLoader(true)
      if (jobListUserIdData.data.Status === 'Success') {
        const jobListUserIdDetails = jobListUserIdData?.data?.Message || []
        if (
          Array.isArray(jobListUserIdDetails) &&
          jobListUserIdDetails.length === 0
        ) {
          setJobList([])
        } else {
          setJobList(jobListUserIdDetails)
        }
        setJobListLoader(false)
        props.candidate.GetJobListUserId = undefined
      } else {
        setJobListLoader(false)
        toast.error(jobListUserIdData.data.Message, {
          theme: 'colored',
          autoClose: 8000
        })
        navigate('/company/create-adv')
        props.candidate.GetJobListUserId = undefined
      }
    }
  }, [props.candidate.GetJobListUserId])

  const formatDate = dateString => {
    if (!dateString) return ''
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  const formatDate1 = dateString => {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) return ''
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
  }

  const filteredJobList = jobList.filter(job => {
    const jobTitle = job?.job_title ? job.job_title.toLowerCase() : ''
    const creationDate = job?.ad_creation_date
      ? formatDate(job.ad_creation_date).toLowerCase()
      : ''
    const expirationDate = job?.ad_exp_date
      ? formatDate1(job.ad_exp_date).toLowerCase()
      : ''
    const query = searchQuery.toLowerCase()

    return (
      jobTitle.includes(query) ||
      creationDate.includes(query) ||
      expirationDate.includes(query)
    )
  })
  console.log(filteredJobList)

  const redirectOnDetails = id => {
    navigate('/company/advertisement-details/' + id)
  }

  const redirectOnAdvertisementCreate = () => {
    navigate('/company/create-adv')
  }

  const redirectToCreaAnnuncio7_bis = add_Id => {
    // navigate("/company/CreaAnnuncio7_bis");
    navigate(`/company/CreaAnnuncio7_bis?adId=${add_Id}`, {
      state: { redirect: 'company/advertisement-list' }
    })
  }

  const handleOnApplyCandidateCount = add_Id => {
    // console.log("redirect to candidate counts");
    // // navigate("/company/advertisement-details/" + id);
    navigate(`/company/advertisement-list/${add_Id}/candidates `)
  }

  return (
    <>
      <Header />
      <div className='advertisement_listing'>
        <div className='container'>
          <div className='advertisement_title'>
            <h3>Lista annunci inseriti</h3>
          </div>
          <div
            className='advertisement_listing_inner position-relative'
            style={{ minHeight: 'calc(100vh - 280px)' }}
          >
            {jobListLoader ? (
              <div className='loader'>
                <div className='loader_inner'>
                  <img src={loaderLogo} />
                </div>
              </div>
            ) : (
              <>
                <div className='search_ads'>
                  <form>
                    <div className='row g-4'>
                      <div className='form-group col-md-6 col-12'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Ricerca annunci'
                          id='search'
                          name='search'
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div className='advertisement_table table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope='col'>Data di pubblicazione</th>
                        <th scope='col'>Titolo</th>
                        <th scope='col'>Candidature ricevute</th>
                        <th scope='col'>Scadenza annuncio</th>
                        <th scope='col'>Stato annuncio</th>
                        <th scope='col'>Sponsorizzato</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredJobList.length > 0 &&
                        filteredJobList
                          .filter(value => value.published_id == null)
                          .sort((a, b) => {
                            return (
                              new Date(b.ad_creation_date) -
                              new Date(a.ad_creation_date)
                            )
                          })
                          .map((item, index) => (
                            <tr key={index}>
                              <td>
                                <p className='publication_date'>
                                  {formatDate(item.ad_creation_date)}
                                </p>
                              </td>
                              <td>
                                <a
                                  className='title'
                                  onClick={() => redirectOnDetails(item.id)}
                                >
                                  {item.job_title
                                    ? item.job_title
                                    : item.franchisor?.brand}
                                </a>
                              </td>

                              <td>
                                {' '}
                                {item?.candidates?.length > 0 ? (
                                  <p
                                    className='application_count'
                                    onClick={() =>
                                      handleOnApplyCandidateCount(item.id)
                                    }
                                  >
                                    {item?.candidates?.length}{' '}
                                  </p>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td>
                                <p className='announcement_deadline'>
                                  {item.sponsor_due_date
                                    ? formatDate1(item.sponsor_due_date)
                                    : item.application_sending_deadline
                                    ? formatDate1(
                                        item.application_sending_deadline
                                      )
                                    : ''}
                                </p>
                              </td>
                              <PermissionAdvertisment item={item} />
                              <td>
                                <div className='sponsored'>
                                  {item.ad_type?.type === 'Agente' ||
                                  item.ad_type?.type === 'Employee' ? (
                                    item.is_sponsored === true &&
                                    item.published === true ? (
                                      <div className='sponsored'>
                                        <p>Sì - in corso</p>
                                      </div>
                                    ) : item.is_sponsored === false &&
                                      item.have_been_sponsored === false ? (
                                      <div
                                        className='not-sponsored'
                                        onClick={() =>
                                          redirectToCreaAnnuncio7_bis(item.id)
                                        }
                                      >
                                        <p>Sponsorizza</p>
                                      </div>
                                    ) : item.is_sponsored === false &&
                                      item.have_been_sponsored === true ? (
                                      <>
                                        <div className='yes_no'>
                                          <p>Sì - conclusa</p>
                                        </div>
                                        <div
                                          className='renew'
                                          onClick={() =>
                                            redirectToCreaAnnuncio7_bis(item.id)
                                          }
                                        >
                                          <p>Rinnova</p>
                                        </div>
                                      </>
                                    ) : ''(item.tokens_spent !== null) ? (
                                      <div className='token'>
                                        <p>{item.tokens_spent} Token spesi</p>
                                      </div>
                                    ) : (
                                      ''
                                    )
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => ({
  company: state.company,
  candidate: state.candidate
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestCompanyDetails,
      requestGetJobListUserId
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementList)
