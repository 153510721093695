import React, { useEffect } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'
import Ckeditor from './Ckeditor'
import Select, { createFilter } from 'react-select'
import BackButton from '../common/BackButton'
import NextButton from '../common/NextButton'

const CreaAnnuncioThree = props => {
  const {
    nextStep,
    prevStep,
    CreatAd_Three,
    setCreatAd_Three,
    validateFormthirdJobTitle,
    errorCreatAd_Three,
    handleOnCreatAdThird,
    // handleOnPostionTitle,
    // validateFormthirdPosition,
    validatethirdFormFile,
    validateFormThirdCkeditor,
    // positionErrorRef,
    fileErrorRef,
    isClearable,
    isSearchable,
    filterConfig,
    mystyle,
    t,
    jobList,
    error,
    setError,
    newAdvertisementId,
    setStepParam
  } = props

  useEffect(() => {
    const focusField = () => {
      if (errorCreatAd_Three.selectedFile && fileErrorRef?.current) {
        fileErrorRef.current.focus()
      }
    }

    if (error) {
      focusField()
      setError(false)
    }
  }, [error, errorCreatAd_Three, fileErrorRef])

  return (
    <>
      <Header />
      <div className='seekng_job_sec create-an-ad'>
        <div className='container'>
          <form>
            <div className='seekng_job_title_note'>
              <div className='seekng_job_title'>
                <h2>
                  {setStepParam != null
                    ? "Modifica l'annuncio"
                    : props?.ad_type == 'Agente'
                    ? 'Inserisci un nuovo annuncio per la ricerca di agenti'
                    : 'Inserisci un nuovo annuncio per la ricerca di dipendenti'}
                </h2>
                <h6>Descrizione</h6>
              </div>
            </div>
            <div className='seeking_job_inner'>
              <div className='seeking_job_form'>
                <div className='seeking_form_left'>
                  <div className='row g-3'>
                    <div className='col-12'>
                      <label className='form-label'>
                        Posizione ricercata (Titolo dell’annuncio)
                      </label>

                      {/* <input
                        type="text"
                        name="job_title"
                        className="form-control"
                        placeholder="Lorem ipso"
                        value={CreatAd_Three.job_title || ""}
                        readOnly
                      /> */}
                      <Select
                        className={`form-control select_dropdown  ${
                          CreatAd_Three.job_title.length === 0 &&
                          errorCreatAd_Three.job_title
                            ? 'error'
                            : ''
                        }`}
                        value={
                          CreatAd_Three.job_title
                            ? {
                                label: `${CreatAd_Three.job_title}`,
                                value: `${CreatAd_Three.job_title}`
                              }
                            : ''
                        }
                        onChange={value => {
                          setCreatAd_Three(prev => ({
                            ...prev,
                            job_title: value?.label ? value?.label : ''
                          }))
                        }}
                        onBlur={validateFormthirdJobTitle}
                        options={jobList}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        placeholder='Seleziona una posizione'
                        filterOption={createFilter(filterConfig)}
                      />
                      {CreatAd_Three.job_title.length === 0 &&
                        errorCreatAd_Three?.job_title && (
                          <div style={mystyle}>
                            {errorCreatAd_Three.job_title}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className='seeking_form_right'>
                  <div className='row g-3'>
                    <div className='col-12'>
                      <label className='form-label'>Logo Azienda</label>
                      <div className='input-group custom-file-button'>
                        <label
                          className={`input-group-text file-button ${
                            errorCreatAd_Three.selectedFile ? 'error' : ''
                          }`}
                          htmlFor='companyLogoUpload'
                        >
                          Scegli file
                        </label>
                        <input
                          type='file'
                          accept='.jpg,.png'
                          className={`form-control ${
                            errorCreatAd_Three.selectedFile ? 'error' : ''
                          }`}
                          name='selectedFile'
                          id='companyLogoUpload'
                          onBlur={validatethirdFormFile}
                          ref={fileErrorRef}
                          placeholder='Nessun file scelto'
                          onChange={handleOnCreatAdThird}
                        />
                      </div>
                      <div className='file-name'>
                        {CreatAd_Three.selectedFile ? (
                          CreatAd_Three.selectedFile.name
                        ) : CreatAd_Three.picture ? (
                          <a href={CreatAd_Three.picture} target='_blank'>
                            Vedi logo caricato
                          </a>
                        ) : (
                          ''
                        )}
                      </div>
                      {errorCreatAd_Three.selectedFile && (
                        <div style={mystyle}>
                          {errorCreatAd_Three.selectedFile}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='seeking_form_full mt-30'>
                  <div className='row g-3'>
                    <div className='col-12'>
                      <label className='form-label'>Descrizione lavoro</label>
                      <Ckeditor
                        className={`form-control ${
                          errorCreatAd_Three.desc ? 'error' : ''
                        }`}
                        name='desc'
                        desc={CreatAd_Three.desc || ''}
                        setDesc={desc =>
                          handleOnCreatAdThird({
                            target: { name: 'desc', value: desc }
                          })
                        }
                        onBlur={validateFormThirdCkeditor}
                      />
                      {errorCreatAd_Three.desc && (
                        <div style={mystyle}>{errorCreatAd_Three.desc}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='seeking_form_btn'>
              <BackButton prevStep={prevStep} />
              <NextButton nextStep={nextStep} />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default CreaAnnuncioThree
