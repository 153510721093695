import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestGetCandidate, userLogout } from "../../Redux/actions";
import Facebook from "../../images/facebook.png";
import Google from "../../images/google.png";
import LinkedIn from "../../images/LinkedIn.png";
import Logo from "../../images/logo.png";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config";
import { toast } from "react-toastify";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { useNavigateToRegisterDestination } from "../../utils/routing";

function SocialRegister(props) {
  const { setShowModal } = props;
  const navigate = useNavigate();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const navigateToRegisterDestination = useNavigateToRegisterDestination();

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const redirectToRegister = () => {
    if (!isCheckboxChecked) {
      toast.error("Accetta la Privacy Policy per continuare.", {
        theme: "colored",
        autoClose: 8000,
      });
      return;
    } else {
      // navigate('/register');
      //   navigate("/candidate/register");
      console.log(location.pathname, "location.pathname");

      if (
        location.pathname === "/job-finder" ||
        location.pathname === "/jobs-list"
      ) {
        navigate("/candidate/register");
      } else if (
        location.pathname === "/agent/opportunity-finder" ||
        location.pathname === "/agent/opportunities-list"
      ) {
        navigate("/agent/register");
      } else if (
        location.pathname === "/agent/search" ||
        location.pathname === "/company/candidate-finder" ||
        location.pathname === "/company/candidate-jobs-list"
      ) {
        navigate("/company/register");
      } else if (
        location.pathname === "/franchisor/search" ||
        location.pathname === "/franchisor/search2" ||
        location.pathname === "/franchisor/search-results"
      ) {
        navigate("/franchisor/register");
      } else {
        navigate("/register");
      }

      //navigateToRegisterDestination();
    }
  };

  const redirectToLogin = () => {
    setShowModal("login");
  };

  const handleGoogleRegister = async () => {
    if (!isCheckboxChecked) {
      toast.error("Accetta la Privacy Policy per continuare.", {
        theme: "colored",
        autoClose: 8000,
      });
      return;
    }

    try {
      const googleProvider = new GoogleAuthProvider();
      const res = await signInWithPopup(auth, googleProvider);
      
      if (res && res.user) {
        const email = res.user.email;
        const accessToken = res.user.accessToken;
        const social_uid = res.user.uid;
        localStorage.setItem("social_uid", social_uid);
        
        console.log("Google Auth Data:", {
          email,
          social_uid,
          accessToken: accessToken.substring(0, 10) + "..."
        });

        if (email) {
          await props.requestGetCandidate({
            data: { username: email },
            token: accessToken,
          });

          let candidateDetails;
          for (let i = 0; i < 5; i++) {
            candidateDetails = props.candidate.GetCandidateData;
            if (candidateDetails !== undefined) break;
            await new Promise((resolve) => setTimeout(resolve, 5000));
          }
          console.log(candidateDetails, "candidateDetailsGoogle");
          if (candidateDetails) {
            if (candidateDetails?.data?.Status === "Success") {
              const updateData = candidateDetails.data.Message[0] || {};

              if (updateData?.username) {
                toast.error("L'utente esiste già", {
                  theme: "colored",
                  autoClose: 8000,
                });
              }
            } else {
              // toast.success("Registrato con successo", {
              //   theme: "colored",
              //   autoClose: 8000,
              // });
              if (
                location.pathname === "/job-finder" ||
                location.pathname === "/jobs-list"
              ) {
                //navigate("/candidate/register");
                navigate("/candidate/register", { 
                  state: { 
                    email, 
                    accessToken, 
                    social_uid,
                    timestamp: Date.now() // Add timestamp to force state update
                  } 
                });
              } else if (
                location.pathname === "/agent/opportunity-finder" ||
                location.pathname === "/agent/opportunity-list"
              ) {
                
                navigate("/agent/register", { 
                  state: { 
                    email, 
                    accessToken, 
                    social_uid,
                    timestamp: Date.now() // Add timestamp to force state update
                  } 
                });
              } else if (
                location.pathname === "/agent/search" ||
                location.pathname === "/company/candidate-finder" ||
                location.pathname === "/company/candidate-jobs-list"
              ) {
                
                navigate("/company/register", { 
                  state: { 
                    email, 
                    accessToken, 
                    social_uid,
                    timestamp: Date.now() // Add timestamp to force state update
                  } 
                });
              } else {
                navigate("/register", { 
                  state: { 
                    email, 
                    accessToken, 
                    social_uid,
                    timestamp: Date.now() // Add timestamp to force state update
                  } 
                });
              }
              
            }
          } else {
            toast.error("Impossibile recuperare i dettagli utente", {
              theme: "colored",
              autoClose: 8000,
            });
          }
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      toast.error("Failed to register with Google", {
        theme: "colored",
        autoClose: 8000,
      });
      console.error("Error during Google registration:", error);
    }
  };

  const handleFaceBookRegister = async () => {
    if (!isCheckboxChecked) {
      toast.error("Accetta la Privacy Policy per continuare.", {
        theme: "colored",
        autoClose: 8000,
      });
      return;
    }

    try {
      const facebookProvider = new FacebookAuthProvider();
      const res = await signInWithPopup(auth, facebookProvider);
      if (res) {
        toast.success("Autenticato correttamente", {
          theme: "colored",
          autoClose: 8000,
        });
        navigate("/register");
      } else {
        navigate("/");
      }
    } catch (error) {
      toast.error("Impossibile registrarsi con Facebook", {
        theme: "colored",
        autoClose: 8000,
      });
      console.log("Error during Facebook registration:", error);
      navigate("/");
    }
  };

  const handleLinkedInLogin = () => {
    if (!isCheckboxChecked) {
      toast.error("Accetta la Privacy Policy per continuare.", {
        theme: "colored",
        autoClose: 8000,
      });
      return;
    }
    const linkedInAuthUrl = "https://www.linkedin.com/oauth/v2/authorization";
    const params = {
      response_type: "code",
      client_id: "77s58qobsiim6i",
      redirect_uri: `${window.location.origin}/home?path=${window.location.pathname}`,
      scope: "profile email openid",
    };

    const queryString = Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    window.location.href = `${linkedInAuthUrl}?${queryString}`;
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  return (
    <div className="registration_social">
      <div className="registration_social_inner">
        <div className="main_logo">
          <Link to="/">
            <img src={Logo} alt="Main Logo" />
          </Link>
        </div>

        <div className="registration_social_title">
          <h1>La nuova dimensione del lavoro </h1>
          <p>Registrati per accedere alla tua dimensione </p>
        </div>

        <div className="registration_btn">
          <button
            type="submit"
            className="btn registration_btn_inner"
            onClick={redirectToRegister}
            // disabled={!isCheckboxChecked}
          >
            Registrati con l’email
          </button>
        </div>

        <div className="registration_social_btn">
          <button
            type="submit"
            className="btn social_registration_btn"
            onClick={handleFaceBookRegister}
            // disabled={!isCheckboxChecked}
          >
            <img src={Facebook} alt="Facebook" />
            Registrati con Facebook
          </button>
          <button
            type="submit"
            className="btn social_registration_btn"
            onClick={handleGoogleRegister}
            // disabled={!isCheckboxChecked}
          >
            <img src={Google} alt="Google" />
            Registrati con Google
          </button>
          <button
            type="submit"
            className="btn social_registration_btn"
            onClick={handleLinkedInLogin}
            // disabled={!isCheckboxChecked}
          >
            <img src={LinkedIn} alt="LinkedIn" />
            Registrati con LinkedIn
          </button>
        </div>

        <div className="selection_checkboxs">
          <div className="selection_checkbox">
            <input
              type="checkbox"
              id="e_mail_consent"
              name="e_mail_consent"
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
            />
            <label>
              Accetto i{" "}
              <a
                href="https://www.iubenda.com/privacy-policy/51986058"
                target="_blank"
                rel="noopener noreferrer"
              >
                Termini di servizio
              </a>
              <br />e confermo di aver letto l’
              <a
                href="https://www.iubenda.com/privacy-policy/51986058/cookie-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Informativa Privacy.
              </a>
            </label>
          </div>
        </div>

        <div className="terms_privacy">
          <p>
            Sei già registrato?&nbsp;
            <a onClick={redirectToLogin}>Accedi</a>
          </p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestGetCandidate,
      userLogout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SocialRegister);
