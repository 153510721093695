// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// const DropdownNavigation = () => {
//   const navigate = useNavigate();

//   const handleChange = (event) => {
//     const { value } = event.target;
//     navigate(value);
//   };

//   return (
//     <div className="dropdown_navigation">
//       <select onChange={handleChange}>
//         <option value="">Select</option>
//         <option value="/home">Home</option>
//         <option value="/candidate/profile">profile</option>
//       </select>
//     </div>
//   );
// };

// export default DropdownNavigation;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useAppNavigation } from "../utils/routing";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { userLogout } from "../Redux/actions";

const DropdownNavigation = (props) => {
  const [quotationsO, setQuotationsO] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState();

  const handleNavigate = (path) => {
    if (path === "/company/create-adv") {
      navigate(path, {
        state: {
          ad_id: null,
        },
      });
    } else {
      navigate(path);
    }
  };

  const { t } = useTranslation();

  const { navigateTo } = useAppNavigation();

  useEffect(() => {
    const storedUserType = localStorage.getItem("userType");
    //const decodedUserType = storedUserType ? (storedUserType) : "";
    setUserType(storedUserType);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
  }, []);

  const getQuotations = async () => {
    const id = props.candidate?.loginData?.data?.Message.id;
    const token = localStorage.getItem("token");
    if (!id) return;
    const response = await axios
      .get(`${process.env.REACT_APP_API_HOST}auth/register/?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        console.log(err, "err register");
        if (err.status === 403) {
          toast.error("Per favore riprova", {
            theme: "colored",
            autoClose: 8000,
          });
          //props.userLogout();
          return;
        }
      });
    setUserDetails(response?.data?.Message?.[0]);
    const serializedData = (
      response?.data?.Message?.[0]?.quotations_o ?? []
    ).map((quotation) => ({
      accepted: quotation.accepted,
      id: quotation.id,
      price: quotation.price,
      text: quotation.text,
      by_name: quotation.offer.created_by.name,
      by_surname: quotation.offer.created_by.surname,
      telefono: quotation.offer.created_by.cellphone,
      by_id: quotation.offer.created_by.id,
      email: quotation.offer.created_by.email,
      date: quotation.offer.created_at,
    }));
    setQuotationsO(serializedData);
    setQuotations(response?.data?.Message?.[0]?.quotations ?? []);
  };

  useEffect(() => {
    getQuotations();
  }, [props.candidate?.loginData?.data?.Message.id]);

  return (
    <>
      {/* <div className="dropdown_navigation">
        <div className="dropdown">
          <a
            className="btn dropdown-toggle"
            href="/"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            Select
          </a>
          <ul className="dropdown-menu">
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleNavigate("/home")}
              >
                Home
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleNavigate("/candidate/profile")}
              >
                Profile
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleNavigate("/update-resume")}
              >
                Resume
              </button>
            </li>
          </ul>
        </div>
      </div> */}

      {/* <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Menu
        </a>
        <ul className="dropdown-menu">
          <li>
            <button
              className="dropdown-item"
              onClick={() => handleNavigate("/home")}
            >
              Home
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={() => handleNavigate("/candidate/profile")}
            >
              Profile
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={() => handleNavigate("/update-resume")}
            >
              Resume
            </button>
          </li>
        </ul>
      </li> */}

      <li className="nav-item">
        <a className="nav-link">Menu</a>
        <ul className="sub-menu">
          <li>
            <button
              className="dropdown-item"
              onClick={() => handleNavigate("/")}
            >
              Home
            </button>
          </li>
          {/* {quotations.length > 0 ? (
            <li>
              <button
                className="dropdown-item"
                onClick={() => navigateTo("/received-quotations")}
              >
                Preventivi Ricevuti
              </button>
            </li>
          ) : (
            <></>
          )} */}
          {quotationsO.length > 0 ? (
            <li>
              <button
                className="dropdown-item"
                onClick={() =>
                  navigateTo("/opportunities", {
                    params: { ["sent-prev"]: true, sent: null },
                  })
                }
              >
                Preventivi Inviati
              </button>
            </li>
          ) : (
            <></>
          )}
          {(() => {
            if (props.agent.loginData?.data.Message.flag_agent) {
              return (
                <>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/agent/profile")}
                    >
                      {t("header.dropdown.profile")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/resume")}
                    >
                      {t("header.dropdown.resume")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/saved-ads")}
                    >
                      Annunci salvati
                    </button>
                  </li>
                </>
              );
            }

            if (props.agent.loginData?.data.Message.flag_candidate) {
              return (
                <>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/candidate/profile")}
                    >
                      {t("header.dropdown.profile")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/saved-ads")}
                    >
                      Annunci salvati
                    </button>
                  </li>
                </>
              );
            }

            if (props.agent.loginData?.data.Message.flag_franchisor) {
              return (
                <>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        navigateTo(
                          props.agent.loginData?.data?.Message?.company_id
                            ?.length
                            ? "/candidate/profile"
                            : "/franchisor/register"
                        )
                      }
                    >
                      {t("header.dropdown.profile")}
                    </button>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          handleNavigate("/company/advertisement-list")
                        }
                      >
                        {t("header.dropdown.advertisement")}
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => handleNavigate("/company/PackageToken")}
                      >
                        {t("header.dropdown.packages")}
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          handleNavigate("/company/Creditmanagement")
                        }
                      >
                        {t("header.dropdown.credit-management")}
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          handleNavigate("/company/candidate-finder")
                        }
                      >
                        {t("header.dropdown.cv-finder")}
                      </button>
                    </li>

                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => handleNavigate("/franchisor/register2")}
                      >
                        {t("header.dropdown.crea-annuncio")}
                      </button>
                    </li>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/saved-ads")}
                    >
                      CV salvati
                    </button>
                  </li>
                </>
              );
            }

            if (props.agent.loginData?.data.Message.flag_artisan) {
              return (
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => navigateTo("/company/profile")}
                  >
                    {t("header.dropdown.profile")}
                  </button>
                </li>
              );
            }

            if (props.agent.loginData?.data.Message.flag_private) {
              return (
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => navigateTo("/agent/profile")}
                  >
                    {t("header.dropdown.profile")}
                  </button>
                </li>
              );
            }

            if (
              props.agent.loginData?.data.Message.flag_employee ||
              localStorage.getItem("userType") == "employee"
            ) {
              return (
                <>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/update-resume")}
                    >
                      {t("header.dropdown.resume")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/candidate/profile")}
                    >
                      {t("header.dropdown.profile")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/saved-ads")}
                    >
                      Annunci salvati
                    </button>
                  </li>
                </>
              );
            }

            if (
              props.candidate.loginData?.data.Message.flag_professional ||
              props.candidate.loginData?.data.Message.flag_artisan
            ) {
              return (
                <>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        navigateTo(
                          props.candidate.loginData?.data?.Message?.company_id
                            ?.length
                            ? "/candidate/profile"
                            : "/company/profile"
                        )
                      }
                    >
                      {t("header.dropdown.profile")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/professional-profile")}
                    >
                      Profilo Professionale
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        handleNavigate("/company/candidate-finder")
                      }
                    >
                      {t("header.dropdown.cv-finder")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        handleNavigate("/company/advertisement-list")
                      }
                    >
                      {t("header.dropdown.advertisement")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/company/create-adv")}
                    >
                      {t("header.dropdown.crea-annuncio")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        navigate("/company/create-adv", {
                          state: {
                            ad_id: null,
                            ad_type: "agent",
                          },
                        })
                      }
                    >
                      Crea Annuncio Agente
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/saved-ads")}
                    >
                      CV salvati
                    </button>
                  </li>
                </>
              );
            }

            if (
              props.agent.loginData?.data.Message.flag_company &&
              !props.agent.loginData?.data.Message.flag_franchisor
            ) {
              return (
                <>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        navigateTo(
                          props.agent.loginData?.data?.Message?.company_id
                            ?.length
                            ? "/candidate/profile"
                            : "/company/profile"
                        )
                      }
                    >
                      {t("header.dropdown.profile")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        handleNavigate("/company/candidate-finder")
                      }
                    >
                      {t("header.dropdown.cv-finder")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        handleNavigate("/company/advertisement-list")
                      }
                    >
                      {t("header.dropdown.advertisement")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/company/create-adv")}
                    >
                      {t("header.dropdown.crea-annuncio")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        navigate("/company/create-adv", {
                          state: {
                            ad_id: null,
                            ad_type: "agent",
                          },
                        })
                      }
                    >
                      Crea Annuncio Agente
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => navigateTo("/saved-ads")}
                    >
                      CV salvati
                    </button>
                  </li>
                </>
              );
            }

            return (
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => navigateTo("/candidate/profile")}
                >
                  {t("header.dropdown.profile")}
                </button>
              </li>
            );
          })()}
          {(() => {
            if (
              props.candidate.loginData?.data.Message.flag_company ||
              props.candidate.loginData?.data.Message.flag_professional ||
              props.candidate.loginData?.data.Message.flag_artisan
            ) {
              return (
                <>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleNavigate("/company/PackageToken")}
                    >
                      {t("header.dropdown.packages")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        handleNavigate("/company/Creditmanagement")
                      }
                    >
                      {t("header.dropdown.credit-management")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        navigateTo("/opportunities", {
                          params: {
                            sent: false,
                            ["sent-prev"]: null,
                          },
                        })
                      }
                    >
                      Opportunità ricevute
                    </button>
                  </li>
                </>
              );
            }
          })()}
          {userDetails?.quotations?.length ? (
            <li>
              <button
                className="dropdown-item"
                onClick={() =>
                  navigateTo("/received-quotations", {
                    params: {
                      sent: null,
                      ["sent-prev"]: null,
                    },
                  })
                }
              >
                Preventivi ricevuti
              </button>
            </li>
          ) : (
            <></>
          )}
          {userDetails?.offers_o?.length ? (
            <li>
              <button
                className="dropdown-item"
                onClick={() =>
                  navigateTo("/opportunities", {
                    params: {
                      sent: true,
                      ["sent-prev"]: null,
                    },
                  })
                }
              >
                Servizi richiesti
              </button>
            </li>
          ) : (
            <></>
          )}
        </ul>
      </li>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    agent: state?.agent,
    candidate: state?.candidate,
    company: state?.company,
    franchisor: state?.franchisor,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ userLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DropdownNavigation);
