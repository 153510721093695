// File: /src/ExtraCompanyPages/Preventivo.js
// Created Date: Thursday December 19th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Thursday December 19th 2024 3:30:14 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import FormBuilder from "../Components/FormBuilder";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";

const OPTIONS = {
  price: [
    { label: "Lo definirò in seguito", value: "Lo definirò in seguito" },
    { label: "Prezzo orario", value: "Prezzo orario" },
    { label: "Prezzo totale", value: "Prezzo totale" },
  ],
};

const Preventivo = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [FIELDS, setFields] = useState([
    {
      label: "Prezzo",
      name: "price",
      type: "select",
      required: true,
    },
    {
      label: "Importo",
      name: "amount",
      type: "number",
      step: "0.01",
      placeholder: "0,00",
      required: true,
      display_dependencies: [
        { field: "price", values: ["Prezzo orario", "Prezzo totale"] },
      ],
    },
    {
      label: "Descrizione",
      name: "text",
      type: "text_area",
      required: true,
    },
  ]);

  const onChange = (formData) => {
    setFields((p) => {
      const newFields = [...p];
      for (let i = 0; i < newFields.length; i++) {
        if (i === 1) {
          console.log(formData?.price);
          newFields[i] = {
            ...newFields[i],
            label:
              formData?.price?.[0] === "Prezzo orario"
                ? "Prezzo in €/ora"
                : "Prezzo in € lordi",
            placeholder:
              formData?.price?.[0] === "Prezzo orario"
                ? "Prezzo in €/ora"
                : "€ lordi",
          };
        }
      }
      return newFields;
    });
  };

  const onSubmit = async (formData) => {
    if (!location.state) return;
    if (!location.state.ad)
      return Swal.fire("Errore", "Non è stato trovato un annuncio", "error");
    if (!props.candidate?.loginData?.data?.Message)
      return Swal.fire("Errore", "Non è stato trovato un utente", "error");
    if (!location.state.ad.receiver_id)
      return Swal.fire(
        "Errore",
        "Non è stato trovato un destinatario",
        "error"
      );
    const receiver = location.state.ad.receiver_id;
    const owner = props.candidate?.loginData?.data?.Message?.id;
    if (!owner || !receiver) return;
    const price =
      formData.price[0] === "Lo definirò in seguito"
        ? formData.price[0]
        : Number(formData.amount).toFixed(2);
    let data = {
      receiver,
      owner,
      price:
        formData.price[0] === "Prezzo orario"
          ? `${price} €/ora`
          : formData.price[0] === "Prezzo totale"
          ? `${price} € lordi`
          : price,
      offer: Number(location.state.ad.receiver_id),
      text: formData.text,
    };
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_HOST}wideer/q/`,
        data,
        config
      );
      navigate(-1);
      toast.success("Preventivo inviato con successo", {
        theme: "colored",
        autoClose: 8000,
      });
    } catch (err) {
      toast.error("Si è verificato un errore", {
        theme: "colored",
        autoClose: 8000,
      });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header />
      <Breadcrumbs />
      <div className="container mt-4 h-100">
        <div className="h-100">
          <div className="seekng_job_title mb-4">
            <h2>Crea Preventivo</h2>
          </div>
          <FormBuilder
            fields={FIELDS}
            selectOptions={OPTIONS}
            onChange={onChange}
            onSubmit={onSubmit}
            submitLabel="Invia"
            onBack={handleBack}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { company: state.company, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Preventivo);
