import { client as axios } from "../lib/axios";

export const getlist = (obj) => {
  return axios
    .get("wideer/getLists/")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getlocation = (obj) => {
  return axios
    .get("wideer/getLists/getTopoInfo")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getRegionelist = (obj) => {
  return axios
    .get("wideer/comuni_italiani/elenco/regioni/")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//============= educational level ===============================

export const getEducationlevel = (obj) => {
  return axios
    .get("wideer/getLists/getEducationalLevels")
    .then((response) => {
      // console.log(response)
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//===================== sector ====================================

export const getSectors = (obj) => {
  return axios
    .get("wideer/getLists/getSectors")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const register = (obj) => {
  const data = { ...obj.data };
  return axios
    .post("auth/register/", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const login = (obj) => {
  return axios
    .post("auth/login/", obj.data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response;
    });
};

export const linkedinLogin = (obj) => {
  return axios
    .get("auth/linkedin/", {
      params: obj,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// export const refreshToken = (obj) => {
//   return axios
//     .post("auth/login/refresh/", obj.data)
//     .then((response) => {
//       return response;
//     })
//     .catch((err) => {
//       console.log("Error", err.response.data.message);
//       return err.response;
//     });
// };

export const GetCandidate = (obj) => {
  const data = { ...obj?.data };
  return axios
    .get("auth/register/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${obj?.token}`,
      },
      params: data,
    })
    .then((response) => {
      // console.log(response)
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const candidateProfile = (obj) => {
  const data = {
    username: obj.data.username,
  };
  return axios
    .patch("auth/register/", obj.data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${obj?.token}`,
      },
      params: data,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//====================resume for post =========================

export const candidateresume = (obj) => {
  const formData1 = new FormData();

  if (obj?.data?.resume_file) {
    formData1.append("resume_file", obj.data.resume_file);
  }

  const appendFormData = (data, parentKey = "") => {
    if (data && typeof data === "object" && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        const value = data[key];
        const newKey = parentKey ? `${parentKey}[${key}]` : key;
        if (key !== "resume_file") {
          if (key === "reccomendationLetter" && value instanceof File) {
            formData1.append(newKey, value);
          } else {
            appendFormData(value, newKey);
          }
        }
      });
    } else {
      formData1.append(parentKey, data);
    }
  };

  appendFormData(obj.data);

  return axios
    .post("wideer/createResume/", formData1, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${obj?.token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//====================resume for get =========================

export const GetResumeDetails = (obj) => {
  return axios
    .get("wideer/createResume/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${obj?.token}`,
      },
      params: obj.data,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//====================resume for update =========================

export const UpdateResumeDetails = (obj) => {
  console.log(obj, "obj*******************");
  const formData1 = new FormData();

  if (obj?.data?.resume_file !== undefined) {
    if (obj.data.resume_file === null) {
      formData1.append("resume_file", obj.data.resume_file);
    } else {
      formData1.append("resume_file", obj.data.resume_file);
    }
  }

  const appendFormData = (data, parentKey = "") => {
    if (data && typeof data === "object" && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        const value = data[key];
        const newKey = parentKey ? `${parentKey}[${key}]` : key;
        if (key !== "resume_file") {
          if (key === "reccomendationLetter" && value instanceof File) {
            formData1.append(newKey, value);
          } else {
            appendFormData(value, newKey);
          }
        }
      });
    } else {
      formData1.append(parentKey, data);
    }
  };

  appendFormData(obj.data);

  return axios
    .patch("wideer/createResume/", formData1, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${obj.token}`,
      },
      params: { username: obj.data.username },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//======================== delete resume file ==========================

export const DeleteResumeFile = ({ token }) => {
  return axios
    .delete("wideer/deleteResumeFile/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//======================== delete reccomendation Letter ==========================

export const DeleteLetterFile = ({ token, filename }) => {
  return axios
    .delete("wideer/deleteLetterFile/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: { filename: filename },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const UpdateResumeDetailsOld = (obj) => {
  console.log(obj, "obj*******************");
  const formData1 = new FormData();

  if (obj?.data?.resume_file) {
    formData1.append("resume_file", obj.data.resume_file);
  }

  const appendFormData = (data, parentKey = "") => {
    if (data && typeof data === "object" && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        const value = data[key];
        const newKey = parentKey ? `${parentKey}[${key}]` : key;
        if (key !== "resume_file") {
          if (key === "reccomendationLetter" && value instanceof File) {
            formData1.append(newKey, value);
          } else {
            appendFormData(value, newKey);
          }
        }
      });
    } else {
      formData1.append(parentKey, data);
    }
  };

  appendFormData(obj.data);

  return axios
    .patch("wideer/createResume/", formData1, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: { username: obj.data.username },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// export const candidateresume = (obj) => {
//   return axios
//     .post("/wideer/createResume/", obj.data, {
//     })
//     .then((response) => {
//       console.log(response,"response");
//       return response
//     })
//     .catch((err) => {
//       return err.response;
//     });
// };

// export const candidateresumeCopy = (obj) => {
//   const formData1 = new FormData();
//   formData1.append('resume_file', obj?.data?.resume_file);

//   const appendFormData = (data, parentKey = '') => {
//     if (data && typeof data === 'object' && !(data instanceof File)) {
//       Object.keys(data).forEach(key => {
//         appendFormData(data[key], parentKey ? `${parentKey}[${key}]` : key);
//       });
//     } else {
//       formData1.append(parentKey, data);
//     }
//   };

//   appendFormData(obj.data);

//   for (let pair of formData1.entries()) {
//     console.log(`${pair[0]}: ${pair[1]}`);
//   }

//   return axios
//     .post("/wideer/createResume/", formData1, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     })
//     .then((response) => {
//       console.log(response, "response");
//       return response;
//     })
//     .catch((err) => {
//       return err.response;
//     });
// };

export const GetJobDetails = (obj) => {
  let data = { ...obj.data };

  if (+data["rad"] === 0) {
    delete data["lat"];
    delete data["lon"];
  }
  let token  = obj.data.token;

  if (obj.data.token ) {
    delete data["token"];

  }

  return axios
    .get("wideer/ads/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: data,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      if(err.status === 429) {
        console.log("Too many requests");
      } else {
        return err.response;
      }
    });
};

// export const GetJobList = (obj) => {
//   return axios
//     .get("wideer/ads/", {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       params: obj.data,
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch((err) => {
//       console.log(err, "err");
//       return err.response;
//     });
// };

export const GetJobList = (obj) => {
  const data = { ...obj.data };

  if (+data["rad"] === 0) {
    delete data["lat"];
    delete data["lon"];
  }

  const headers = {
    "Content-Type": "application/json",
  };

  if (obj.token) {
    headers["Authorization"] = `Bearer ${obj?.token}`;
  }

  return axios
    .get("wideer/ads/", {
      headers: headers,
      params: data,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      if(err.status === 429) {
        console.log("Too many requests");
      } else {
        return err.response;
      }
    });
};

export const GetJobListUserId = (obj) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (obj?.token) {
    headers["Authorization"] = `Bearer ${obj?.token}`;
  }

  return axios
    .get("wideer/ads/", {
      headers: headers,
      params: obj.data,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      if(err.status === 429) {
        console.log("Too many requests");
      } else {
        return err.response;
      }
    });
};
//================= candidate apply for job =======================

export const jobApplyNow = (obj) => {
  console.log(obj, "jobApplyNow");
  const headers = {
    "Content-Type": "application/json",
  };

  if (obj.token) {
    headers["Authorization"] = `Bearer ${obj.token}`;
  }

  return axios
    .patch(
      `wideer/ads/ad/${obj.id}/`,

      obj.data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//========================Add answer for jobs ============================

export const AddAnswerForJobs = (obj) => {
  console.log(obj.data, "AddAnswerForJobs");
  const headers = {
    "Content-Type": "application/json",
  };

  if (obj.token) {
    headers["Authorization"] = `Bearer ${obj.token}`;
  }

  return axios
    .post("wideer/answers/save/", obj.data, {
      headers: headers,
    })
    .then((response) => {
      console.log(response, "response");
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

//========================Get answer for jobs ============================

export const GetAnswerForJobs = (obj) => {
  console.log(obj, "GetAnswerForJobs");
  const headers = {
    "Content-Type": "application/json",
  };

  if (obj.token) {
    headers["Authorization"] = `Bearer ${obj.token}`;
  }

  return axios
    .get(`wideer/answers/load/${obj}/${obj}`, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const getProducts = (obj) => {
  return axios
    .get("wideer/getLists/getProducts")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getFilterOptions = (obj) => {
  return axios
    .get("wideer/f/filters")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const saveFilterOptions = (obj) => {
  return axios
    .post("wideer/f/save/", obj.data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${obj.token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getFranchisorResults = (obj) => {
  const data = { ...obj };

  data["ad_type__type"] = "franchising";

  return axios
    .get("wideer/ads", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${obj.data.token}`,
      },
      params: data,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const publishFranchisingAd = (obj) => {
  const data = { ...obj.data };

  data["ad_type__type"] = { type: "franchising" };

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object") {
      for (const [key2, value] of Object.entries(data[key])) {
        formData.append(`${key}[${key2}]`, value);
      }
    } else {
      formData.append(key, data[key]);
    }
  });

  return axios
    .post("wideer/ads/ad/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${obj.token}`,
      },
      params: { ad_type__type: "franchising" },
    })

    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err, "err");
      return err.response;
    });
};

export const searchFranchisor = (obj) => {
  const data = { ...obj.data };
  const token = obj.data.token ? `Bearer ${obj.data.token}` : undefined;
  delete data.token;

  if (!+data.rad) {
    delete data.lat;
    delete data.lon;
    delete data.rad;
  }

  return axios

    .get("wideer/ads/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      params: { ...data, ad_type__type: "franchising" },
    })

    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
