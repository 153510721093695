import React from "react";
import Select, { createFilter } from "react-select";

//================ Basic Register, Candidate Register, Profile ===================//

function ProvinceCommue(props) {
  const {
    data,
    municipalities,
    selectedCity,
    isLoading,
    handleChangeCity,
    cityRef,
    validateCity,
    errorCity,
    onChangeData,
    provinceRef,
    validateProvince,
    errorProvince,
    mystyle,
    t,
  } = props;

  const isClearable = true;
  const isSearchable = true;
  const matchFromStart = true;

  const filterConfig = {
    matchFrom: matchFromStart ? "start" : "any",
  };

  return (
    <>
      {data?.country?.length > 0 && (
        <>
          {data.country === "Italia" ? (
            <>
              <div className="registration_col form-group col-md-6 col-12">
                <label htmlFor="city" className="label">
                  {t("location.city")}, {t("location.province")}
                </label>
                <Select
                  className={`form-control set_max_width select_dropdown ${
                    errorCity ? "error" : ""
                  }`}
                  options={municipalities}
                  // defaultOption={municipalities[0]}
                  value={selectedCity}
                  isLoading={isLoading}
                  isClearable={isClearable}
                  onChange={handleChangeCity}
                  isSearchable={!isLoading && isSearchable}
                  ref={cityRef}
                  onBlur={validateCity}
                  placeholder="Seleziona un Comune"
                  filterOption={createFilter(filterConfig)}
                />
                {errorCity && <div style={mystyle}>{errorCity}</div>}
              </div>
            </>
          ) : (
            <>
              <div className="registration_col form-group col-md-6 col-12">
                <label htmlFor="city" className="label">
                  {t("location.city")}
                </label>
                <input
                  type="text"
                  className={`form-control set_max_width ${
                    errorCity ? "error" : ""
                  }`}
                  placeholder={t("location.city")}
                  id="city"
                  name="city"
                  ref={cityRef}
                  value={data.city || ""}
                  onChange={onChangeData}
                  onBlur={validateCity}
                />
                {errorCity && <div style={mystyle}>{errorCity}</div>}
              </div>
              <div className="registration_col form-group col-md-6 col-12">
                <label htmlFor="province" className="label">
                  {t("location.province")}
                </label>
                <input
                  type="text"
                  className={`form-control set_max_width ${
                    errorProvince ? "error" : ""
                  }`}
                  placeholder={t("location.province")}
                  name="province"
                  id="province"
                  ref={provinceRef}
                  value={data.province || ""}
                  onChange={onChangeData}
                  onBlur={validateProvince}
                />
                {errorProvince && <div style={mystyle}>{errorProvince}</div>}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default ProvinceCommue;
