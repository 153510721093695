import React from 'react';
import CreatableSelect from 'react-select/creatable';

function EducationalLevel(props) {
    const {
        errorCreatAd_Two,
        selectedOptionsEducation,
        educationLevels,
        subSubmenuOptions,
        educationLevelRef,
        validateEducationLevel,
        handleOnCreatAdTwoEducationLevel,
        handleCreatAdTwoSubEducation,
        validateSubEducationLevel,
        subEducationalRef,
        isLoading,
        isClearable,
        mystyle,
        t,
    } = props;

    const selectedSubmenuOption = subSubmenuOptions.filter(
        option => option.levelType === selectedOptionsEducation?.submenu
    );

    const hasSubSubmenuOptions = selectedSubmenuOption && selectedSubmenuOption?.length > 0;

    return (
        <>
            <div className="col-12">
                <label htmlFor="educationLevels" className="form-label">
                    Grado istruzione Richiesto
                </label>
                <CreatableSelect
                    name="educationLevels"
                    className={`form-control  select_dropdown  ${errorCreatAd_Two.educationLevels ? "error" : ""} `}
                    options={educationLevels}
                    onChange={handleOnCreatAdTwoEducationLevel}
                    value={selectedOptionsEducation.submenu ? { label: selectedOptionsEducation.submenu, value: selectedOptionsEducation.submenu } : null}
                    ref={educationLevelRef}
                    placeholder="Seleziona o inserisci il tuo titolo di studio"
                    onBlur={validateEducationLevel}
                    isClearable={isClearable}
                    isLoading={isLoading}
                />
                {errorCreatAd_Two?.educationLevels && (
                    <div style={mystyle}>
                        {errorCreatAd_Two.educationLevels}
                    </div>
                )}
            </div>

            {selectedOptionsEducation?.submenu && hasSubSubmenuOptions && (
                <>
                    <div className="col-12">
                        <label>Specializzazione</label>
                        <select
                            name="educationlevel"
                            value={selectedOptionsEducation?.subSubmenu || ""}
                            onChange={handleCreatAdTwoSubEducation}
                            onBlur={validateSubEducationLevel}
                            ref={subEducationalRef}
                            className={`form-control  mt-2 ${errorCreatAd_Two?.subeducationLevels ? "error" : ""}`}>
                            <option value="">Nessuna specializzazione</option>
                            {selectedSubmenuOption?.map((subOption, index) => (
                                <option key={index} value={subOption.specialisation}>
                                    {subOption.specialisation}
                                </option>
                            ))}

                        </select>

                        {errorCreatAd_Two?.subeducationLevels && (
                            <div style={mystyle}>
                                {errorCreatAd_Two.subeducationLevels}
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    )
}

export default EducationalLevel