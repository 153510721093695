// File: /src/Ads/ReceivedQuotations.js
// Created Date: Thursday January 9th 2025
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Thursday January 9th 2025 4:33:33 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import AdsBuilder from "../Components/AdsBuilder";
import axios from "axios";
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";
import loaderLogo from "../images/Iphone-spinner-2.gif";

const CARD_FIELDS = {
  title1: ["by_name", "by_surname"],
  title2: [""],
  subtitle: ["offer_description"],
  date: ["date"],
  highlighted_elements: [
    {
      label: "Prezzo",
      key: "price",
    },
    {
      label: "Telefono",
      key: "telefono",
    },
    {
      label: "Email",
      key: "email",
    },
  ],
  tags: ["accepted"],
};

const DETAILED_FIELDS = {
  title1: ["by_name", "by_surname"],
  image: [],
  subtitle: [],
  description: ["offer_description", "quotation_description"],
  highlights: ["email", "telefono", "accepted"],
  blocks: ["qna"],
  highlighted_elements: [],
  info: ["price"],
};

const ReceivedQuotations = (props) => {
  const [quotations, setQuotations] = useState([]);
  const [showModal, setShowModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmPreventivo = async (ad) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_HOST}wideer/q/${ad.id}/`,
        {
          accepted: true,
        },
        {
          headers: {
            Authorization: `Bearer ${props.candidate?.loginData?.data?.Message.access}`,
          },
        }
      );
      Swal.fire({
        title: "Preventivo Accettato",
        text: "Il preventivo è stato accettato con successo",
        icon: "success",
        confirmButtonText: "OK",
      });
      await getQuotations();
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Errore",
        text: "Si è verificato un errore",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const getQuotations = async (companyId) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const id = props.candidate?.loginData?.data?.Message?.id;
      if (!id) return;
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}auth/register/?id=${companyId ?? id}`,
        {
          headers: {
            Authorization: `Bearer ${props.candidate?.loginData?.data?.Message.access}`,
          },
        }
      );

      const serializedData = (response.data.Message?.[0]?.quotations ?? []).map(
        (quotation) => ({
          accepted: quotation.accepted,
          id: quotation.id,
          price: quotation.price,
          by_name: quotation.owner.name,
          by_surname: quotation.owner.surname,
          telefono: quotation.owner.cellphone,
          by_id: quotation.owner.id,
          email: quotation.owner.email,
          // by_name: quotation.offer.created_by.name,
          // by_surname: quotation.offer.created_by.surname,
          // telefono: quotation.offer.created_by.cellphone,
          // by_id: quotation.offer.created_by.id,
          // email: quotation.offer.created_by.email,
          date: quotation.offer.created_at,
          offer_description: quotation.offer.description,
          quotation_description: quotation.text,
          qna: quotation.offer.answers.map((answer) => ({
            question: answer.question.question,
            answer: answer.answer,
          })),
        })
      );
      setQuotations((p) =>
        localStorage.getItem("representative_id")
          ? [
              ...p,
              ...serializedData.filter((d) => !p.some((p) => p.id === d.id)),
            ]
          : serializedData
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      if (localStorage.getItem("representative_id") && companyId) {
        getQuotations(
          props.candidate?.loginData?.data?.Message?.company_id?.[0]
        );
      }
    }
  };

  useEffect(() => {
    getQuotations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.candidate?.loginData?.data?.Message.id]);
  console.log(quotations);
  return (
    <div>
      <Header showModal={showModal} setShowModal={setShowModal} />
      <div className="container min-vh-100">
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            {isLoading ? (
              <div className="loader">
                <div className="loader_inner">
                  <img src={loaderLogo} />
                </div>
              </div>
            ) : quotations.length ? (
              <AdsBuilder
                cardFields={CARD_FIELDS}
                detailedFields={DETAILED_FIELDS}
                blockFields={{
                  title: "question",
                  content: "answer",
                }}
                ads={quotations}
                onAction={handleConfirmPreventivo}
                actionLabel="Accetta Preventivo"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { company: state.company, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReceivedQuotations);
