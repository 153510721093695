import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import CompanyLogo from '../../../src/images/logo.png'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestCompanyPackage } from '../../Redux/actions'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const PackageToken = props => {
  const [packageData, setPackageData] = useState([])
  const navigate = useNavigate()

  const { t } = useTranslation()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      const updatedRequestData = { token }
      props.requestCompanyPackage({ data: updatedRequestData })
    }
  }, [props.requestCompanyPackage])

  useEffect(() => {
    const packageDetails = props.company.packageData
    if (packageDetails !== undefined) {
      if (packageDetails?.data?.Status === 'Success') {
        setPackageData(packageDetails.data.Message || [])
      } else {
        // Handle unsuccessful status or clear data as needed
        console.error('Failed to fetch package details')
        setPackageData([])
      }
    }
  }, [props.company.packageData])

  const redirectToAdd = () => {
    if (location.state?.redirect) {
      navigate(location.state.redirect)
    } else {
      navigate(-1)
    }
  }

  // const redirectToPayment = (item) => {
  //     // navigate('/company/payment', { state: { item } });
  //     // navigate('/company/payment-system', { state: { item } });
  // }

  const redirectToPayment = item => {
    const isValidItem =
      item &&
      item.id &&
      item.package_name &&
      item.price &&
      item.price_currency &&
      item.token_amount
    if (isValidItem) {
      navigate('/company/payment-system', { state: { item } })
    } else {
      navigate('/company/PackageToken')
    }
  }

  const location = useLocation()

  return (
    <>
      <Header />
      <div
        className='job_listing_sec package_token'
        style={{ maxHeight: '100%', overflow: 'auto' }}
      >
        <div className='container'>
          <div className='new_research_btn mt-4 text-end'>
            <button
              className='btn btn_default btn_orange_outline'
              type='button'
              onClick={redirectToAdd}
            >
              Indietro
            </button>
          </div>
          <h2 className='package_token_title mb-3'>
            Acquista un nuovo pacchetto di crediti
          </h2>
          <div className='job_listing_blocks'>
            {packageData.length > 0 ? (
              packageData.map((item, index) => (
                <div
                  key={index}
                  className='job_listing_block'
                  onClick={() => redirectToPayment(item)}
                >
                  <a className='redirect_a'>
                    <div className='job_listing_block_inner'>
                      <div className='job_title'>
                        <h2>{item.package_name}</h2>
                      </div>
                      <div className='logo_detailbtn'>
                        <div className='company_logo'>
                          <img src={CompanyLogo} alt='company logo' />
                        </div>
                      </div>
                      {/* <div className="job_description">
                                                <p>{item.price}</p>
                                            </div> */}
                      <div className='token_detail'>
                        <p>
                          <strong>{t('packages.price')}:</strong> {item.price}
                        </p>
                        <p>
                          <strong>{t('packages.price-currency')}:</strong>{' '}
                          {item.price_currency}
                        </p>
                        <p>
                          <strong>{t('packages.token-amount')}:</strong>{' '}
                          {item.token_amount}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              ))
            ) : (
              <p>No package data available.</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => ({
  candidate: state.candidate,
  company: state.company
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ requestCompanyPackage }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PackageToken)
