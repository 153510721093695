import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestGetSectorList } from '../../../Redux/actions'
import CreatableSelect from 'react-select/creatable'
import { useLocation } from 'react-router-dom'

function SectorLevel (props) {
  const {
    selectedOptionsSector,
    handleSectorLevelChange,
    validateSector,
    sectorRef,
    errorSector,
    handleSubSectorLevelChange,
    validateSubSector,
    subsectorRef,
    errorSubSector,
    isLoading,
    mystyle,
    sectorLabel = 'Settore',
    hideSubSector = false,
    hidden = false,
    onChangeSectorSubsector = undefined
  } = props

  const [sectorsLevel, setSectorsLevel] = useState([])
  const [subSubSectorsLevel, setSubSubSectorsLevel] = useState([])
  const isClearable = true

  useEffect(() => {
    props.requestGetSectorList()
  }, [])

  useEffect(() => {
    const sectorsLevelData = props.candidate.SectorData
    if (sectorsLevelData?.data?.Status === 'Success') {
      const uniqueSectors = {}

      sectorsLevelData.data.Message.forEach(sector => {
        if (!uniqueSectors[sector.sectorName]) {
          uniqueSectors[sector.sectorName] = {
            value: sector.id,
            label: sector.sectorName
          }
        }
      })
      const transformedSectorlevelData = Object.values(uniqueSectors)
      setSectorsLevel(transformedSectorlevelData)
      onChangeSectorSubsector?.({
        sector: transformedSectorlevelData,
        subsector: []
      })

      //   const filteredSubMenuOptions = sectorsLevelData.data.Message.filter(
      //     (level) => level.subSector
      //   ).map((level) => ({
      //     sectorName: level.sectorName,
      //     subSector: level.subSector,
      //   }));

      //   setSubSubSectorsLevel(filteredSubMenuOptions);
    }
  }, [props.candidate.SectorData])

  const location = useLocation()

  const selectedSubmenuOption = subSubSectorsLevel.filter(
    option => option.sectorName === selectedOptionsSector?.submenu
  )

  //   const hasSubSubmenuOptions =
  //     selectedSubmenuOption && selectedSubmenuOption?.length > 0;

  useEffect(() => {
    const uState = (() => {
      if (props.agent.SectorData) return props.agent.SectorData
      if (props.company.SectorData) return props.company.SectorData
      if (props.candidate.SectorData) return props.candidate.SectorData
      return null
    })()
    const optList = uState?.data.Message
    const subSectorsList = optList
      ?.filter(
        sector =>
          sector.sectorName === selectedOptionsSector.submenu ||
          sector.sectorName ===
            optList.find(
              o => +o.id === +location.state?.formData?.sector?.[0]?.sectorName
            )?.sectorName
      )
      .filter(s => !!s.subSector)

    if (subSectorsList) {
      setSubSubSectorsLevel(subSectorsList)
    }
  }, [selectedOptionsSector.submenu, location.state?.formData?.sector])

  const searchParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    console.log(subSubSectorsLevel)
    console.log(location.state?.formData?.sub_sector)
  }, [])

  if (hidden) return <></>

  return (
    <>
      <div className='registration_col form-group col-md-6 col-12'>
        <label htmlFor='sector' className='label'>
          {sectorLabel}
        </label>
        <CreatableSelect
          name='sector'
          isDisabled={searchParams.get('type') === 'franchisee'}
          className={`form-control set_max_width select_dropdown  ${
            errorSector ? 'error' : ''
          } `}
          options={sectorsLevel}
          onChange={(...args) => {
            handleSectorLevelChange(...args)
            const uState = (() => {
              if (props.agent.SectorData) return props.agent.SectorData
              if (props.company.SectorData) return props.company.SectorData
              if (props.candidate.SectorData) return props.candidate.SectorData
              return null
            })()

            const optList = uState?.data.Message
            const subSectorsList = optList
              ?.filter(sector => sector.sectorName === args[0]?.label)
              .filter(s => !!s.subSector)

            if (subSectorsList) {
              setSubSubSectorsLevel(subSectorsList)
              onChangeSectorSubsector?.(p => ({
                sector: p.sector,
                subsector: subSectorsList
              }))
            }
          }}
          value={
            selectedOptionsSector.submenu
              ? {
                  label:
                    selectedOptionsSector.submenu.label ||
                    selectedOptionsSector.submenu,
                  value:
                    selectedOptionsSector.submenu.value ||
                    selectedOptionsSector.submenu
                }
              : null
          }
          ref={sectorRef}
          placeholder='Seleziona un Settore'
          onBlur={validateSector}
          isClearable={isClearable}
          isLoading={isLoading}
        />
        {errorSector && <div style={mystyle}>{errorSector}</div>}
      </div>

      {!hideSubSector && localStorage.getItem('userType') !== 'agent' ? (
        subSubSectorsLevel?.length > 0 ? (
          <>
            <div className='registration_col form-group col-md-6 col-12'>
              <label>Sottosettore</label>
              <select
                disabled={searchParams.get('type') === 'franchisee'}
                name='sector'
                value={
                  subSubSectorsLevel.find(
                    subOption =>
                      subOption.subSector === selectedOptionsSector?.subSubmenu
                  )?.subSector ||
                  subSubSectorsLevel.find(
                    subOption =>
                      +subOption.id === +location.state?.formData?.sub_sector
                  )?.subSector ||
                  ''
                }
                onChange={handleSubSectorLevelChange}
                onBlur={validateSubSector}
                ref={subsectorRef}
                className={`form-control set_max_width mt-2 ${
                  errorSubSector ? 'error' : ''
                }`}
              >
                <option value=''>Seleziona un Sottosettore</option>
                {subSubSectorsLevel?.map((subOption, index) => (
                  <option key={index} value={subOption.subSector}>
                    {subOption.subSector}
                  </option>
                ))}
              </select>
              {errorSubSector && <div style={mystyle}>{errorSubSector}</div>}
            </div>
          </>
        ) : (
          <></>
          // <div className="registration_col form-group col-md-6 col-12">
          //   <label htmlFor="products_marketed" className="label">
          //     Sottosettori
          //   </label>
          //   <input
          //     type="text"
          //     className={`form-control set_max_width ${
          //       errorSubSector ? "error" : ""
          //     }`}
          //     placeholder="Sottosettori"
          //     id="products_marketed"
          //     name="products_marketed"
          //     ref={subsectorRef}
          //     value={selectedOptionsSector?.subSubmenu?.label || ""}
          //     onChange={(e) =>
          //       handleSubSectorLevelChange({ label: e.target.value })
          //     }
          //     onBlur={validateSubSector}
          //   />
          //   {errorSubSector && <div style={mystyle}>{errorSubSector}</div>}
          // </div>
        )
      ) : (
        <></>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    candidate: state.candidate,
    company: state.company,
    agent: state.agent
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ requestGetSectorList }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SectorLevel)
