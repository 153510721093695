import {
  RECEIVE_REGISTER,
  RECEIVE_LOGIN,
  // RECEIVE_REFRESH_LOGIN,
  RECEIVE_GET_LIST,
  RECEIVE_GET_LOCATION,
  //==============regione==========================
  RECEIVE_GET_REGIONE_LIST,
  //==============education level===================
  RECEIVE_GET_EDUCATION_LEVEL_LIST,
  //==============sector level======================
  RECEIVE_GET_SECTOR_LIST,
  RECEIVE_GET_CANDIDATE,
  RECEIVE_CANDIDATE_PROFILE,
  //=================resume post get and patch ======================
  RECEIVE_CANDIDATE_RESUME,
  RECEIVE_GET_RESUME,
  RECEIVE_UPDATE_RESUME,
  RECEIVE_DELETE_RESUME_FILE,
  RECEIVE_DELETE_LETTER_FILE,
  RECEIVE_GET_JOB,
  RECEIVE_GET_JOBLIST,
  RECEIVE_GET_JOBLIST_USER_ID,
  RECEIVE_COMPANY_JOB_APPLY_NOW,
  RECEIVE_CANDIDATE_ADD_ANSWER,
  RECEIVE_CANDIDATE_GET_ANSWER,
  RECEIVE_GET_FILTER_OPTIONS,
  RECEIVE_LINKEDIN_LOGIN,
} from "./actions";

export default (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_REGISTER:
      return {
        ...state,
        registerData: data,
      };

    case RECEIVE_LINKEDIN_LOGIN:
      return {
        ...state,
        loginData: (() => {
          if (data?.data?.Message) {
            return data;
          }
          return {
            data: {
              Message: {
                ...data.data,
              },
            },
          };
        })(),
      };

    case RECEIVE_LOGIN:
      return {
        ...state,
        loginData: data,
      };

    // case RECEIVE_REFRESH_LOGIN:
    //   return {
    //     ...state,
    //     loginrefreshData: data,
    //   };

    case RECEIVE_GET_LIST:
      return {
        ...state,
        getlistData: data,
      };

    case RECEIVE_GET_LOCATION:
      return {
        ...state,
        getlocation: data,
      };

    case RECEIVE_GET_REGIONE_LIST:
      return {
        ...state,
        getRegionData: data,
      };

    case RECEIVE_GET_EDUCATION_LEVEL_LIST:
      return {
        ...state,
        EducationlevelData: data,
      };

    case RECEIVE_GET_SECTOR_LIST:
      return {
        ...state,
        SectorData: data,
      };

    case RECEIVE_GET_CANDIDATE:
      return {
        ...state,
        GetCandidateData: data,
      };

    case RECEIVE_CANDIDATE_PROFILE:
      return {
        ...state,
        CandidateProfileData: data,
      };

    case RECEIVE_CANDIDATE_RESUME:
      return {
        ...state,
        resumeData: data,
      };

    case RECEIVE_GET_RESUME:
      return {
        ...state,
        GetResumeData: data,
      };

    case RECEIVE_UPDATE_RESUME:
      return {
        ...state,
        UpdateResumeData: data,
      };

    case RECEIVE_DELETE_RESUME_FILE:
      return {
        ...state,
        DeleteResume: data,
      };

    case RECEIVE_DELETE_LETTER_FILE:
      return {
        ...state,
        DeleteLetter: data,
      };

    case RECEIVE_GET_JOB:
      return {
        ...state,
        GetJobData: data,
      };

    case RECEIVE_GET_JOBLIST:
      return {
        ...state,
        GetJobListData: data,
      };

    case RECEIVE_GET_JOBLIST_USER_ID:
      return {
        ...state,
        GetJobListUserId: data,
      };

    case RECEIVE_COMPANY_JOB_APPLY_NOW:
      return {
        ...state,
        applyNowJob: data,
      };

    //==================Add answer====================

    case RECEIVE_CANDIDATE_ADD_ANSWER:
      return {
        ...state,
        addAnswerForJob: data,
      };

    //==================Get answer====================

    case RECEIVE_CANDIDATE_GET_ANSWER:
      return {
        ...state,
        getAnswerForJob: data,
      };

    case RECEIVE_GET_FILTER_OPTIONS:
      return {
        ...state,
        GetFilterOptionsData: data,
      };

    default:
      return state;
  }
};
