import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  requestGetCandidate,
  requestGetJobList,
  resetGetJobList
} from '../Redux/actions'
import CompanyLogo from '../../src/images/logo.png'
import { useTranslation } from 'react-i18next'
import Header from './Header'
import Footer from './Footer'
import { useAppNavigation } from '../utils/routing'
import { jwtDecode } from 'jwt-decode'
import JobsDetails from './JobsDetails'
import Swal from 'sweetalert2'
import axios from 'axios'

function JobsList (props) {
  const [jobs, setJobs] = useState([])
  const [JobId, setJobId] = useState(null)
  const [user, setUser] = useState(null)
  const [showModal, setShowModal] = useState('')
  const { location, navigateTo } = useAppNavigation()
  const { t } = useTranslation()
  const loc = useLocation()
  const navigate = useNavigate()

  /* 
  useEffect(() => {
    console.log(props);
     let JobsList = props.jobListData;
     if (JobsList !== undefined) {
       if (JobsList) {
         setJobs(JobsList);
       } else {
         props.jobListData = undefined;
       }
     }
   }, [props.jobListData]);

   */

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (loc.state?.from === 'advertisement-details') {
      let requestData = { ad_type__type: 'Employee' }
      /*if (loc.state?.country) {
        requestData.address__country = loc.state?.country
      } 
      if (loc.state?.regione) {
        requestData.address__state = loc.state?.regione
      }
      if (loc.state?.city) {
        requestData.address__city = loc.state?.city
      }
      if (loc.state?.province) {
        requestData.address__province = loc.state?.province
      }
        */
      if (loc.state?.professional) {
        requestData.sector__sectorName = loc.state?.professional
      }
      if (loc.state?.specialization) {
        requestData.subsector__subsector_name = loc.state?.specialization
      }
      
      requestData.published = true
    

      props.requestGetJobList({ data: requestData, token: token })
      setJobs(props.candidate.GetJobListData?.data?.Message)
    }
  }, [])

  useEffect(() => {
    console.log(location)
    const data = location.state?.jobListData || []
    setJobs(data)
    console.log(jobs)
    if (props.candidate.GetJobListData?.data?.Status === "Success") {
      setJobs(props.candidate.GetJobListData?.data?.Message)
    }

    return () => {
      if (props.candidate && 'GetJobListData' in props.candidate) {
        props.candidate.GetJobListData = undefined
      }
      if (props.company && 'GetJobListData' in props.company) {
        props.company.GetJobListData = undefined
        console.log('Setting company GetJobListData to undefined')
      }
      if (props.agent && 'GetJobListData' in props.agent) {
        props.agent.GetJobListData = undefined
        console.log('Setting agent GetJobListData to undefined')
      }
      if (props.franchisor && 'GetJobListData' in props.franchisor) {
        props.franchisor.GetJobListData = undefined
      }
      props.resetGetJobList()
    }
  }, [
    location.state?.jobListData,
    props.candidate?.GetJobListData,
    props.company?.GetJobListData,
    props.agent?.GetJobListData,
    props.franchisor?.GetJobListData
  ])

  useEffect(() => {
    props.requestGetCandidate()
  }, [])

  useEffect(() => {
    const candidateData = props?.candidate?.loginData?.data?.Message

    if (candidateData) {
      setUser(candidateData)
    }
  }, [props.candidate.loginData?.data?.Message])
  console.log(user, 'user')
  const getValue = value => (user ? value : value)

  const redirectToJobDetails = id => {
    let destination = location.pathname
    const newOptions = { state: { jobListData: jobs } }
    const userType = localStorage.getItem('userType')
    console.log(user, 'user')
    if (userType === 'noType') {
      navigateTo('/candidate/profile', { state: { convertToEmployee: true } })
      return
    }
    if (userType === 'company') {
      Swal.fire({
        title: 'Vuoi guardare gli annunci di lavoro?',
        text: 'Registrati come candidato utilizzando un nuovo account'
      })
      return
    }
    if (!user) {
      setShowModal('login')
      localStorage.setItem('logging-for', 'flag_employee')
      newOptions.params = {
        userType: 'candidate'
      }
      navigateTo(destination, newOptions)
    } else {
      setJobId(id)
    }
  }

  const redirectToJobFinder = () => {
    navigate('/job-finder', { state: { jobListData: [] } })
  }

  return (
    <>
      <Header showModal={showModal} setShowModal={setShowModal} />
      <div className='container'>
        <div className='new_research_btn mt-4 text-end'>
          <button
            className='btn btn_default btn_orange_outline'
            type='button'
            onClick={redirectToJobFinder}
          >
            Nuova ricerca
          </button>
        </div>
        <div className='row justify-content-center'>
          <div className='col-md-6 col-12'>
            {jobs.length > 0 && (
              <div className='job_listing_sec'>
                {/* <div className="container"> */}

                <div className='job_listing_blocks'>
                  {jobs.map(job => (
                    <div
                      key={job.id}
                      className={`job_listing_block ${
                        !user || !user.flag_employee ? 'blur_effec' : ''
                      }`}
                    >
                      <div
                        className='redirect_a'
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                          redirectToJobDetails(job.id)
                        }}
                      >
                        <div className='job_listing_block_inner'>
                          <div className='job_title'>
                            <h2>{getValue(job.job_title)}</h2>
                          </div>
                          <div className='logo_detailbtn'>
                            <div className='company_logo'>
                              <img src={CompanyLogo} alt='company logo' />
                            </div>
                            <div className='detail_btn'>
                              <button
                                className='btn btn_default btn_green_outline'
                                type='button'
                                onClick={() => redirectToJobDetails(job.id)}
                              >
                                {t('job-list.button.save')}
                              </button>
                            </div>
                          </div>
                          <div className='job_description'>
                            <p>{getValue(job.job_description)}</p>
                          </div>
                          {job.sector?.sectorName ? (
                            <div className='sector_name'>
                              <p>
                                <strong>Settore:&nbsp;&nbsp;</strong>{' '}
                                {getValue(job.sector?.sectorName || '')}
                              </p>
                            </div>
                          ) : null}

                          <div className='job_salary_address'>
                            {job?.salary?.fixedAmount ||
                            (job?.salary?.minAmount &&
                              job?.salary?.maxAmount) ? (
                              <div className='job_salary'>
                                <p>
                                  <strong>Retribuzione:&nbsp;&nbsp;</strong>
                                  {getValue(
                                    job?.salary?.fixedAmount
                                      ? `${job?.salary?.fixedAmount} ${job?.salary?.fixedAmount_currency}`
                                      : job?.salary?.minAmount &&
                                        job?.salary?.maxAmount
                                      ? `${job?.salary?.minAmount} - ${job?.salary?.maxAmount} ${job?.salary?.reccurency}`
                                      : ''
                                  )}
                                </p>
                              </div>
                            ) : null}
                            <div className='job_address'>
                              <p>
                                <strong>Luogo:&nbsp;&nbsp;</strong>
                                <span>
                                  {getValue(
                                    job.address
                                      ? `${
                                          job.address.city
                                            ? job.address.city
                                            : ''
                                        }${
                                          job.address.province
                                            ? `, ${job.address.province}`
                                            : ''
                                        }${
                                          job.address.country
                                            ? `, ${job.address.country}`
                                            : ''
                                        }`
                                      : ''
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className='required_education_skills'>
                            <div className='required_education'>
                              <p>
                                {' '}
                                {job.educationalLevel?.levelType ? (
                                  <strong>
                                    Istruzione richiesta:&nbsp;&nbsp;
                                  </strong>
                                ) : (
                                  ''
                                )}{' '}
                                {getValue(
                                  job.educationalLevel?.levelType || ''
                                )}
                              </p>
                            </div>
                            <div className='required_skills'>
                              {user && job.skills && job.skills.length > 0 ? (
                                <>
                                  <p>
                                    <strong>
                                      Competenze richieste:&nbsp;&nbsp;
                                    </strong>

                                    {job.skills.map((skill, index) => (
                                      <span key={index}>
                                        {skill.skillName},
                                      </span>
                                    ))}
                                  </p>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* </div> */}
              </div>
            )}
          </div>
          {JobId && user && (
            <div className='col-md-6 col-12'>
              <JobsDetails id={JobId} userId={user?.id} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return { candidate: state.candidate }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { requestGetJobList, requestGetCandidate, resetGetJobList },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(JobsList)
