import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { bindActionCreators } from 'redux'
import {
  requestGetResumeDetails,
  requestCompanyDetails
} from '../../Redux/actions'
import CompanyLogo from '../../../src/images/logo.png'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useParams, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Download from '../../../src/images/download.png'

function CVDetails (props) {
  const [jobDetails, setJobDetails] = useState({})
  const navigate = useNavigate()
  const { id } = useParams()
  const isCompany = props.candidate.loginData?.data?.Message?.flag_company
  const [resumeDetails, setResumeDetails] = useState({})
  const [userDetails, setUserDetails] = useState({})
  const location = useLocation()

  useEffect(() => {
    const token = localStorage.getItem('token')
    axios
      .get(`${process.env.REACT_APP_API_HOST}wideer/createResume/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        params: { id: id }
      })
      .then(response => {
        console.log(response)
        setResumeDetails(response.data.Message.resumes[0].resume)
      })
      .catch(err => {
        console.log(err, 'err')
      })

    axios
      .get(`${process.env.REACT_APP_API_HOST}auth/register/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        params: { id: id }
      })
      .then(response => {
        console.log(response)
        setUserDetails(response.data.Message[0])
      })
      .catch(err => {
        console.log(err, 'err')
      })
    console.log(location.state)
  }, [])

  const redirectToAdvertosementList = () => {
    if (location.state?.jobList?.length > 0) {
      navigate(-1)
    }
    navigate('/saved-ads')
  }

  return (
    <>
      <Header />

      <div className='job_detail_sec'>
        <div className='container'>
          <div className='new_research_btn mt-4 text-end'>
            <button
              className='btn btn_default btn_orange_outline'
              type='button'
              onClick={redirectToAdvertosementList}
            >
              Indietro
            </button>
          </div>
          <div className='row'>
            <div className='job_detail_inner col-xl-12 col-lg-12 col-md-12 col-m-12 col-12'>
              <div className='job_details'>
                <div className='company_logo'>
                  <img src={CompanyLogo} alt='company logo' />
                </div>
                {location?.state?.answers && (
                  <>
                    <div className='job_title mb-4 d-flex justify-content-between gap-3'>
                      <h2 className=''>
                        Risposte all'annuncio {location?.state?.ad}
                      </h2>
                    </div>
                    <div className='job_answers mb-5'>
                      <ul>
                        {location?.state?.answers &&
                          location?.state?.answers
                            .filter(
                              answer => Number(answer.user_id) === Number(id)
                            )
                            .map((answer, index) => (
                              <li key={index}>
                                <b style={{ color: 'black' }}>
                                  {answer.question_id?.question}
                                </b>
                                <br />
                                <span>{answer.answer}</span>
                              </li>
                            ))}
                      </ul>
                    </div>
                  </>
                )}
                <div className='job_title mb-0 d-flex justify-content-between gap-3'>
                  <h2 className=''>
                    {userDetails?.name} {userDetails?.surname} (
                    <span style={{ textTransform: 'lowercase' }}>
                      {userDetails?.username}
                    </span>
                    )
                  </h2>

                  {resumeDetails?.resume_file && (
                    <a className='download_icon'>
                      <img src={Download} alt='Download Icon' />
                    </a>
                  )}
                </div>

                <div className='job_address'>
                  {userDetails?.address && userDetails?.address?.length > 0 ? (
                    <>
                      {userDetails.address[0]?.street &&
                        `${userDetails.address[0].street}, `}
                      {userDetails.address[0]?.city &&
                        `${userDetails.address[0].city}, `}
                      {userDetails.address[0]?.province &&
                        `${userDetails.address[0].province}, `}
                      {userDetails.address[0]?.state &&
                        `${userDetails.address[0].state}, `}
                      {userDetails.address[0]?.country &&
                        `${userDetails.address[0].country}, `}
                      {userDetails.address[0]?.zip_code &&
                        `${userDetails.address[0].zip_code}`}
                    </>
                  ) : (
                    ''
                  )}
                </div>

                <div className='company_placed_on'>
                  <ul>
                    {userDetails.sector && (
                      <li>
                        <b style={{ color: 'black' }}>Settore: </b>
                        {userDetails.sector.sectorName
                          ? userDetails.sector.sectorName
                          : ''}
                      </li>
                    )}
                    {userDetails.subsector && (
                      <li>
                        <b style={{ color: 'black' }}>Sottosettore: </b>
                        {userDetails.subsector.subsector_name
                          ? userDetails.subsector.subsector_name
                          : ''}
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              <div className='job_requirements'>
                <ul>
                  {userDetails?.educationalLevel?.levelType && (
                    <li>
                      <b style={{ color: 'black' }}>Livello scolastico: </b>

                      <span>{userDetails?.educationalLevel?.levelType}</span>
                    </li>
                  )}
                </ul>
              </div>

              <div className='job_requirements'>
                <ul>
                  {userDetails?.driving_licence && (
                    <li>
                      <b style={{ color: 'black' }}>Patenti conseguite: </b>
                      {userDetails?.driving_licence.map((license, index) => (
                        <span key={index}>
                          {license.licenceType}
                          {index !== userDetails.driving_licence.length - 1 &&
                            ', '}
                        </span>
                      ))}
                    </li>
                  )}
                </ul>
              </div>
              {userDetails?.description && (
                <div className='job_description'>
                  <h6 className='job_description_title'>Descrizione:</h6>
                  <p>{userDetails.description}</p>
                </div>
              )}
              <div className='job_additional_info pt-4'>
                {resumeDetails?.education && (
                  <>
                    <h6 className='job_additional_title'>Formazione</h6>
                    <ul>
                      {resumeDetails.education.map((education, index) => (
                        <>
                          <li>
                            <b style={{ color: 'black' }}>Specializzazione: </b>
                            <span>{education.educationalLevel}</span>
                          </li>

                          <li>
                            <b style={{ color: 'black' }}>
                              Nome scuola / istituto / accademia:{' '}
                            </b>
                            <span>{education.school}</span>
                          </li>

                          <li>
                            <div className='d-flex align-items-center gap-5'>
                              <div>
                                <b style={{ color: 'black' }}>Dal: </b>
                                <span>
                                  {education.monthFrom}/{education.yearFrom}
                                </span>
                              </div>
                              <div>
                                <b style={{ color: 'black' }}>Al: </b>
                                <span>
                                  {education.monthTo}/{education.yearTo}
                                </span>
                              </div>
                            </div>
                          </li>

                          <li>
                            <b style={{ color: 'black' }}>Settore: </b>
                            <span>{education.educationalSector}</span>
                          </li>
                        </>
                      ))}
                    </ul>
                  </>
                )}
              </div>

              <div className='professional_experiences'>
                {resumeDetails?.works && (
                  <>
                    <h6 className='professional_experiences_title'>
                      Esperienze professionali
                    </h6>
                    {resumeDetails.works.map((work, index) => (
                      <>
                        <div key={index} className={`text-end`}>
                          {work?.reccomendationLetter && (
                            <a
                              href={
                                process.env.REACT_APP_API_HOST +
                                work?.reccomendationLetter
                              }
                              className='download_icon d-inline-flex align-items-center'
                              target='_blank'
                              style={{ gap: '10px' }}
                            >
                              Lettera di referenze
                              <img src={Download} alt='Download Icon' />
                            </a>
                          )}
                        </div>
                        <ul key={index}>
                          {work.sector && (
                            <li>
                              <b style={{ color: 'black' }}>Settore: </b>
                              <span>{work.sector}</span>
                            </li>
                          )}

                          {work.subsector && (
                            <li>
                              <b style={{ color: 'black' }}>Sottosettore: </b>
                              <span>{work.subsector}</span>
                            </li>
                          )}

                          <li>
                            <b style={{ color: 'black' }}>Ruolo: </b>
                            <span>{work.role}</span>
                          </li>
                          <li>
                            <b style={{ color: 'black' }}>Nome azienda: </b>
                            <span>{work.company}</span>
                          </li>

                          <li>
                            <div className='d-flex align-items-center gap-5'>
                              <div>
                                <b style={{ color: 'black' }}>Dal: </b>
                                <span>
                                  {work.monthFrom}/{work.yearFrom}
                                </span>
                              </div>
                              <div>
                                <b style={{ color: 'black' }}>Al: </b>
                                <span>
                                  {work.monthTo}/{work.yearTo}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </>
                    ))}
                  </>
                )}
              </div>

              <div className='known_languages'>
                {resumeDetails?.languages && (
                  <>
                    <h6 className='known_languages_title'>Lingue conosciute</h6>

                    {resumeDetails.languages.map((language, index) => (
                      <ul style={{ paddingTop: '30px' }}>
                        <li>
                          <b style={{ color: 'black' }}>Lingua: </b>
                          <span>{language.language}</span>
                        </li>

                        <li>
                          <b style={{ color: 'black' }}>Livello di ascolto: </b>
                          <span>{language.levelListening}</span>
                        </li>

                        <li>
                          <b style={{ color: 'black' }}>Livello di lettura: </b>
                          <span>{language.levelReading}</span>
                        </li>
                        <li>
                          <b style={{ color: 'black' }}>
                            Livello di scrittura:{' '}
                          </b>
                          <span>{language.levelWritten}</span>
                        </li>
                        <li>
                          <b style={{ color: 'black' }}>
                            Livello del parlato:{' '}
                          </b>
                          <span>{language.levelSpoken}</span>
                        </li>
                      </ul>
                    ))}
                  </>
                )}
              </div>

              <div className='job_next_process company_next_process'>
                {!isCompany && (
                  <div className='save_apply_btn'>
                    <div className='save_btn'>
                      <button
                        className='btn btn_default btn_orange'
                        type='button'
                      >
                        <svg
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M5 19.71H15C16.0609 19.71 17.0783 19.2885 17.8284 18.5384C18.5786 17.7882 19 16.7708 19 15.71V6.12396C18.9999 5.85877 18.8946 5.60445 18.707 5.41696L15.293 2.00296C15.1055 1.81541 14.8512 1.71002 14.586 1.70996H5C3.93913 1.70996 2.92172 2.13139 2.17157 2.88153C1.42143 3.63168 1 4.64909 1 5.70996V15.71C1 16.7708 1.42143 17.7882 2.17157 18.5384C2.92172 19.2885 3.93913 19.71 5 19.71Z'
                            stroke='white'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M15 19.71V12.71C15 12.4447 14.8946 12.1904 14.7071 12.0029C14.5196 11.8153 14.2652 11.71 14 11.71H6C5.73478 11.71 5.48043 11.8153 5.29289 12.0029C5.10536 12.1904 5 12.4447 5 12.71V19.71M7 1.70996H13V4.70996C13 4.97518 12.8946 5.22953 12.7071 5.41707C12.5196 5.6046 12.2652 5.70996 12 5.70996H8C7.73478 5.70996 7.48043 5.6046 7.29289 5.41707C7.10536 5.22953 7 4.97518 7 4.70996V1.70996Z'
                            stroke='white'
                            strokeWidth='2'
                          />
                          <path
                            d='M9 15.71H11'
                            stroke='white'
                            strokeWidth='2'
                            strokeLinejoin='round'
                          />
                        </svg>
                        Salva annuncio per dopo
                      </button>
                    </div>

                    <div className='apply_btn'>
                      <button
                        className='btn btn_default btn_green'
                        type='button'
                      >
                        candidati ora
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
const mapStateToProps = state => {
  return { candidate: state.candidate }
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CVDetails)
