import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  requestGetList,
  requestGetCandidate,
  requestCompanyJWT,
  requestCompanyReference,
  requestCompanyRegister,
  userLogout,
  requestCompanyDetails,
  requestGetCVList
} from '../../Redux/actions'
import { toast } from 'react-toastify'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useTranslation } from 'react-i18next'
import TermsAndService from '../../Components/common/register-fields/TermsAndService'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import LocationForm from '../../Components/common/register-fields/location/LocationForm'
import VatValidator from './VatValidator'
import SectorLevel from '../../Components/common/register-fields/SectorLevel'
import SDICode from './SDICode'
import Referent from './Referent'
import Swal from 'sweetalert2'
import Select, { createFilter } from 'react-select'
import { useAppNavigation } from '../../utils/routing'
import loaderLogo from '../../images/submit.gif'

function Register (props) {
  const [selectedOptionsSector, setSelectedOptionsSector] = useState({
    submenu: '',
    subSubmenu: ''
  })

  const [data, setData] = useState({
    country: 'Italia',
    phone: '+39'
  })

  const [jwtToken, setJwtToken] = useState({})

  const [reference, setReference] = useState([
    {
      email: '',
      first_name: '',
      last_name: '',
      phone: ''
    }
  ])

  const [errorreference, setErrorReference] = useState([
    {
      email: '',
      first_name: '',
      last_name: '',
      phone: ''
    }
  ])

  const [companySizes, setCompanySizes] = useState([])
  //===================referent ====================
  const sdiCodeRef = useRef(null)
  const companyEmailRef = useRef(null)
  const sectorRef = useRef(null)
  const subsectorRef = useRef(null)
  const streetNumberRef = useRef(null)
  const zipCodeRef = useRef(null)
  const cityRef = useRef(null)
  const provinceRef = useRef(null)
  const countryRef = useRef(null)
  const eula_consentRef = useRef(null)
  const privacy_consentRef = useRef(null)

  const companyNameRef = useRef(null)
  const dimensionRef = useRef(null)
  const activityRef = useRef(null)
  const vatNumberRef = useRef(null)

  const ReferIndexRef = useRef(null)
  const formFocusRefs = {
    reference: useRef([])
  }

  const [error, setError] = useState(false)
  const [errorCompanyEmail, setErrorCompanyEmail] = useState('')
  const [errorSector, setErrorSector] = useState('')
  const [errorSubSector, setErrorSubSector] = useState('')
  const [errorStreetnumber, seterrorStreetnumber] = useState('')
  const [errorCountry, seterrorCountry] = useState('')
  const [errorCity, seterrorCity] = useState('')
  const [errorProvince, seterrorProvince] = useState('')
  const [errorZipcode, seterrorZipcode] = useState('')
  const [countryData, setCountryData] = useState([])
  const [municipalities, setMunicipalities] = useState([])
  const [autopassword, setAutoPassword] = useState('')
  const [selectedCity, setSelectedCity] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [sectorSubsectorOptions, setSectorSubsectorOptions] = useState({
    sector: [],
    subsector: []
  })

  const [loadingForRefer, setLoadingForRefer] = useState(false)
  const [emailVerify, setEmailVerify] = useState([])

  const [emailChangeVerify, setEmailChangeVerify] = useState([])

  const [privacyAccepted, setPrivacyAccepted] = useState(false)
  const [errorprivacyAccepted, setErrorPrivacyAccepted] = useState('')
  const [privacyContentAccepted, setPrivacyContentAccepted] = useState(false)
  const [errorPrivacyContentAccepted, setErrorPrivacyContentAccepted] =
    useState('')
  const [submitLoading, setSubmitLoading] = useState(false)

  const { location, navigateTo } = useAppNavigation()

  //===========================================================================================
  const [errorCompanyname, setErrorCompanyname] = useState('')
  const [errorSDIcode, setErrorSDIcode] = useState('')
  const [errorDimension, setErrorDimension] = useState('')
  const [errorVatNumber, setErrorVatNumber] = useState('')
  const [errorActivity, setErrorActivity] = useState('')

  const navigate = useNavigate()
  const { t } = useTranslation()

  const isClearable = true
  const isSearchable = true
  const matchFromStart = true

  const filterConfig = {
    matchFrom: matchFromStart ? 'start' : 'any'
  }

  const mystyle = {
    color: '#D10000',
    padding: '3px 10px',
    width: '100%'
  }

  useEffect(() => {
    if (data.company_email) {
      localStorage.setItem('username', data.company_email)
    }
  }, [data.company_email])

  const loc = useLocation()

  useEffect(() => {
    props.requestGetList()
  }, [])

  useEffect(() => {
    let levelData = props.candidate.getlistData
    if (levelData !== undefined) {
      if (levelData?.data?.Status === 'Success') {
        const processedCountries = levelData.data.Message.countries.map(
          item => {
            const name = item.nativeCountryName
            return name
          }
        )
        const uniqueCountries = [...new Set(processedCountries)]
        const uniqueCountryData = uniqueCountries.map(name => ({
          nativeCountryName: name
        }))

        setCountryData(uniqueCountryData)
        const municipalit = levelData?.data?.Message?.municipalities
        const uniqueMunicipalities = []
        const municipalityNames = new Set()

        municipalit.forEach(municipality => {
          if (!municipalityNames.has(municipality.name)) {
            municipalityNames.add(municipality.name)
            const municipalityObject = {
              name: `${municipality.name}, ${municipality.provincia.name}`
            }
            uniqueMunicipalities.push(municipalityObject)
          }
        })
        const transformedCityData =
          uniqueMunicipalities?.map((city, index) => ({
            value: index,
            label: city?.name
          })) || []

        setMunicipalities(transformedCityData)
        const transformedCompanySizes =
          levelData.data.Message.companySizes?.map((item, index) => ({
            value: index,
            label: item.size
          })) || []

        setCompanySizes(transformedCompanySizes)
      }
    }
  }, [props.candidate.getlistData])

  const sortedCountryData = [...countryData].sort((a, b) => {
    if (a.nativeCountryName === 'Italia') return -1
    if (b.nativeCountryName === 'Italia') return 1
    return 0
  })

  function validateCompany () {
    const companyName = companyNameRef.current.value
    if (!companyName) {
      setErrorCompanyname('Inserisci la tua Ragione Sociale.')
      return false
    }
    const trimmedCompanyName = companyName.trim()
    if (!trimmedCompanyName) {
      setErrorCompanyname('Regione sociale non può essere vuoto.')
      return false
    }
    setErrorCompanyname('')
    return true
  }

  function validateDimension () {
    const dimension = dimensionRef?.current?.props?.value
    if (!dimension) {
      setErrorDimension('Inserisci la dimensione della tua azienda')
      return false
    }
    setErrorDimension('')
    return true
  }

  // const validateSDIcode = () => {
  //     const sdiCode = data?.sdi_code ? data.sdi_code.trim() : '';

  //     if (data?.sdi_code && sdiCode.length === 0) {
  //         setErrorSDIcode("Il Codice univoco SDI non può essere solo spazi. Per favore, inserisci un codice valido.");
  //         return false;
  //     }

  //     // if (sdiCode.length === 0) {
  //     //     setErrorSDIcode("Inserisci il tuo codice univoco SDI");
  //     //     return false;
  //     // }

  //     setErrorSDIcode('');
  //     return true;
  // };

  const validateVatNumber = () => {
    const vatNumber = data?.vat_number || ''

    if (vatNumber.length === 0) {
      setErrorVatNumber('Inserisci la tua partita IVA')
      return false
    }

    if (vatNumber.length !== 11) {
      setErrorVatNumber('Il numero di Partita IVA deve essere di 11 cifre.')
      return false
    }

    if (vatNumber.length === 11) {
      const vatDigits = vatNumber?.split('').map(Number)

      const sumX =
        vatDigits[0] + vatDigits[2] + vatDigits[4] + vatDigits[6] + vatDigits[8]
      const sumY =
        (vatDigits[1] * 2 > 9 ? vatDigits[1] * 2 - 9 : vatDigits[1] * 2) +
        (vatDigits[3] * 2 > 9 ? vatDigits[3] * 2 - 9 : vatDigits[3] * 2) +
        (vatDigits[5] * 2 > 9 ? vatDigits[5] * 2 - 9 : vatDigits[5] * 2) +
        (vatDigits[7] * 2 > 9 ? vatDigits[7] * 2 - 9 : vatDigits[7] * 2) +
        (vatDigits[9] * 2 > 9 ? vatDigits[9] * 2 - 9 : vatDigits[9] * 2)

      const totalSum = sumX + sumY
      const checkDigit = totalSum % 10
      const calculatedEleventhNumber = (10 - checkDigit) % 10

      if (calculatedEleventhNumber !== vatDigits[10]) {
        setErrorVatNumber('Numero di Partita IVA non valido.')
        return false
      }
    }

    setErrorVatNumber('')
    return true
  }

  function validateActivity () {
    const activity = activityRef.current.value
    if (!activity) {
      setErrorActivity('Inserisci la tua attività')
      return false
    }
    const trimmedActivity = activity.trim()
    if (!trimmedActivity) {
      setErrorActivity('Attivita non può essere vuoto.')
      return false
    }
    if (!/^[a-zA-Z0-9'@\s]+$/.test(trimmedActivity)) {
      setErrorActivity('Inserisci solo caratteri alfabetici.')
      return false
    }
    setErrorActivity('')
    return true
  }

  function validateCompanyEmail () {
    const company_email = companyEmailRef.current.value
    if (!company_email) {
      setErrorCompanyEmail('Inserisci la tua e-mail.')
      return false
    }
    const trimmedCompanyEmail = company_email.trim()
    if (!trimmedCompanyEmail) {
      setErrorCompanyEmail("L'e-mail non può essere vuota.")
      return false
    }
    if (
      !trimmedCompanyEmail.match(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      )
    ) {
      setErrorCompanyEmail('Inserisci un ID e-mail valido.')
      return false
    }
    setErrorCompanyEmail('')
    return true
  }

  function validateEmail (i) {
    let formIsValid = true

    if (!formFocusRefs.reference.current[i]) {
      formFocusRefs.reference.current[i] = {}
    }

    const emailFilled = formFocusRefs.reference.current[i].email?.value

    if (!emailFilled) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].email = 'Inserisci la tua e-mail.'
      setErrorReference(updatedErrorReference)
      const newList = [...reference]
      newList[i] = {
        ...newList[i],
        first_name: '',
        last_name: '',
        phone: '+39'
      }
      setReference(newList)
      const updatedEmailVerify = [...emailVerify]
      updatedEmailVerify[i] = false
      setEmailVerify(updatedEmailVerify)
    }

    const trimmedEmail = emailFilled?.trim()

    if (formIsValid && !trimmedEmail) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].email = "L'e-mail non può essere vuota."
      setErrorReference(updatedErrorReference)
      const newList = [...reference]
      newList[i] = {
        ...newList[i],
        first_name: '',
        last_name: '',
        phone: '+39'
      }
      setReference(newList)
      const updatedEmailVerify = [...emailVerify]
      updatedEmailVerify[i] = false
      setEmailVerify(updatedEmailVerify)
    }

    if (
      formIsValid &&
      !trimmedEmail?.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].email = 'Inserisci un ID e-mail valido.'
      setErrorReference(updatedErrorReference)
      const newList = [...reference]
      newList[i] = {
        ...newList[i],
        first_name: '',
        last_name: '',
        phone: '+39'
      }
      setReference(newList)
      const updatedEmailVerify = [...emailVerify]
      updatedEmailVerify[i] = false
      setEmailVerify(updatedEmailVerify)
    }
    if (formIsValid && !emailVerify[i]) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].email = 'Premi Invio o Tab per confermare.'
      setErrorReference(updatedErrorReference)
    }

    if (formIsValid) {
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].email = ''
      setErrorReference(updatedErrorReference)
    }

    return formIsValid
  }

  function validateFname (i) {
    let formIsValid = true
    const firstNameFilled = formFocusRefs.reference.current[i].first_name.value
    if (!firstNameFilled) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].first_name = 'Inserisci il tuo nome.'
      setErrorReference(updatedErrorReference)
    }

    const trimmedFirstName = firstNameFilled.trim()

    if (formIsValid && !trimmedFirstName) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].first_name = 'Il nome non può essere vuoto.'
      setErrorReference(updatedErrorReference)
    }

    if (formIsValid && !/^[a-zA-Z\s]+$/.test(trimmedFirstName)) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].first_name =
        'Inserisci solo caratteri alfabetici.'
      setErrorReference(updatedErrorReference)
    }

    if (formIsValid) {
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].first_name = ''
      setErrorReference(updatedErrorReference)
    }

    return formIsValid
  }

  function validateLname (i) {
    let formIsValid = true
    const lastNameFilled = formFocusRefs.reference.current[i].last_name.value

    if (!lastNameFilled) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].last_name = 'Inserisci il tuo cognome.'
      setErrorReference(updatedErrorReference)
    }

    const trimmedLastName = lastNameFilled.trim()

    if (formIsValid && !trimmedLastName) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].last_name = 'Il cognome non può essere vuoto.'
      setErrorReference(updatedErrorReference)
    }

    if (formIsValid && !/^[a-zA-Z\s]+$/.test(trimmedLastName)) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].last_name =
        'Inserisci solo caratteri alfabetici.'
      setErrorReference(updatedErrorReference)
    }

    if (formIsValid) {
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].last_name = ''
      setErrorReference(updatedErrorReference)
    }

    return formIsValid
  }

  const validatePhone = i => {
    let formIsValid = true
    const phone_no = formFocusRefs.reference.current[i].phone.props.value

    if (!phone_no) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].phone = 'Inserisci il numero di telefono.'
      setErrorReference(updatedErrorReference)
    } else if (phone_no.length < 5) {
      formIsValid = false
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].phone =
        'Il numero di telefono deve contenere almeno 5 cifre.'
      setErrorReference(updatedErrorReference)
    }

    if (formIsValid) {
      const updatedErrorReference = [...errorreference]
      updatedErrorReference[i].phone = ''
      setErrorReference(updatedErrorReference)
    }

    return formIsValid
  }

  //================= Validation Sector ===============================

  const validateSector = () => {
    if (!sectorRef?.current?.props?.value) {
      setErrorSector('Seleziona il tuo Settore.')
      return false
    } else {
      setErrorSector('')
      return true
    }
  }

  /*useEffect(() => {
    validateSubSector();
  }, [selectedOptionsSector]);
*/
  const validateSubSector = () => {
    if (subsectorRef?.current) {
      const subSectorMenu = subsectorRef.current.value
      if (!subSectorMenu) {
        setErrorSubSector('Seleziona il tuo Sottosettore.')
        return false
      }
    }
    setErrorSubSector('')
    return true
  }

  function validateStreetNumber () {
    const street_number = streetNumberRef.current.value
    if (!street_number) {
      seterrorStreetnumber('Inserisci il numero civico.')
      return false
    }
    const trimmedStreetnumber = street_number.trim()
    if (!trimmedStreetnumber) {
      seterrorStreetnumber('Il numero civico non può essere vuoto.')
      return false
    }
    seterrorStreetnumber('')
    return true
  }

  function validateZipCode () {
    const zip_code = zipCodeRef.current.value
    if (!zip_code) {
      seterrorZipcode('Inserisci il tuo Cap.')
      return false
    }
    const trimmedZipcode = zip_code.trim()
    if (!trimmedZipcode) {
      seterrorZipcode('Il CAP non può essere vuoto.')
      return false
    }
    if (!/^\d+$/.test(trimmedZipcode)) {
      seterrorZipcode('Il CAP può contenere solo numeri.')
      return false
    }
    if (trimmedZipcode.length < 5) {
      seterrorZipcode('Il CAP deve contenere almeno 5 cifre.')
      return false
    }
    seterrorZipcode('')
    return true
  }

  function validateCountry () {
    if (countryRef.current !== null) {
      const country = countryRef.current.value
      if (!country) {
        seterrorCountry('Nazione non selezionato.')
        return false
      }
      seterrorCountry('')
      return true
    }
  }

  function validateProvince () {
    if (provinceRef?.current !== null) {
      const province = provinceRef?.current?.value
      if (!province) {
        seterrorProvince('Inserisci la tua provincia.')
        return false
      }
      seterrorProvince('')
      return true
    } else {
      return true
    }
  }

  const validateCity = () => {
    let city

    if (data?.country === 'Italia') {
      if (data !== 'undefined') {
        if (!data?.city || !data?.province) {
          seterrorCity('Comune non selezionato.')
          return false
        }
      }
    } else {
      if (cityRef?.current) {
        city = cityRef.current.value ?? ''
        if (!city.trim()) {
          seterrorCity('Inserisci la tua Comune.')
          return false
        }
      }
    }
    seterrorCity('')
    return true
  }

  const validatePrivacy = () => {
    const privacy = eula_consentRef.current.checked
    if (!privacy) {
      setErrorPrivacyAccepted('Questo campo è obbligatorio.')
      return false
    } else {
      setErrorPrivacyAccepted('')
      return true
    }
  }

  const validatePrivacyContent = () => {
    const privacy = privacy_consentRef.current.checked
    if (!privacy) {
      setErrorPrivacyContentAccepted('Questo campo è obbligatorio.')
      return false
    } else {
      setErrorPrivacyContentAccepted('')
      return true
    }
  }

  const generatePassword = () => {
    const pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/
    const characters =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@$!%*?&'
    let password = ''
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      password += characters.charAt(randomIndex)
    }
    if (!pattern.test(password)) {
      return generatePassword()
    }
    return password
  }

  const handleAutoGeneratePassword = () => {
    const newPassword = generatePassword()
    setAutoPassword(newPassword)
    localStorage.setItem('cazzomaveramente', newPassword)
  }

  const onChangeData = e => {
    const { name, value, type, files } = e.target
    let newValue

    if (type === 'number') {
      newValue = value.replace(/\D/g, '').slice(0, 5)
    } else if (type === 'file') {
      newValue = files[0]
    } else {
      newValue = value
    }

    if (name === 'country') {
      if (value === 'Italia') {
        setData(prevData => ({
          ...prevData,
          country: newValue,
          city: '',
          province: ''
        }))
      } else {
        setData(prevData => ({
          ...prevData,
          country: newValue,
          city: '',
          province: ''
        }))
      }
    } else {
      setData(prevData => ({ ...prevData, [name]: newValue }))
    }
  }

  const handleChangeSDICode = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleOnVatnumber = e => {
    const value = e.target.value
    const trimmedValue = value.replace(/\D/g, '').slice(0, 11)
    setData({ ...data, [e.target.name]: trimmedValue })
  }

  //===========================Sector Level ==============================

  const handleSectorLevelChange = e => {
    setIsLoading(true)
    if (e !== null) {
      setTimeout(() => {
        // HAHAHAHAH
        const updatedSelectedOptions = e ? e.label : ''
        setData(prevData => ({
          ...prevData,
          sector: updatedSelectedOptions
        }))
        setSelectedOptionsSector(prevOptions => ({
          ...prevOptions,
          submenu: updatedSelectedOptions,
          subSubmenu: ''
        }))
        setIsLoading(false)
        setErrorSector('')
      }, 50)
    } else {
      setData(prevData => ({ ...prevData, sector: '' }))
      setSelectedOptionsSector(prevOptions => ({
        ...prevOptions,
        submenu: '',
        subSubmenu: ''
      }))
      setIsLoading(false)
      validateSector()
    }
  }

  const handleSubSectorLevelChange = e => {
    const { value } = e.target

    setSelectedOptionsSector(prevOptions => {
      const newOptions = {
        ...prevOptions,
        subSubmenu: value
      }

      const sector = `${newOptions.submenu}, ${value}`
      setData(prevData => ({
        ...prevData,
        subsector: value
      }))

      return newOptions
    })
  }

  // const handleInputReference = (e, i) => {
  //     let { name, value } = e.target || { name: "phone", value: e };

  //     if (name === "phone" && value && !value.startsWith("+")) {
  //         value = "+" + value;
  //     }

  //     console.log(value,"value");

  //     const newList = [...reference];
  //     newList[i][name] = value;
  //     setReference(newList);
  // };

  const handleInputReference = (e, i) => {
    let { name, value } = e.target || { name: 'phone', value: e }

    if (name === 'phone' && value && !value.startsWith('+')) {
      value = '+' + value
    }

    const updatedReference = [...reference]

    updatedReference[i][name] = value

    if (name === 'email' && !emailChangeVerify[i]) {
      updatedReference[i] = {
        ...updatedReference[i],
        first_name: '',
        last_name: '',
        phone: '+39'
      }

      delete updatedReference[i].password
      delete updatedReference[i].password2
    }

    if (emailChangeVerify[i] && name === 'email') {
      const updatedEmailChanfeVerify = [...emailChangeVerify]
      updatedEmailChanfeVerify[i] = false
      setEmailChangeVerify(updatedEmailChanfeVerify)
      const updatedEmailVerify = [...emailVerify]
      updatedEmailVerify[i] = false
      setEmailVerify(updatedEmailVerify)
    }
    setReference(updatedReference)
  }

  const generateToken = email => {
    const header = {
      alg: 'none',
      typ: 'JWT'
    }

    const payload = {
      email: email,
      exp: Math.floor(Date.now() / 1000) + 5 * 60
    }

    const base64UrlEncode = str => {
      return str.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
    }

    const base64UrlHeader = base64UrlEncode(JSON.stringify(header))
    const base64UrlPayload = base64UrlEncode(JSON.stringify(payload))

    const token = `${base64UrlHeader}.${base64UrlPayload}`

    return token
  }

  const handleOnEmailVerify = async (x, i) => {
    // const isEmailValid = validateEmail(i);

    // if (!isEmailValid) {
    //     const newList = [...reference];
    //     newList[i].email = x.email;
    //     newList[i] = {
    //         ...newList[i],
    //         first_name: "",
    //         last_name: "",
    //         phone: "+39",
    //     };
    //     setReference(newList)
    //     return;
    // }

    try {
      const token = generateToken(x.email)
      const objData = {
        token: token,
        username: x.email,
        index: i
      }
      setJwtToken(objData)
      await props.requestCompanyJWT({ data: { token } })
    } catch (error) {
      console.error('Failed to fetch candidate data:', error)
    }
  }

  useEffect(() => {
    const companyJwt = props.company.companyJwt
    console.log(companyJwt, 'companyJwt')
    if (companyJwt !== undefined) {
      if (+companyJwt.status === 403) {
        toast.error(companyJwt?.data?.Message, {
          theme: 'colored',
          autoClose: 8000
        })
      } else if (companyJwt.data.Status === 'Success') {
        if (jwtToken) {
          props.requestCompanyReference({
            data: { username: jwtToken.username },
            token: jwtToken.token
          })
          ReferIndexRef.current = jwtToken.index
        }
      } else if (companyJwt.data.Status === 'Failed') {
        toast.error(companyJwt?.data?.Message, {
          theme: 'colored',
          autoClose: 8000
        })
      }
    }
  }, [props.company.companyJwt])

  useEffect(() => {
    const candidateReferData = props.company.referenceData
    if (candidateReferData) {
      setLoadingForRefer(true)
      const timer = setTimeout(() => {
        const newList = [...reference]
        const indexToUpdate = ReferIndexRef.current
        if (indexToUpdate !== -1) {
          if (candidateReferData?.data?.Status === 'Success') {
            const updateData = candidateReferData?.data?.Message || {}
            newList[indexToUpdate] = {
              ...newList[indexToUpdate],
              email: reference[indexToUpdate]?.email || '',
              first_name: updateData.name || '',
              last_name: updateData.surname || '',
              phone: updateData.cellphone || ''
            }
            toast.success('Successo', {
              theme: 'colored',
              autoClose: 8000
            })
          } else if (candidateReferData?.data?.Status === 'Failed') {
            newList[indexToUpdate] = {
              ...newList[indexToUpdate],
              email: reference[indexToUpdate]?.email || '',
              first_name: '',
              last_name: '',
              phone: '+39',
              password: '',
              password2: ''
            }
            toast.error(candidateReferData?.data?.Message, {
              theme: 'colored',
              autoClose: 8000
            })
          }
          const updatedEmailVerify = [...emailVerify]
          updatedEmailVerify[indexToUpdate] = true
          setEmailVerify(updatedEmailVerify)

          const updatedEmailChanfeVerify = [...emailChangeVerify]
          updatedEmailChanfeVerify[indexToUpdate] = true
          setEmailChangeVerify(updatedEmailChanfeVerify)
          setReference(newList)
        }
        setLoadingForRefer(false)
        props.userLogout()
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [props.company.referenceData, reference])

  const handleAddReference = () => {
    let length = reference.length
    if (length === 0) {
      setReference([
        ...reference,
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ])

      setErrorReference([
        ...errorreference,
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ])
    } else if (
      reference[length - 1].email === '' ||
      reference[length - 1].first_name === '' ||
      reference[length - 1].last_name === '' ||
      reference[length - 1].phone === '+39'
    ) {
      Swal.fire(
        'Errore!',
        'Dopo aver completato quello precedente, puoi aggiungerne uno nuovo.',
        'error'
      )
      return
    } else {
      setReference([
        ...reference,
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ])
      setErrorReference([
        ...errorreference,
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ])
    }
  }

  const handleRemoveReference = index => {
    let updateReference = [...reference]

    if (index) {
      updateReference.splice(index, 1)
    } else {
      updateReference = [
        {
          email: '',
          first_name: '',
          last_name: '',
          phone: ''
        }
      ]
    }
    setReference(updateReference)

    const updateErrorReference = [...errorreference]
    index && updateErrorReference.splice(index, 1)
    setErrorReference(updateErrorReference)
  }

  const handleChangeCity = async selectedOptions => {
    setIsLoading(true)
    if (selectedOptions !== null) {
      setTimeout(() => {
        const [city, province] = selectedOptions?.label?.split(', ')
        setData(prevData => ({
          ...prevData,
          city: city,
          province: province
        }))
        setSelectedCity(selectedOptions)
        setIsLoading(false)
        seterrorCity('')
      }, 1000)
    } else {
      setData(prevData => ({
        ...prevData,
        city: '',
        province: ''
      }))
      setSelectedCity(null)
      setIsLoading(false)
    }
  }

  const handleOndimension = selectedOption => {
    if (selectedOption) {
      setData({ ...data, dimension: selectedOption.label })
    } else {
      setData({ ...data, dimension: '' })
    }
  }

  const handleCheckboxChange1 = e => {
    setPrivacyAccepted(e.target.checked)
    if (e.target.checked) {
      setErrorPrivacyAccepted('')
    }
    validatePrivacy()
  }

  const handleCheckboxChange2 = e => {
    setPrivacyContentAccepted(e.target.checked)
    if (e.target.checked) {
      setErrorPrivacyContentAccepted('')
    }
    validatePrivacyContent()
  }

  // const validateReferenceForm = () => {
  //     if (reference.length > 0) {
  //         return reference.every((_, i) => validateEmail(i));
  //     }
  //     return false;
  // };

  const validateReferenceForm = () => {
    if (reference.length > 0) {
      return reference.every((_, i) => {
        return (
          validateEmail(i) &&
          validateFname(i) &&
          validateLname(i) &&
          validatePhone(i)
        )
      })
    }
    return false
  }

  useEffect(() => {
    reference?.forEach((lang, i) => {
      if (emailVerify[i]) {
        if (validateEmail(i)) {
          validateFname(i)
          validateLname(i)
          validatePhone(i)
        }
      }
    })
  }, [reference])

  function validateForm () {
    let companyN = validateCompany()
    let dimensionV = validateDimension()
    // let sdiCodeV = validateSDIcode();
    let activityV = validateActivity()
    let vatnumberV = validateVatNumber()
    let Companyemail = validateCompanyEmail()
    let sectorV = validateSector()
    let subsectorV = validateSubSector()
    let streetNum = validateStreetNumber()

    // let referalData = validateReferenceForm();
    let zipCode = validateZipCode()
    let countryV = validateCountry()
    let cityV = validateCity()
    let provinceV = validateProvince()
    let privacy = validatePrivacy()
    let privacyContent = validatePrivacyContent()

    const validall =
      companyN &&
      dimensionV &&
      // sdiCodeV &&
      activityV &&
      vatnumberV &&
      Companyemail &&
      sectorV &&
      subsectorV &&
      // referalData &&
      streetNum &&
      zipCode &&
      countryV &&
      cityV &&
      provinceV &&
      privacy &&
      privacyContent
    return validall
  }

  useEffect(() => {
    const focusErrorField = () => {
      if (error) {
        if (errorCompanyname && companyNameRef.current) {
          companyNameRef?.current?.focus()
          return true
        }
        if (errorDimension && dimensionRef.current) {
          dimensionRef?.current?.focus()
          return true
        }
        // if (errorSDIcode && sdiCodeRef.current) {
        //     sdiCodeRef.current.focus();
        //     return true;
        // }
        if (errorVatNumber && vatNumberRef.current) {
          vatNumberRef?.current?.focus()
          return true
        }
        if (errorActivity && activityRef.current) {
          activityRef?.current?.focus()
          return true
        }
        if (errorCompanyEmail && companyEmailRef.current) {
          companyEmailRef.current.focus()
          return true
        }
        if (errorSector && sectorRef.current) {
          sectorRef.current.focus()
          return true
        }
        if (errorSubSector && subsectorRef.current) {
          subsectorRef.current.focus()
          return true
        }

        // for (let i = 0; i < errorreference.length; i++) {
        //   const referError = errorreference[i];
        //   if (referError.email) {
        //     formFocusRefs.reference.current[i]?.email?.focus();
        //     return true;
        //   } else if (referError.first_name) {
        //     formFocusRefs.reference.current[i]?.first_name?.focus();
        //     return true;
        //   } else if (referError.last_name) {
        //     formFocusRefs.reference.current[i]?.last_name?.focus();
        //     return true;
        //   }
        //   // else if (referError.phone) {
        //   //     formFocusRefs.reference.current[i]?.phone.focus();
        //   //     return true;
        //   // }
        //   else if (referError.phone && formFocusRefs?.reference?.current[i]) {
        //     formFocusRefs?.reference?.current[i]?.phone?.numberInputRef.focus();
        //   }
        // }
        // if (errorCountry && countryRef.current) {
        //   countryRef.current.focus();
        //   return true;
        // }
        // if (errorStreetnumber && streetNumberRef.current) {
        //   streetNumberRef.current.focus();
        //   return true;
        // }
        // if (errorCity && cityRef.current) {
        //   cityRef.current.focus();
        //   return true;
        // }
        // if (errorProvince && provinceRef.current) {
        //   provinceRef.current.focus();
        //   return true;
        // }
        // if (errorZipcode && zipCodeRef.current) {
        //   zipCodeRef.current.focus();
        //   return true;
        // }
        // if (errorprivacyAccepted && eula_consentRef.current) {
        //   eula_consentRef.current.focus();
        //   return true;
        // }
        // if (errorPrivacyContentAccepted && privacy_consentRef.current) {
        //   privacy_consentRef.current.focus();
        //   return true;
        // }
      }
      return false
    }

    if (focusErrorField()) {
      setError(false)
    }
  }, [
    error,
    errorCompanyname,
    errorDimension,
    // errorSDIcode,
    errorVatNumber,
    errorActivity,
    errorSector,
    errorSubSector,
    formFocusRefs,
    errorreference,
    errorCountry,
    errorCity,
    errorProvince,
    errorStreetnumber,
    errorZipcode,
    errorprivacyAccepted,
    errorPrivacyContentAccepted
  ])

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const email = location.state?.email
    const social_uid = location.state?.social_uid // Changed from uid
    if (email) {
      setData(prevData => ({
        ...prevData,
        email: email
      }))
    }
  }, [location.state])

  function onSubmitForm (e) {
    e.preventDefault()
    props.userLogout()

    if (validateForm()) {
      const modifiedReference = reference.map(
        ({ phone, password, password2, ...rest }) => ({
          ...rest,
          username: rest.email,
          ...(password !== undefined && { password: autopassword }),
          ...(password2 !== undefined && { password2: autopassword }),
          eula_consent: privacyAccepted,
          privacy_consent: privacyContentAccepted,
          cellphone: phone
        })
      )

      let formData = {
        name: data.company_name,
        e_mail: data.company_email,
        password: autopassword,
        password2: autopassword,
        type: data.activity,
        vat_number: data.vat_number,
        size: data.dimension,
        ...(modifiedReference[0].email && { reps: modifiedReference }),
        sdi: data.sdi_code,
        social_uid: loc.state?.social_uid, // Changed from uid
        address: {
          street: data.street_number,
          city: data.city,
          zip_code: data.zip_code,
          state: null,
          province: data.province,
          country: data.country
        },

        eula_consent: privacyAccepted,
        privacy_consent: privacyContentAccepted,

        ...(searchParams.get('type') === 'franchisor' && {
          flag_franchisor: true,
          franchisor: {
            brand: location.state?.formData?.company_name,
            year_brand_creation:
              location.state?.formData?.founding_date?.split('-')[0],
            area_min: location.state?.formData?.min_mq,
            financeable: location.state?.formData?.financeable
          },
          ...location.state?.formData
        }),

        ...(searchParams.get('type') === 'franchisee' && {
          flag_franchisee: true
        })
      }

      formData = {
        ...formData,
        sector: {
          sectorName: data.sector ?? +selectedOptionsSector.submenu.value
        },
        sub_sector: {
          subsector_name:
            data.subsector ?? +location.state?.formData?.sub_sector
        }
      }

      if (
        searchParams.get('type') === 'professional' ||
        searchParams.get('type') === 'artisan'
      ) {
        navigate(
          `/offered-services?type=${searchParams.get('type')}&registering=true`,
          {
            state: {
              formData
            }
          }
        )
      } else {
        let fn = props.requestCompanyRegister
        if (props.company?.getCompanyData?.status === 200) {
          fn = props.requestCompanyUpdate
        }
        fn({
          data: formData
        })
      }

      if (searchParams.get('type') === 'franchisee') {
        props.requestGetCVList({ data: location.state?.filterData })
      }

      setError(false)
      setSubmitLoading(true)
    } else {
      setError(true)
      setSubmitLoading(false)
    }
  }

  useEffect(() => {
    if (location.state?.formData) {
      setData(prevData => ({
        ...prevData,
        company_name: location.state?.formData.company_name
      }))
      setSelectedOptionsSector(prevOptions => ({
        ...prevOptions,
        submenu: sectorSubsectorOptions.sector?.find(
          s => +s.value === +location.state?.formData.sector?.[0]?.sectorName
        ),
        subSubmenu: sectorSubsectorOptions.subsector?.find(
          s => +s.value === +location.state?.formData.sub_sector
        )
      }))
    }
  }, [location.state?.formData, sectorSubsectorOptions])

  useEffect(() => {
    let cvFindData = props.franchisor.cvListData
    if (cvFindData !== undefined && searchParams.get('type') === 'franchisee') {
      if (cvFindData?.data?.Status === 'Success') {
        if (
          Array.isArray(cvFindData.data.Message) &&
          cvFindData.data.Message.length === 0
        ) {
          toast.error('No CV found', { theme: 'colored', autoClose: 8000 })
        } else {
          navigate('/franchisor/search-results', {
            state: { jobListData: cvFindData?.data?.Message }
          })
          toast.success(
            `Sono stati trovati ${cvFindData.data.Message.length} annunci`,
            {
              theme: 'colored',
              autoClose: 8000
            }
          )
        }
        props.franchisor.cvListData = undefined
      } else {
        toast.error(cvFindData.data.Message, {
          theme: 'colored',
          autoClose: 8000
        })
        props.franchisor.cvListData = undefined
      }
    }
  }, [props.franchisor.cvListData, searchParams])

  useEffect(() => {
    let RegisterData = props.company?.comRegisterData
    setSubmitLoading(false)
    if (!RegisterData && props.company?.getCompanyData?.status === 200)
      RegisterData = props.company?.getCompanyData?.data?.Message
    if (RegisterData !== undefined) {
      if (
        RegisterData?.data?.Status === 'Success' ||
        props.company?.getCompanyData?.status === 200
      ) {
        if (searchParams.get('type') === 'franchisee') {
          navigate('/franchisor/search2', {
            state: {
              filterData: location.state?.formData
            }
          })
          return
        }
        toast.success('Completato', {
          theme: 'colored',
          autoClose: 8000
        })
        let url = '/'
        if (searchParams.get('type') === 'professional') {
          // url = "/notifications?type=professional&registering=true";
          url = '/display-name?type=professional&registering=true'
        } else if (searchParams.get('type') === 'artisan') {
          url = '/display-name?type=artisan&registering=true'
        }
        navigate(url)
        props.company.comRegisterData = undefined
        if (searchParams.get('type') === 'franchisor') {
          navigateTo('/franchisor/register2', {
            state: {
              formData: location.state?.formData
            }
          })
        }
      } else {
        toast.error(RegisterData?.data?.Message ?? 'An error occurred', {
          theme: 'colored',
          autoClose: 8000
        })
        setError(true)
        props.company.comRegisterData = undefined
      }
    }
  }, [props.company.comRegisterData, searchParams])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      props.requestCompanyDetails({ data: { token } })
    }
  }, [])

  useEffect(() => {
    setData(p => ({
      ...p,
      sector: location.state?.sector,
      subsector: location.state?.subsector
    }))
    setSelectedOptionsSector(p => ({
      ...p,
      submenu: location.state?.sector,
      subSubmenu: location.state?.subsector
    }))
  }, [location.state?.sector, location.state?.subsector])

  return (
    <>
      <Header />
      <div className='registration_sec'>
        <div className='container'>
          <form className='registration_form' onSubmit={onSubmitForm}>
            <div className='registration_title'>
              <h3>
                Registrati come{' '}
                {searchParams.get('type') === 'professional'
                  ? 'Professionista'
                  : searchParams.get('type') === 'artisan'
                  ? 'Artigiano'
                  : searchParams.get('type') === 'franchisor'
                  ? 'Franchisor'
                  : searchParams.get('type') === 'franchisee'
                  ? 'Franchisee'
                  : 'Azienda'}
              </h3>
            </div>
            <div className='row registration_row g-4'>
              <div className='registration_col form-group col-md-6 col-12'>
                <label htmlFor='company_name' className='label'>
                  Nome / Ragione Sociale
                </label>
                <input
                  type='text'
                  className={`form-control set_max_width ${
                    errorCompanyname ? 'error' : ''
                  }`}
                  placeholder='Nome / Ragione Sociale'
                  id='company_name'
                  name='company_name'
                  ref={companyNameRef}
                  value={data.company_name || ''}
                  onChange={onChangeData}
                  onBlur={validateCompany}
                />
                {errorCompanyname && (
                  <div style={mystyle}>{errorCompanyname}</div>
                )}
              </div>
              <div className='registration_col form-group col-md-6 col-12'>
                <label htmlFor='dimension' className='label'>
                  Dimensione
                </label>

                <Select
                  name='dimension'
                  options={companySizes}
                  className={`form-control set_max_width select_dropdown ${
                    errorDimension ? 'error' : ''
                  }`}
                  value={
                    data.dimension
                      ? { label: data.dimension, value: data.dimension }
                      : null
                  }
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  ref={dimensionRef}
                  onChange={handleOndimension}
                  onBlur={validateDimension}
                  filterOption={createFilter(filterConfig)}
                  placeholder='Dimensione'
                />
                {errorDimension && <div style={mystyle}>{errorDimension}</div>}
              </div>

              <SDICode
                data={data}
                handleChangeSDICode={handleChangeSDICode}
                sdiCodeRef={sdiCodeRef}
                // validateSDIcode={validateSDIcode}
                errorSDIcode={errorSDIcode}
                mystyle={mystyle}
                t={t}
              />

              <VatValidator
                data={data}
                handleOnVatnumber={handleOnVatnumber}
                validateVatNumber={validateVatNumber}
                errorVatNumber={errorVatNumber}
                vatNumberRef={vatNumberRef}
                mystyle={mystyle}
              />
              <div className='registration_col form-group col-md-6 col-12'>
                <label htmlFor='activity' className='label'>
                  Attività
                </label>
                <input
                  type='text'
                  className={`form-control set_max_width ${
                    errorActivity ? 'error' : ''
                  }`}
                  placeholder='Inserisci l’attività che offri'
                  id='activity'
                  name='activity'
                  ref={activityRef}
                  value={data.activity || ''}
                  onChange={onChangeData}
                  onBlur={validateActivity}
                />
                {errorActivity && <div style={mystyle}>{errorActivity}</div>}
              </div>

              <div className='registration_col form-group col-md-6 col-12'>
                <label htmlFor='email' className='label'>
                  {/* E-mail */}
                  {t('register.email')}
                </label>
                <input
                  type='email'
                  className={`form-control set_max_width ${
                    errorCompanyEmail ? 'error' : ''
                  }`}
                  placeholder={t('register.email')}
                  id='company_email'
                  name='company_email'
                  ref={companyEmailRef}
                  value={data.company_email || location.state?.email || ''}
                  onChange={onChangeData}
                  onBlur={validateCompanyEmail}
                />
                {errorCompanyEmail && (
                  <div style={mystyle}>{errorCompanyEmail}</div>
                )}
              </div>

              <SectorLevel
                selectedOptionsSector={selectedOptionsSector}
                handleSectorLevelChange={handleSectorLevelChange}
                validateSector={validateSector}
                sectorRef={sectorRef}
                errorSector={errorSector}
                handleSubSectorLevelChange={handleSubSectorLevelChange}
                validateSubSector={validateSubSector}
                subsectorRef={subsectorRef}
                errorSubSector={errorSubSector}
                mystyle={mystyle}
                haveSubSector={selectedOptionsSector?.subSubmenu}
                t={t}
                onChangeSectorSubsector={setSectorSubsectorOptions}
              />
            </div>

            <Referent
              //===============email======================

              reference={reference}
              errorreference={errorreference}
              handleInputReference={handleInputReference}
              formFocusRefs={formFocusRefs}
              validateEmail={validateEmail}
              loadingForRefer={loadingForRefer}
              //===============first name=====================

              validateFname={validateFname}
              //===============last name======================

              validateLname={validateLname}
              //===============telephone======================
              validatePhone={validatePhone}
              handleAddReference={handleAddReference}
              handleRemoveReference={handleRemoveReference}
              mystyle={mystyle}
              t={t}
              handleOnEmailVerify={handleOnEmailVerify}
              emailVerify={emailVerify}
              // setEmailVerify={setEmailVerify}
            />

            <div className='contry_form'>
              <h6 className='contry_form_title'>INDIRIZZO</h6>
              <LocationForm
                data={data}
                //==================Country================

                countryRef={countryRef}
                onChangeData={onChangeData}
                validateCountry={validateCountry}
                sortedCountryData={sortedCountryData}
                errorCountry={errorCountry}
                //===========Street=========================

                validateStreetNumber={validateStreetNumber}
                streetNumberRef={streetNumberRef}
                errorStreetnumber={errorStreetnumber}
                //=============ProvinceCommue===============

                municipalities={municipalities}
                selectedCity={selectedCity}
                isLoading={isLoading}
                handleChangeCity={handleChangeCity}
                cityRef={cityRef}
                validateCity={validateCity}
                errorCity={errorCity}
                provinceRef={provinceRef}
                validateProvince={validateProvince}
                errorProvince={errorProvince}
                //=======ZipCode=====================

                zipCodeRef={zipCodeRef}
                validateZipCode={validateZipCode}
                errorZipcode={errorZipcode}
                mystyle={mystyle}
                t={t}
              />
            </div>

            <TermsAndService
              privacyAccepted={privacyAccepted}
              privacyContentAccepted={privacyContentAccepted}
              handleCheckboxChange1={handleCheckboxChange1}
              handleCheckboxChange2={handleCheckboxChange2}
              validatePrivacy={validatePrivacy}
              validatePrivacyContent={validatePrivacyContent}
              errorprivacyAccepted={errorprivacyAccepted}
              errorPrivacyContentAccepted={errorPrivacyContentAccepted}
              eula_consentRef={eula_consentRef}
              privacy_consentRef={privacy_consentRef}
              mystyle={mystyle}
              t={t}
            />

            <div className='registration_button text-center'>
              <button
                type='submit'
                className='btn'
                onClick={handleAutoGeneratePassword}
              >
                {submitLoading ? (
                  <img
                    style={{
                      width: '100%',
                      height: '60%',
                      objectFit: 'contain'
                    }}
                    src={loaderLogo}
                  />
                ) : (
                  'Registra la mia attività!'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return {
    company: state.company,
    candidate: state.candidate,
    franchisor: state.franchisor
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestGetList,
      requestGetCandidate,
      requestCompanyJWT,
      requestCompanyReference,
      requestCompanyDetails,
      requestCompanyRegister,
      userLogout,
      requestGetCVList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Register)
