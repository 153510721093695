// File: /src/Franchisor/register/Register2.js
// Created Date: Friday September 20th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Friday September 20th 2024 5:47:44 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Ckeditor from "../../Company/Create-and-ad/step3/Ckeditor";
import Select from "react-select";
import BackButton from "../../Company/Create-and-ad/common/BackButton";
import NextButton from "../../Company/Create-and-ad/common/NextButton";
import { useAppNavigation } from "../../utils/routing";
import { connect } from "react-redux";
import {
  requestCompanyDetails,
  requestCompanyRegister,
  requestCompanyUpdate,
  requestGetSectorList,
  requestRegister,
  requestPublishFranchisingAd,
  requestRemovePublishFranchisingAd,
  requestLogin,
} from "../../Redux/actions";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import { publishFranchisingAd } from "../../Redux/api";
import Swal from "sweetalert2";

const Register2 = (props) => {
  const [formSettings, setFormSettings] = useState({
    slogan: {
      type: "text",
      label: "Slogan",
    },
    country: {
      type: "select",
      label: "Paese",
      disabled: true,
      defaultValue: { label: "Italia", value: "Italia" },
      options: [{ label: "Italia", value: "Italia" }],
    },
    language: {
      type: "select",
      label: "Lingua",
      disabled: true,
      defaultValue: { label: "Italiano", value: "Italiano" },
      options: [{ label: "Italiano", value: "Italiano" }],
    },
    min_investment: {
      type: "number",
      label: "Investimento minimo",
    },
    logo: {
      type: "file",
      label: "Logo",
    },
    street: {
      type: "text",
      label: "Via",
    },
    city: {
      type: "text",
      label: "Città",
    },
    zip_code: {
      type: "text",
      label: "CAP",
    },
    province: {
      type: "text",
      label: "Provincia",
    },
    founding_date: {
      type: "date",
      label: "Anno di fondazione",
    },
    n_store: {
      type: "number",
      label: "Punti vendita",
    },
    min_mq: {
      type: "number",
      label: "Mq. minimi",
    },
    financeable: {
      type: "select",
      label: "Finanziabile",
      options: [
        { label: "Si", value: true },
        { label: "No", value: false },
      ],
    },
    description: {
      type: "richtext",
      label: "Descrizione",
    },
  });
  const [data, setData] = useState({
    country: { label: "Italia", value: "Italia" },
    language: { label: "Italiano", value: "Italiano" },
  });

  const location = useLocation();

  const { navigateTo } = useAppNavigation();

  useEffect(() => {
    const accessToken = props.candidate?.loginData?.data.Message.access;
    if (!accessToken) return;
    props.requestCompanyDetails({ data: { token: accessToken } });
  }, [props.candidate?.loginData]);

  useEffect(() => {
    if (props.company?.getCompanyData) {
      setData(props.company?.getCompanyData);
    }
  }, [props.company?.getCompanyData]);

  const prevStep = () => {
    navigateTo("/franchisor/register");
  };

  const formRef = useRef();

  const nextStep = (e) => {
    formRef.current.dispatchEvent(new Event("submit", { bubbles: true }));
  };

  const [formDataDiQuestoForm, setFormDataDiQuestoForm] = useState({});
  const [franchisorDaUsareDopoLogin, setFranchisorDaUsareDopoLogin] = useState(
    {}
  );

  const isPublishing = useRef(false);

  const onFormSubmit = (e) => {
    e.preventDefault();

    isPublishing.current = true;

    let formData = {
      country: data.country,
      language: data.language,
    };
    const formElements = e.target.elements;

    formData.description = data.description;

    if (!formData.description) {
      const el = document.querySelector(".jodit-react-container");
      el.classList.add("error-jodit");
    } else {
      const el = document.querySelector(".jodit-react-container");
      el.classList.remove("error-jodit");
    }

    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];

      if (!element.name) continue;
      if (
        [...element.classList].some((className) =>
          className.includes("jodit")
        ) ||
        element.role === "combobox"
      )
        continue;

      let container =
        element.parentElement?.parentElement?.parentElement?.parentElement;

      if (element.parentElement.classList.contains("form-control")) {
        container = element.parentElement;
      }

      if (
        typeof element.value === "string" &&
        element.value?.length &&
        typeof element.value !== "undefined"
      ) {
        formData[element.name] = element.value;
        if (element.name === "sector" || element.name === "sub_sector") {
          container = element.parentElement;
        }
        container?.classList.remove("error");
        if (container.parentElement.querySelector(".error-message")) {
          container.classList.remove("error");
          const errorMessage =
            container?.parentElement?.querySelector(".error-message");
          if (errorMessage) {
            errorMessage.remove();
          }
        }
      } else {
        element.focus();

        if (!container.classList.contains("seeking_job_inner")) {
          container.classList.add("error");
        }

        if (
          !container.parentElement.querySelector(".error-message") &&
          !container.parentElement.classList.contains("jodit-toolbar__box") &&
          !container.parentElement.classList.contains("jodit-container") &&
          !container.parentElement.classList.contains("jodit-react-container")
        ) {
          const errorMessage = document.createElement("p");
          errorMessage.textContent = "Questo campo è obbligatorio.";
          errorMessage.style.color = "#D10000";
          errorMessage.style.marginTop = "4px";
          errorMessage.classList.add("error-message");
          container.parentElement?.appendChild(errorMessage);
        }
      }
    }

    if (!Object.entries(formData ?? {}).length) return;

    if (
      Object.values(formData).every(Boolean) &&
      Object.values(formData).length === Object.keys(formSettings).length
    ) {
      const franchisor = {
        brand: props.company?.getCompanyData?.data?.Message?.name,
        year_brand_creation: formData.founding_date?.split("-")[0],
        area_min: formData.min_mq,
        financeable: formData.financeable,
      };

      formData = {
        ...formData,
        franchisor,
        published: true,
      };

      setFormDataDiQuestoForm(formData);
      setFranchisorDaUsareDopoLogin(franchisor);

      props.requestLogin({
        data: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("cazzomaveramente"),
        },
      });
    } else {
      Swal.fire({
        title: "Errore",
        text: "Compila tutti i campi obbligatori",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    if (!props.franchisor?.publishFranchisingAdData) return;
    if (props.franchisor?.publishFranchisingAdData?.data.Message) {
      Swal.fire({
        title: "Pubblicazione effettuata",
        text: "La tua offerta franchisor è stata pubblicata con successo",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        navigateTo("/");
        props.requestRemovePublishFranchisingAd({});
      });
    } else {
      Swal.fire({
        title: "Errore",
        text: "Si è verificato un errore durante la pubblicazione",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }, [
    props.franchisor,
    props.franchisor,
    props.requestRemovePublishFranchisingAd,
  ]);

  useEffect(() => {
    const loginData = props.candidate.loginData;

    //if (localStorage.getItem("logging-for") && !loginData) return;

    if (loginData && isPublishing.current) {
      const { Status, Message: updateData } = loginData.data;

      if (Status === "Success") {
        localStorage.setItem("token", updateData?.access);
        localStorage.setItem("refresh_token", updateData?.refresh);
        localStorage.setItem("representative", updateData?.representative);
        localStorage.setItem("representative_id", updateData?.id);
        const date = new Date();
        date.setMinutes(date.getMinutes() + updateData?.duration);
        localStorage.setItem("expiration", date.toISOString());
        if (!updateData.flag_company) {
          localStorage.setItem("username", updateData?.username);
        }
        props.requestCompanyUpdate({
          data: {
            franchisor: franchisorDaUsareDopoLogin,
          },
          token: props.candidate?.loginData?.data.Message.access,
        });

        props.requestPublishFranchisingAd({
          data: { ...formDataDiQuestoForm },
          token: props.candidate?.loginData?.data.Message.access,
        });
      } else {
        if (
          props.candidate.loginData?.data?.Message?.includes(
            "E-mail non verificata"
          )
        ) {
          Swal.fire({
            text: "Per pubblicare un'offerta franchisor, è necessario verificare la tua email",
            title: "Errore",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Errore",
            text: "Si è verificato un errore",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    }
  }, [props.candidate.loginData]);

  return (
    <>
      <Header />
      <Breadcrumbs />
      <div className="container">
        <div className="seekng_job_title mb-4">
          <h2>Pubblica il tuo annuncio franchising</h2>
        </div>
        <div className="seeking_job_inner">
          <form
            ref={formRef}
            onSubmit={onFormSubmit}
            className="seeking_job_form row"
          >
            {Object.entries(formSettings).map(([name, setting], i) => {
              switch (setting.type) {
                case "text":
                case "number":
                case "date":
                  return (
                    <div key={i} className="col-md-6 mb-3">
                      <div>
                        <div>
                          <div>
                            <div className="form-group-text">
                              <label htmlFor={name} className="label">
                                {setting.label}
                              </label>
                              <input
                                type={setting.type}
                                className={`form-control set_max_width`}
                                placeholder={setting.label}
                                name={name}
                                value={data?.[name] || ""}
                                onChange={(e) =>
                                  setData((p) => ({
                                    ...p,
                                    [name]: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                case "file":
                  return (
                    <div className="col-md-6 mb-3" key={i}>
                      <div>
                        <div className="form-group-text">
                          <div>
                            <label className="form-label">
                              {setting.label}
                            </label>
                            <div className="input-group custom-file-button">
                              <label
                                className={`input-group-text file-button`}
                                htmlFor="companyLogoUpload"
                              >
                                Scegli file
                              </label>
                              <input
                                type="file"
                                accept=".jpg,.png"
                                className={`form-control`}
                                name="logo"
                                id="companyLogoUpload"
                                placeholder="Nessun file scelto"
                                onChange={(e) =>
                                  setData((p) => ({
                                    ...p,
                                    [name]: e.target.files[0],
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                case "select":
                  return (
                    <div className="col-md-6 mb-3" key={i}>
                      <label>{setting.label}</label>
                      <Select
                        isDisabled={setting.disabled}
                        name={name}
                        defaultValue={setting.defaultValue}
                        className={`form-control set_max_width select_dropdown`}
                        options={setting.options}
                        value={data?.[name]}
                        onChange={(e) => setData((p) => ({ ...p, [name]: e }))}
                        placeholder={"Seleziona un'opzione"}
                        isMulti={setting.isMulti}
                        isClearable={true}
                      />
                    </div>
                  );

                case "richtext":
                  return (
                    <div className="col-12 mb-3 mt-4" key={i}>
                      <div className="row g-3">
                        <div className="col-12">
                          <label className="form-label">{setting.label}</label>
                          <Ckeditor
                            className={`form-control`}
                            name={name}
                            desc={data?.[name] || ""}
                            setDesc={(desc) =>
                              setData((p) => ({ ...p, [name]: desc }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                default:
                  return <></>;
              }
            })}
          </form>
        </div>
        <div className="seeking_form_btn d-flex justify-content-between mt-4 mb-4">
          <BackButton prevStep={prevStep} />
          <NextButton nextStep={nextStep} iconDisabled={true} text="Pubblica" />
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    franchisor: state.franchisor,
    candidate: state.candidate,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestRegister,
      requestCompanyRegister,
      requestCompanyUpdate,
      requestCompanyDetails,
      requestPublishFranchisingAd,
      requestRemovePublishFranchisingAd,
      requestLogin,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Register2);
