// File: /src/utils/routing.js
// Created Date: Sunday September 15th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Sunday September 15th 2024 2:26:38 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const useNavigateToRegisterDestination = (userType = "noType") => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const registerDestination = (() => {
    const userType = searchParams.get("userType");
    switch (userType) {
      case "candidate":
        return "/candidate/register";
      case "agent":
        return "/agent/register";
      case "franchisor":
        return "/service/register";
      case "company":
        return "/company/register";
      default:
        return "/register";
    }
  })();

  const params = new URLSearchParams(searchParams);
  params.delete("userType");

  return navigate.bind(
    this,
    `${registerDestination}/${params ? `?${params.toString()}` : ""}`
  );
};

export const useAppNavigation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const navigateTo = (_path, options) => {
    const currentParams = Object.fromEntries(searchParams);

    let path = _path;

    const _newParams = new URLSearchParams({
      ...currentParams,
      ...options?.params,
    });

    const newParams = Object.fromEntries(_newParams);

    if (options?.replaceFrom) {
      path = newParams["from"] || path;
      delete newParams["from"];
      delete options["replaceFrom"];
    }

    for (const key in options?.params) {
      if (options?.params[key] === null) {
        delete newParams[key];
      }
    }

    const actualParams = new URLSearchParams(newParams);
    return navigate(`${path}?${actualParams.toString()}`, options);
  };

  return { navigateTo, location };
};
