import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  requestGetCVDetails,
  requestGetJobDetails,
  requestCandidateProfile,
} from "../../Redux/actions";
import CompanyLogo from "../../../src/images/logo.png";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import loaderLogo from "../../images/submit.gif";
import { useNavigate } from "react-router-dom";

function FranchisorJobDetail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div
        className="job_detail_sec"
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{
          backgroundColor: "white",
          marginBlock: 0,
          zIndex: 1000,
          height: "100vh",
          width: "40rem",
          marginLeft: "auto",
          "@media (maxWidth: 768px)": {
            width: "100%",
          },
          padding: "1rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="job_detail_inner col-xl-12 col-lg-12 col-md-12 col-m-12 col-12">
              <div className="job_details">
                <div className="company_logo">
                  <img src={CompanyLogo} alt="company logo" />
                </div>
                <div className="job_title mt-4">
                  <h2 className="">{props.jobDetails.franchisor?.brand}</h2>
                </div>
                <div className="sector_name">
                  <p>
                    <strong>Anno di fondazione:&nbsp;&nbsp;</strong>
                    {props.jobDetails?.franchisor?.year_brand_creation}
                  </p>
                </div>
                <div className="sector_name">
                  <p>
                    <strong>Punti Vendita:&nbsp;&nbsp;</strong>
                    {props.jobDetails?.franchisor?.n_store}
                  </p>
                </div>
                <div className="sector_name">
                  <p>
                    <strong>Metratura minima:&nbsp;&nbsp;</strong>
                    {props.jobDetails?.franchisor?.area_min}mq
                  </p>
                </div>
                <div className="sector_name">
                  <p>
                    <strong>Finanziabile:&nbsp;&nbsp;</strong>
                    {String(props.jobDetails?.franchisor?.financeable) ===
                    "true"
                      ? "Si"
                      : "No"}
                  </p>
                </div>
                <div
                  style={{ marginTop: "2rem" }}
                  dangerouslySetInnerHTML={{
                    __html: props.jobDetails.description,
                  }}
                ></div>
                <button
                  className="btn_default btn_green_outline"
                  style={{ width: "100%", marginTop: "2rem" }}
                  onClick={() => {
                    navigate(
                      `/send-franchisor-request?jobId=${props.jobDetails.id}&franchisorId=${props.jobDetails.franchisor?.id}`,
                      {
                        state: {
                          franchisee_answers: location?.state?.filterData,
                        },
                      }
                    );
                  }}
                >
                  Contattami
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { candidate: state.candidate, company: state.company };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { requestGetJobDetails, requestGetCVDetails, requestCandidateProfile },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FranchisorJobDetail);
