// File: /src/Redux/agentReducer.js
// Created Date: Tuesday September 10th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Tuesday September 10th 2024 1:14:25 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import {
  RECEIVE_GET_JOB,
  RECEIVE_GET_JOBLIST,
  RECEIVE_GET_LIST,
  RECEIVE_GET_LOCATION,
  RECEIVE_GET_SECTOR_LIST,
  RECEIVE_LOGIN,
  RECEIVE_REGISTER,
  RESET_GET_JOBLIST,
  REQUEST_GET_CANDIDATE,
  RECEIVE_GET_CANDIDATE,
  RECEIVE_GET_PRODUCTS,
  RECEIVE_COMPANY_GET_CV_LIST,
  RECEIVE_CANDIDATE_PROFILE,
  RECEIVE_GET_FILTER_OPTIONS,
  RECEIVE_LINKEDIN_LOGIN,
} from "./actions";

export default (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_REGISTER:
      return {
        ...state,
        registerData: data,
      };

    case RECEIVE_LINKEDIN_LOGIN:
      return {
        ...state,
        loginData: (() => {
          if (data?.data?.Message) {
            return data;
          }
          return {
            data: {
              Message: {
                ...data.data,
              },
            },
          };
        })(),
      };

    case RECEIVE_LOGIN:
      return {
        ...state,
        loginData: data,
      };

    case RECEIVE_GET_LIST:
      return {
        ...state,
        getlistData: data,
      };

    case RECEIVE_COMPANY_GET_CV_LIST:
      return {
        ...state,
        cvListData: data,
      };

    case RECEIVE_GET_LOCATION:
      return {
        ...state,
        getlocation: data,
      };

    case RECEIVE_GET_SECTOR_LIST:
      return {
        ...state,
        SectorData: data,
      };

    case RECEIVE_GET_JOB:
      return {
        ...state,
        GetJobData: data,
      };

    case RECEIVE_GET_JOBLIST:
      return {
        ...state,
        GetJobListData: data,
      };

    case RESET_GET_JOBLIST:
      return {
        ...state,
        GetJobListData: null,
      };

    case RECEIVE_GET_CANDIDATE:
      return {
        ...state,
        GetCandidateData: data,
      };

    case RECEIVE_GET_PRODUCTS:
      return {
        ...state,
        GetProductsData: data,
      };

    case RECEIVE_CANDIDATE_PROFILE:
      return {
        ...state,
        CandidateProfileData: data,
      };

    case RECEIVE_GET_FILTER_OPTIONS:
      return {
        ...state,
        GetFilterOptionsData: data,
      };

    default:
      return state;
  }
};
