import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    requestPurchageTokenPayment,
    requestPatchAdvertisement,
    userLogout,
} from "../../Redux/actions";

import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { useNavigate, useLocation } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';
import { toast } from 'react-toastify';
import StripeSession from './stripe-session/StripeSession';
import SatispayButton from './satispay/SatispayButton';


function PaymentButtons(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [product, setProduct] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [orderId, setOrderId] = useState(null);

    useEffect(() => {
        const data = location?.state?.item;
        if (!data) {
            navigate('/company/PackageToken');
        } else {
            setProduct(data);
        }
    }, [location, navigate, product]);

    useEffect(() => {
        if (!isProcessing) {
            const timer = setTimeout(() => {
                setIsProcessing(true);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [isProcessing]);

    const initialOptions = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "EUR",
    };

    const styles = {
        layout: "horizontal",
        tagline: false
    };

    const createOrder = async () => {

        if (!product || !product.token_amount || !product.package_name) {
            console.error("Product information is incomplete or missing.");
            navigate('/company/PackageToken');
            return;
        }

        const requestData = {
            currency_code: product.price_currency,
            price: product?.price
        };

        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${process.env.REACT_APP_API_HOST}wideer/create-order/paypal/`,
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            console.log(response);

            setOrderId(response.data.order_id);
            return response.data.id;
        } catch (error) {
            console.error("Error creating order:", error.response ? error.response.data : error.message);
            localStorage.removeItem('__paypal_storage__');
            throw error;
        }
    };


    const onApprove = async (data) => {
        const token = localStorage.getItem("token");

        if (!product || !product.token_amount || !product.package_name) {
            console.error("Product information is incomplete or missing.");
            //navigate('/company/PackageToken');
            return;
        }
        
        const requestData = {
            bought: product.token_amount,
            in: product.package_name,
            order_id: orderId
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_HOST}wideer/paypal/capture-order/`,
                { orderID: data.orderID },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            console.log(response, "response paypasl");
            if (response.data.Message.status === "COMPLETED") {
                //requestData.order_id = response.data.Message.order_id;
                await props.requestPurchageTokenPayment({
                    data: requestData,
                    token: token,
                });

                toast.success("Grazie per il tuo acquisto!", { theme: "colored", autoClose: 8000 });
                localStorage.removeItem('__paypal_storage__');
                navigate('/company/Creditmanagement');
            }
            return response.data;
        } catch (error) {
            console.error("Error capturing order:", error.response ? error.response.data : error.message);
            localStorage.removeItem('__paypal_storage__');
            //navigate('/company/PackageToken');
            toast.error("There was an issue capturing your payment.", {
                theme: "colored",
                autoClose: 8000,
            });
            throw error;
        }
    };

    const handleCancel = () => {
        localStorage.removeItem('__paypal_storage__');
        toast.info("Payment process was canceled.", { theme: "colored", autoClose: 8000 });
        navigate('/company/PackageToken');
        reloadPayPal()
    };

    const handleError = (err) => {
        console.log("PayPal Checkout onError", err);
        // toast.error("An error occurred during the payment process.", { theme: "colored", autoClose: 8000 });
        localStorage.removeItem('__paypal_storage__');
        navigate('/company/PackageToken');
        reloadPayPal()
    };

    const reloadPayPal = () => {
        //window.location.reload();
    };

    const redirectToPlan = () => {
        navigate("/company/PackageToken");
    }


    return (
        <>
            <Header />
            <div className='container'>
                <div className="new_research_btn mt-4 text-end">
                    <button
                        className="btn btn_default btn_orange_outline"
                        type="button"
                        onClick={redirectToPlan}
                    >
                        Indietro
                    </button>
                </div>
                <div className='paypal'>
                    {!isProcessing ? <>
                        <div className='paypal_btn'>
                            <button type='button' style={{
                                cursor: 'not-allowed'
                            }}>
                                <div className="spinner-border" role="status" style={{ width: '1.5rem', height: '1.5rem', cursor: 'not-allowed' }}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </>
                        :
                        <>
                            <div className="paypal-button-container">
                                <PayPalScriptProvider options={initialOptions}>
                                    <PayPalButtons
                                        style={styles}
                                        createOrder={(data, actions) => createOrder(data, actions)}
                                        onApprove={(data, actions) => onApprove(data, actions)}
                                        onCancel={handleCancel}
                                        onError={handleError}
                                    />
                                </PayPalScriptProvider>
                            </div>
                        </>
                    }

                    {/* <div className="paypal-button-container">
                                <PayPalScriptProvider options={initialOptions}>
                                    <PayPalButtons
                                        style={styles}
                                        createOrder={(data, actions) => createOrder(data, actions)}
                                        onApprove={(data, actions) => onApprove(data, actions)}
                                        onCancel={handleCancel}
                                        onError={handleError}
                                    />
                                </PayPalScriptProvider>
                            </div> */}

                    {/* payment intent method */}
                    {/* <div className='stripe_btn'>
                    <button type='button' onClick={redirectToStripe}>
                        <svg className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Logo-svg Icon-color-svg Icon-color--gray800-svg" height="24" width="58" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 150"><path fillRule="evenodd" d="M360 77.4c0 2.4-.2 7.6-.2 8.9h-48.9c1.1 11.8 9.7 15.2 19.4 15.2 9.9 0 17.7-2.1 24.5-5.5v20c-6.8 3.8-15.8 6.5-27.7 6.5-24.4 0-41.4-15.2-41.4-45.3 0-25.4 14.4-45.6 38.2-45.6 23.7 0 36.1 20.2 36.1 45.8zm-49.4-9.5h25.8c0-11.3-6.5-16-12.6-16-6.3 0-13.2 4.7-13.2 16zm-63.5-36.3c17.5 0 34 15.8 34.1 44.8 0 31.7-16.3 46.1-34.2 46.1-8.8 0-14.1-3.7-17.7-6.3l-.1 28.3-25 5.3V33.2h22l1.3 6.2c3.5-3.2 9.8-7.8 19.6-7.8zm-6 68.9c9.2 0 15.4-10 15.4-23.4 0-13.1-6.3-23.3-15.4-23.3-5.7 0-9.3 2-11.9 4.9l.1 37.1c2.4 2.6 5.9 4.7 11.8 4.7zm-71.3-74.8V5.3L194.9 0v20.3l-25.1 5.4zm0 7.6h25.1v87.5h-25.1V33.3zm-26.9 7.4c5.9-10.8 17.6-8.6 20.8-7.4v23c-3.1-1.1-13.1-2.5-19 5.2v59.3h-25V33.3h21.6l1.6 7.4zm-50-29.1l-.1 21.7h19v21.3h-19v35.5c0 14.8 15.8 10.2 19 8.9v20.3c-3.3 1.8-9.3 3.3-17.5 3.3-14.8 0-25.9-10.9-25.9-25.7l.1-80.1 24.4-5.2zM25.3 58.7c0 11.2 38.1 5.9 38.2 35.7 0 17.9-14.3 28.2-35.1 28.2-8.6 0-18-1.7-27.3-5.7V93.1c8.4 4.6 19 8 27.3 8 5.6 0 9.6-1.5 9.6-6.1 0-11.9-38-7.5-38-35.1 0-17.7 13.5-28.3 33.8-28.3 8.3 0 16.5 1.3 24.8 4.6v23.5c-7.6-4.1-17.2-6.4-24.8-6.4-5.3 0-8.5 1.5-8.5 5.4z"></path></svg>
                    </button>
                </div> */}
                    {/* stripe session method */}
                    {<StripeSession product={product} />}
                    {/* <SatispayButton product={product} /> */}
                </div>
            </div>
            <Footer />
        </>
    )
}

const mapStateToProps = (state) => {
    return { company: state.company, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        requestPurchageTokenPayment,
        requestPatchAdvertisement,
        userLogout,
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentButtons);
