// File: /src/Ads/Opportunities.js
// Created Date: Thursday December 19th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Thursday December 19th 2024 10:33:13 am
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/Header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "../Components/Footer";
import AdsBuilder from "../Components/AdsBuilder";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestCompanyDetails } from "../Redux/actions";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import loaderLogo from "../images/Iphone-spinner-2.gif";

const DUMMY_ADS = [
  {
    id: 1,
    name: "Mario Rossi",
    macroservizio: "Servizi",
    city: "Milano",
    due: new Date("2025-01-01"),
    description:
      "Da qualche giorno il lettore dvd non funziona più e non riesco a farlo partire",
    date: new Date("2024-12-19"),
    urgent: true,
    service: "Elettronica",
    zip: "20121",
    qna: [
      {
        question: "Qual è il problema?",
        answer: "Il lettore dvd non funziona più",
      },
      {
        question: "Quando è stato acquistato?",
        answer: "2020",
      },
    ],
  },
  {
    id: 2,
    name: "Laura Bianchi",
    macroservizio: "Manutenzione",
    city: "Roma",
    due: new Date("2025-02-15"),
    description: "Cerco un idraulico per riparare un rubinetto che perde",
    date: new Date("2024-12-20"),
    premium: true,
    zip: "00118",
    qna: [
      {
        question: "Qual è il problema?",
        answer: "Il rubinetto perde",
      },
    ],
  },
  {
    id: 3,
    name: "Giuseppe Verdi",
    macroservizio: "Consulenza",
    city: "Torino",
    due: new Date("2025-03-10"),
    description: "Necessito supporto per dichiarazione dei redditi",
    date: new Date("2024-12-21"),
    service: "Fiscale",
    remote: true,
    qna: [
      {
        question: "Qual è il problema?",
        answer: "Dichiarazione dei redditi",
      },
      {
        question: "Quando è stato acquistato?",
        answer: "2020",
      },
      {
        question: "Hai già provato a riparare?",
        answer: "No",
      },
    ],
  },
  {
    id: 4,
    name: "Anna Neri",
    macroservizio: "Formazione",
    city: "Firenze",
    due: new Date("2025-01-20"),
    description: "Cerco insegnante di inglese per lezioni private",
    date: new Date("2024-12-18"),
    flexible: true,
    level: "Advanced",
    qna: [
      {
        question: "Qual è il problema?",
        answer: "Insegnante di inglese",
      },
      {
        question: "Quando è stato acquistato?",
        answer: "2020",
      },
    ],
  },
  {
    id: 5,
    name: "Marco Ferrari",
    macroservizio: "Assistenza",
    city: "Bologna",
    due: new Date("2025-02-01"),
    description: "Ricerca assistente per persona anziana part-time",
    date: new Date("2024-12-22"),
    urgent: true,
    type: "Part-time",
    qna: [
      {
        question: "Qual è il problema?",
        answer: "Assistenza per persona anziana",
      },
      {
        question: "Quando è stato acquistato?",
        answer: "2020",
      },
    ],
  },
];

const CARD_FIELDS = {
  title1: ["receiver_name"],
  title2: ["offer_description"],
  subtitle: [],
  date: ["created_at"],
  highlighted_elements: [
    {
      label: "Crediti richiesti per inviare un preventivo",
      key: "offer_fee",
      dependencies: [
        {
          key: "accepted",
          value: false,
        },
      ],
    },
    {
      label: "Tipo di intervento",
      key: "offer_macro_service",
    },
    {
      label: "Servizio richiesto",
      key: "offer_service",
    },
  ],
  tags: ["accepted"],
};

const DETAILED_FIELDS = {
  title1: ["receiver_name"],
  image: [],
  subtitle: [],
  description: ["offer_description"],
  highlights: ["offer_macro_service", "offer_service"],
  blocks: ["qna"],
  highlighted_elements: [
    {
      label: "Crediti richiesti per inviare un preventivo",
      key: "offer_fee",
    },
  ],
  info: [
    "price",
    {
      key: "created_by_email",
      nullable_message:
        "L'utente non ha reso disponibile il suo indirizzo email",
    },
    {
      key: "created_by_phone",
      nullable_message:
        "L'utente non ha reso disponibile il suo numero di telefono",
    },
  ],
};

const Opportunities = (props) => {
  const [requests, setRequests] = useState([]);
  const [showModal, setShowModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setRequests([]);
  }, [searchParams]);

  const getOpportunities = async (representative_id) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const id = props.candidate?.loginData?.data?.Message.id;
      const token = localStorage.getItem("token");
      const type = searchParams.get("type");
      if (!id) return;
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}auth/register/?id=${
          representative_id ?? id
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data?.Message?.[0]) {
        const serializedRequests = response.data.Message?.[0]?.[
          searchParams.get("sent-prev") === "true"
            ? "quotations_o"
            : searchParams.get("sent") === "true"
            ? "offers_o"
            : "offers"
        ].map((offer) => {
          let obj = { ...offer };
          if (
            type &&
            ((type === "professional" && obj.flag_artisan) ||
              (type === "artisan" && obj.flag_professional))
          ) {
            return null;
          }
          if (
            obj.representative ===
            props.candidate?.loginData?.data?.Message?.company_id?.[0]
          ) {
            return null;
          }
          const uKey =
            searchParams.get("sent-prev") === "true"
              ? "receiver"
              : searchParams.get("sent") === "true"
              ? "created_by"
              : "created_by";
          // obj.receiver_id = obj.receiver_id;
          obj.created_by_name = obj[uKey].name;
          obj.created_by_surname = obj[uKey].surname;
          obj.created_by_email = obj[uKey].e_mail_consent
            ? obj[uKey].e_mail
            : undefined;
          obj.created_by_phone = obj[uKey].tel_consent
            ? obj[uKey].cellphone
            : undefined;
          obj.offer_description = obj.description ?? obj.offer?.description;
          obj.qna =
            obj.answers?.map((answer) => ({
              question: answer.question.question,
              answer: answer.answer,
            })) ?? [];
          obj.offer_macro_service = obj.macro_service?.macro_service;
          obj.offer_service = obj.service?.service;
          obj.offer_fee = obj?.fee;
          if (searchParams.get("sent-prev") === "true") {
            obj.created_at = new Date(obj.date);
            obj.receiver_name = obj.receiver.name;
            obj.receiver_surname = obj.receiver.surname;
            obj.receiver_email = obj.receiver.e_mail;
            obj.receiver_phone = obj.receiver.cellphone;
          }
          delete obj[uKey].name;
          delete obj[uKey].surname;
          delete obj[uKey].e_mail;
          delete obj[uKey].cellphone;
          delete obj.description;
          delete obj.macro_service;
          delete obj.service;
          delete obj.fee;
          return obj;
        });
        setRequests((p) =>
          [...(p ?? []), ...serializedRequests].filter(Boolean)
        );
      }
    } catch (error) {
      console.error("Error fetching opportunities:", error);
    } finally {
      setIsLoading(false);
      if (
        localStorage.getItem("representative_id") &&
        !representative_id &&
        props.candidate?.loginData?.data?.Message?.company_id?.[0]
      ) {
        getOpportunities(
          props.candidate?.loginData?.data?.Message?.company_id?.[0]
        );
      }
    }
  };

  useEffect(() => {
    getOpportunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.candidate?.loginData?.data?.Message.id, searchParams]);

  const updateFlag = async () => {
    try {
      const isArtisan = props.candidate?.loginData?.data?.Message?.flag_artisan;
      const isProfessional =
        props.candidate?.loginData?.data?.Message?.flag_professional;
      if (!isArtisan && !isProfessional) return window.location.reload();
      if (!props.candidate?.loginData?.data?.Message?.id) return;
      const patchData = {
        flag_artisan: true,
        flag_professional: true,
      };
      const token = localStorage.getItem("token");
      await axios.patch(
        `${process.env.REACT_APP_API_HOST}auth/register/company/`,
        patchData,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            id: props.candidate?.loginData?.data?.Message?.id,
          },
        }
      );
      window.location.reload();
    } catch (error) {
      console.error("Error updating flag:", error);
      toast.error("Errore nell'aggiornamento del flag");
    }
  };

  useEffect(() => {
    if (!props.candidate?.loginData?.data?.Message?.access) {
      setShowModal("login");
    }
  }, [props.candidate?.loginData?.data?.Message?.access]);

  return (
    <div>
      <Header showModal={showModal} setShowModal={setShowModal} />
      <div className="container min-vh-100">
        {/* <div className="new_research_btn mt-4 text-end">
          <button
            style={{ marginRight: "10px" }}
            className="btn btn_default btn_orange_outline"
            type="button"
            onClick={() => {}}
          >
            Nuova ricerca
          </button>
          {props.candidate?.loginData?.data.Message.flag_company ? (
            <button
              className="btn btn_default btn_orange_outline"
              type="button"
              onClick={() => {}}
            >
              Crea nuovo annuncio
            </button>
          ) : (
            <></>
          )}
        </div> */}
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            {isLoading ? (
              <div className="loader">
                <div className="loader_inner">
                  <img src={loaderLogo} />
                </div>
              </div>
            ) : requests.length ? (
              <AdsBuilder
                cardFields={(() => {
                  if (searchParams.get("sent") === "true") {
                    return CARD_FIELDS;
                  } else {
                    const cpy = { ...CARD_FIELDS };
                    cpy.highlighted_elements = [];
                    return cpy;
                  }
                })()}
                detailedFields={(() => {
                  if (
                    searchParams.get("sent") !== "true" &&
                    searchParams.get("sent-prev") !== "true"
                  ) {
                    return DETAILED_FIELDS;
                  } else {
                    const cpy = { ...DETAILED_FIELDS };
                    cpy.highlighted_elements = [];
                    return cpy;
                  }
                })()}
                openedDetail={{
                  targetKey: "url_id" ?? "id",
                  targetValue: searchParams.get("id"),
                }}
                blockFields={{
                  title: "question",
                  content: "answer",
                }}
                ads={requests}
                onAction={(ad) => {
                  Swal.fire({
                    title: "Invia preventivo",
                    text: `Inviando il preventivo consumerai ${ad.offer_fee} crediti`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Invia",
                    cancelButtonText: "Annulla",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/preventivo", {
                        state: {
                          ad,
                        },
                      });
                    }
                  });
                }}
                actionLabel={
                  searchParams.get("sent") === "true"
                    ? undefined
                    : "Invia Preventivo"
                }
              />
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                <p className="text-center text-muted mb-3">
                  Per questo utente non ho trovato risultati
                </p>
                <button
                  className="btn btn_default btn_orange_outline"
                  type="button"
                  onClick={updateFlag}
                >
                  {(() => {
                    if (
                      props.candidate?.loginData?.data?.Message.flag_artisan &&
                      !props.candidate?.loginData?.data.Message &&
                      searchParams.get("type") === "professional"
                    ) {
                      return "iscrivimi anche come artigiano";
                    }
                    if (
                      props.candidate?.loginData?.data.Message
                        .flag_professional &&
                      !props.candidate?.loginData?.data.Message.flag_artisan &&
                      searchParams.get("type") === "artisan"
                    ) {
                      return "iscrivimi anche come professionista";
                    }
                    return "Aggiorna";
                  })()}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { candidate: state.candidate, company: state.company };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestCompanyDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Opportunities);
